/* eslint-disable max-lines */

import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { MapVisualizationModal, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile, Checkbox,
  ImageWithFallback,
  Tooltip,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { poisTableData } from './data'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { IPois, IPoisTileData } from './@types/IPoisTileData'
import { ReactComponent as ClockIcon } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/clock.svg'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})
const NoItems = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
`

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

const CheckboxWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      display: flex;
    align-items: center;
      color: ${palette.blue};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};

      > :first-child {
        margin-right: 0.5rem
      }
    `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)

export const PoisTile: React.FC<{data: IPoisTileData, isPdf?: boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)

  const {
    nonGroupedLabels, groupedLabels,
  } = poisTableData

  const [grouped, setGrouped] = useState<boolean>(true)
  const [categories, setCategories] = useState<ResourceWithId[]>([])
  const [val, setValue] = useState<ResourceWithId[]>(categories)
  const [rowsToDisplay, setRowsToDisplay] = useState<{[key: string]: IPois[] }>({})
  const labels = grouped ? groupedLabels : nonGroupedLabels
  const [layers, setLayers] = useState<IMapTile['layers']>()
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const countriesWithOnlyGroceryStores = ['CZ', 'PT']
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  // token
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  // functions

  const groupCategoriesPdf = () => {
    const rowsToDisplayPdf = JSON.parse(localStorage?.getItem('poisRowsToDisplay') || '{}') as {
      [key: string]: IPois[];
    }
    return (Object.entries(rowsToDisplayPdf).map((entry) => (
      <div key={entry[0]}>
        {`{{table1_${entry[0].replace(/-|\s/g, '_')}_${tileId?.replace(/-/g, '_')} | safe}}`}
      </div>
    )))
  }

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const getLayers = () => {
    if (data?.value?.pois?.length) {
      const features : IFeatureCollection['data']['features'] = []
      const pois = data?.value?.pois?.filter(poi => val?.map(v => v?.id).includes(poi.category))
      pois?.forEach(poi => features.push({
        geometry: {
          coordinates: [poi.lng, poi.lat],
          type: 'Point',
        },
        properties: {
          title: `<div style="display: flex; flex-direction: column;"><span>${poi?.brand}</span><span>${poi?.address}</span></div>`,
          pinnedItem: {
            class: 'poi-img',
            html: `<img alt='' src='${poi.logo || 'assets/icons/logoPlaceholder.svg'}' style="width:22px; height: 22px;"/>`,
          },
        },
        type: 'Feature',
      }))
      return [{
        id: 'pois-layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'pois',
          },
        },
      }]
    }
    return undefined
  }

  // hooks

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    window.localStorage.setItem('poisRowsToDisplay', JSON.stringify(rowsToDisplay))
  }, [rowsToDisplay])

  useEffect(() => {
    if (data?.value?.pois?.length && mapLocation) {
      dispatch(saveTileData('market', tileId, {
        ...data,
        mapLocation,
      }))
    }
  }, [mapLocation])

  useEffect(() => {
    if (data?.value?.pois?.length) {
    // filtering results by selected categories
      const filteredValues = data?.value?.pois?.filter(value => val
        .map(c => c.id).includes(value.category))
      // parsing an array to object by category
      const assignedValues = filteredValues.reduce((acc, cur) => ({
        ...acc,
        [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
      }), ({} as {[key: string]: IPois[] }))
      // grouping results by brand and counting
      const groupedValues = Object.entries(assignedValues).reduce((acc, cur) => {
        const grouped = cur[1].reduce((acc: IPois[], cur: IPois) => {
          const exist = acc.find(v => v.brand === cur.brand)
          const reducedValue = {
            ...cur,
            count: exist?.count ? exist.count + 1 : 1,
            distance: exist?.distance ? Math.min(exist.distance, cur.distance) : cur.distance,
          }
          let newArray = []
          if (exist) {
            acc.forEach(item => {
              if (item !== exist) newArray.push(item)
            })
            newArray.push(reducedValue)
          } else {
            newArray = [...acc, reducedValue]
          }

          return newArray
        }, [])

        return {
          ...acc,
          [cur[0]]: grouped,
        }
      }, {})

      // setting the results to be displayed depending on whether they are to be grouped or not
      setRowsToDisplay(grouped
        ? groupedValues
        : filteredValues.reduce((acc, cur) => ({
          ...acc,
          [cur.category]: acc?.[cur.category]?.length ? [...acc?.[cur.category], cur] : [cur],
        }), ({} as {[key: string]: IPois[] })))
    }
  }, [JSON.stringify(data), grouped, val])

  useEffect(() => {
    if (data?.value?.pois?.length) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.pois?.forEach(investment => {
        const categoryId = investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.pois_tile.category.${categoryId.toLocaleLowerCase().split(/[_ -]+/)
              .join('-')}`),
          })
        }
      })

      setCategories(categoriesObjects)
      setValue(categoriesObjects)
    }
  }, [data?.value])

  useEffect(() => {
    setLayers(getLayers())
  }, [data?.value, val])

  return !data || data?.loading
    ? (<Loader />)
    : (data?.value?.pois?.length
      ? (
        <Wrapper>
          {!isPdf
          && (
            <>
              {!countriesWithOnlyGroceryStores.includes(value?.country || ' ') && (
                <NestedSelectWrapper>
                  <span>{t('placeme.pois_tile.points_categories')}</span>
                  <NestedSelect
                    name=''
                    onChange={setValue}
                    options={categories}
                    selected={val}
                    width='50%'
                  />
                </NestedSelectWrapper>
              )}

              <CheckboxWrapper>
                <Checkbox
                  checked={grouped}
                  onClick={() => setGrouped(state => !state)}
                />
                <span>{t('placeme.pois_tile.group')}</span>
              </CheckboxWrapper>
            </>
          )}
          {!isPdf
            ? Object.entries(rowsToDisplay)?.map((entry) => (
              <Table
                key={entry[0]}
                content={entry[1].map(value => [
                  <ImageWithFallback
                    key={value.logo}
                    alt={value.brand}
                    fallbackSrc='assets/icons/logoPlaceholder.svg'
                    height='22px'
                    src={value.logo}
                    width='22px'
                  />,
                  <span
                    key={value.brand + value.address}
                    className={value?.workingHours ? 'bank' : undefined}
                  >
                    <Tooltip
                      content={value.brand}
                      position='right center'
                    >
                      {' '}
                      {value.brand}
                    </Tooltip>
                    {value?.workingHours
                      ? (
                        <Tooltip
                          content={(
                            <ul>
                              {Object.entries(value?.workingHours).map(([key, value]) => <li key={key}>{`${t(`placeme.pois.working_hours.day.${key}`)}: ${value}`}</li>)}
                            </ul>
                          )}
                          header={t('placeme.pois.working_hours')}
                          position='right center'
                        >
                          <ClockIcon
                            height='18px'
                            width='18px'
                          />
                        </Tooltip>
                      )
                      : null}
                  </span>,
                  <span key={value.address}>
                    {grouped
                      ? value.count
                      : (
                        <Tooltip
                          content={value.address}
                          position='right center'
                        >
                          {value.address}
                        </Tooltip>
                      )}
                  </span>,
                  <span
                    key={value.distance}
                    style={{
                      display: 'flex',
                      justifyContent:'flex-end',
                    }}
                  >
                    {value.distance * 1000}
                  </span>,
                ])}
                gap='1rem'
                headerTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
                labels={[
                  <span
                    key={entry[0]}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {t(`placeme.pois_tile.category.${entry[0]?.toLocaleLowerCase().split(/[_ -]+/)
                      .join('-')}`)}
                  </span>,
                  ...labels
                    .map((label: string) => (<span key={label}>{t(label)}</span>)),
                ]}
                pois={grouped}
                rowTemplate={grouped ? '1fr 6fr 4fr 4fr' : '1fr 5fr 5fr 4fr'}
              />
            ))
            : (
              <>

                <div>
                  {groupCategoriesPdf()}
                </div>
              </>
            )}
          {!isPdf && (
            <>
              <StyledTitleFormSectionSubTitle>
                <span>{t('placeme.pois_tile.points_on_map')}</span>
                <LinkWithIconCheckOnMap onClick={handleMapOpen} />
              </StyledTitleFormSectionSubTitle>
              <MapWrapper>
                <MapTile
                  dragging
                  height='100%'
                  layers={getLayers()}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={value?.address}
                  setMapLocation={setMapLocation}
                  width='100%'
                  zoom={15}
                  zoomControl
                />
              </MapWrapper>
              {isMapDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isMapDisplayed}
                  layers={layers}
                  location={value}
                  mapId={`pois-map-${values?.find(c => c.id === 'market')?.tiles?.find(t => t.id === 'pois')?.chosenRange?.catchmentId}`}
                  setIsDisplay={setIsMapDisplayed}
                  zoom={15}
                />
              )}
            </>
          )}
        </Wrapper>
      )
      : (
        <Wrapper>
          <NoItems>{t('placeme.pois.no_items')}</NoItems>
        </Wrapper>
      )
    )
}
