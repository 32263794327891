/* eslint-disable max-lines,import/no-cycle,@nrwl/nx/enforce-module-boundaries */
import React, { FC, useState, useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import {
  TextButton,
  VerticalDivider,
  ButtonProduct,
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { IRange } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { GeojsonLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext } from '@dataplace.ai/features'
import { RootState } from '../../../../../redux/store'
import { ICustomRangeModalProps } from './@types/ICustomRangeModal.props'
import { CustomRangeModalBar } from '../../molecules'
import { handleRangeLabel, handleRangeValue, handleRangeIcon } from '../RangeTile'
import { addNewRangeAction, saveTemporaryCatchment } from '../../../slice/analysisSlice'

const StyledButtonProduct = styled(ButtonProduct)(() => css`
    margin-right: 1.25rem;
  `)

const Wrapper = styled.div(({ theme }) => {
  const { shadows } = theme
  return css`
    width: 80vw;
    height: 70vh;
    padding: 1.875rem;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    box-shadow: ${shadows.hover.boxShadow};
  `
})

const Header = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    padding-bottom: 1.875rem;
    display: flex;
    align-items: center;
    color: ${palette.black};

    h3 {
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};
      margin: 0 1.25rem;
    }
  `
})

const StyledTextButton = styled(TextButton)(() => css`
    margin-left: auto;
    margin-right: 1.82rem;
  `)

const ChosenRangeWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.black};
    margin-left: 1.25rem;
  `
})

const Value = styled.p(({ theme }) => {
  const { typography } = theme
  return css`
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};
      margin: 0 0 0 8px;

      span {
        margin-left: 0.25rem;
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
      }

  `
})

const Location = styled.p(({ theme }) => {
  const { typography } = theme
  return css`
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-top: 6px;
      margin-left: 5px;
  `
})

const Content = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.08), 0 0 24px rgba(95, 82, 73, 0.08);
  `,
)

export const CustomRangeModal: FC<ICustomRangeModalProps> = ({
  handleClose,
  onlyMap,
  handleRangeSave,
  maxRanges,
  tile,
}) => {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const dispatch = useAppDispatch()
  const { value } = useSelector((state: RootState) => state.location)
  const { layers } = useSelector((state: RootState) => state.analysis)

  const handleCustomRangeSave = () => {
    dispatch(addNewRangeAction(customRange))
    if (handleRangeSave) {
      handleRangeSave(customRange)
    }
  }
  const [removingDrawRangeLayers, setRemovingDrawRangeLayers] = useState(false)
  const [isDrawRangeChosen, setDrawRangeChoose] = useState(false)
  const [customRange, setCustomRange] = useState<IRange>({
    id: 'walk-8',
    type: 'walk',
    value: 8,
  })
  const [acceptButtonDisabled, setAcceptButtonDisabled] = useState(false)
  const [isRangeDrawn, setIsRangeDrawn] = useState(false)
  const [temporaryLayer, setTemporaryLayer] = useState<GeojsonLayers[] | undefined>(undefined)

  const handleDrawRange = (newRange: IRange) => {
    setAcceptButtonDisabled(false)
    setCustomRange(newRange)
  }

  useEffect(() => {
    setAcceptButtonDisabled(isDrawRangeChosen)
  }, [isDrawRangeChosen])

  useEffect(() => {
    if (layers?.temporaryCatchment?.layer) {
      setTemporaryLayer([{
        id: layers?.temporaryCatchment?.id,
        layer: layers?.temporaryCatchment?.layer,
      }])
    } else if (!layers?.temporaryCatchment) {
      setTemporaryLayer(undefined)
    }
  }, [layers])

  const drawPolygon = () => {
    if (!isDrawRangeChosen) {
      // todo - check if is another way after new realise of react-leaflet-draw
      const drawPolygonButton = document.body.querySelector<HTMLElement>(
        '.leaflet-draw-draw-polygon',
      )
      if (drawPolygonButton) {
        // disables custom buttons/slider
        setCustomRange(state => ({
          ...state,
          id: `custom${0}`,
          type: 'custom',
          value: 0,
        }))
        drawPolygonButton.click()
        setDrawRangeChoose(true)
        setRemovingDrawRangeLayers(false)
      }
      dispatch(saveTemporaryCatchment(undefined))
      setIsRangeDrawn(false)
    }
  }

  return (
    <Wrapper>
      <Header>
        <h3>{t('placeme.analyse_range')}</h3>
        <VerticalDivider
          height='23px'
          margin='0'
        />
        <ChosenRangeWrapper>
          {handleRangeIcon(customRange.type, 20, 20)}
          <Value className='value'>
            {handleRangeValue(customRange.type, customRange.value)}
            {handleRangeLabel(customRange.type, customRange.value)}
          </Value>
          <Location className='location'>
            {value?.address}
          </Location>
        </ChosenRangeWrapper>
        <StyledTextButton onClick={handleClose}>
          <p>{t('generic.cancel')}</p>
        </StyledTextButton>
        {!onlyMap && (
          <StyledButtonProduct
            disabled={acceptButtonDisabled}
            onClick={() => {
              analytics?.track('Custom Range Accept Button Clicked', {
                location: {
                  ...value,
                },
                tile: tile?.split('-')[0],
                range: {
                  type: customRange?.type,
                  value: customRange?.value,
                },
              })
              handleCustomRangeSave()
              handleClose()
            }}
            small
          >
            {t('generic.approve')}
          </StyledButtonProduct>
        )}
      </Header>
      <Content>
        <CustomRangeModalBar
          customRange={customRange}
          drawPolygon={drawPolygon}
          isDrawRangeChosen={isDrawRangeChosen}
          isRangeDrawn={isRangeDrawn}
          maxRanges={maxRanges}
          setCustomRange={setCustomRange}
          setDrawRangeChoose={setDrawRangeChoose}
          setIsRangeDrawn={setIsRangeDrawn}
          setRemoveCustomLayers={setRemovingDrawRangeLayers}
          tile={tile}
        />
        <MapTile
          dragging
          drawPolygon={drawPolygon}
          handleDraw={handleDrawRange}
          height='100%'
          layers={temporaryLayer}
          location={value}
          mapId={`custom-range-bar-${customRange?.id}`}
          mapTypeDropdown
          maxRange={maxRanges?.custom}
          pinDisplayed
          popupHeading={`${t('generic.chosen_location')}:`}
          popupParagraph={value?.address}
          removingDrawRangeLayers={removingDrawRangeLayers}
          scroll
          setDrawRangeChoose={setDrawRangeChoose}
          width='100%'
          zoomControl
        />
      </Content>
    </Wrapper>
  )
}
