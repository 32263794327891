import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import {
  SelectCaret,
} from '@dataplace.ai/ui-components/atoms'
import { Popup } from 'reactjs-popup'

const ListItem = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    text-align: start;
    padding: 0.625rem;
    color: ${palette.black};
    border-radius: ${corners.default.borderRadius};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};
    border: none;
    background-color: transparent;
    cursor: pointer;

    :hover {
      background-color: ${palette.results.green.opaque};
    }
  `
})

const MenuButton = styled.button(
  ({ theme }) => {
    const {
      palette, corners, typography,
    } = theme
    return css`
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0.25rem 0 0.25rem 0.5rem;
      max-width: 100px;
      width: 100px;
          border: none;
    padding: 0.5rem;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

      :focus {
        color: ${palette.light.white};
        background-color: ${palette.product.location.main};
      }
    
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
      
      > div {
        padding-left: 0.5rem;
        margin-left: auto;
      }
  `
  },
)

const MenuWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    > div > button {
      width: 100%;
    }
  `
})

const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    > button {
      margin: 0.25rem;
    }
  `,
)

export interface IListSelectorProps {
  placeholder?: string
  options: string[]
}

export const SelectButton = ({
  placeholder, options,
}: IListSelectorProps): JSX.Element => {
  const [value, setValue] = useState<string>('')

  const handleClick = (value: string) => {
    setValue(value)
  }

  return (
    <Wrapper>
      <Popup
        closeOnDocumentClick
        on='click'
        position='top center'
        trigger={(
          <MenuButton>
            <span>{value || placeholder}</span>
            <SelectCaret active={false} />
          </MenuButton>
        )}
      >
        {(close: () => void) => (
          <MenuWrapper>
            {options.map(option => (
              <ListItem
                key={option}
                onClick={() => {
                  close()
                  handleClick(option)
                }}
              >
                {option}
              </ListItem>
            ))}
          </MenuWrapper>
        )}
      </Popup>
    </Wrapper>
  )
}
