/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react'
import {
  BarChart,
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRow,
  TileFormColumn,
  DoughnutChart,
  TileFormRowWithData,
  Price,
} from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { IDemographyTileData } from './@types/IDemographyTileData'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { useAppDispatch } from '../../../../../../../redux/hooks'

const Banner = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    justify-content: center;
    color: ${palette.light.white};
    background-color: ${palette.product.location.medium};
    padding: 1rem;
    width: inherit;

    > span:first-child {
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    > span:last-child {
      > span {
        color: ${palette.light.white};
      }
    }
  `
})

export const DemographyTile: React.FC<{data: IDemographyTileData, isPdf?:boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobileDevice = useCheckForMobileDevice()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { value } = useSelector((state: RootState) => state.location)

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const sortedAgeRange = data?.value?.ageRange?.length
    ? data?.value?.ageRange?.slice().sort((a, b) => (a.order > b.order ? 1 : -1))
    : []

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        <TileFormSpan>
          {t('placeme.demography_tile.form_span_1')}
        </TileFormSpan>
        {/* age */}
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.demography_tile.section_title_1')}</TileFormSectionTitle>
          <TileFormRow width='100%'>
            <TileFormColumn
              align='start'
              width='60%'
            >
              <TileFormRowWithData>
                <span>{ t('placeme.demography_tile.row_with_data_1_span_1')}</span>
                <span>{`${parseFloat(((data?.value?.workingAgeStructure?.above65 || data?.value?.workingAgeStructure?.above60) * 100).toFixed(1))}%`}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_2_normal_1')}
                {data?.value?.workingAgeStructure?.above65
                  ? <strong>{t('placeme.demography_tile.form_span_2_strong_1')}</strong>
                  : <strong>{t('placeme.demography_tile.form_span_2_strong_1.foreign')}</strong>}
                {t('placeme.demography_tile.form_span_2_normal_2')}
              </TileFormSpan>
              <TileFormRowWithData>
                <span>{t('placeme.demography_tile.row_with_data_2_span_1')}</span>
                <span>{`${parseFloat(((data?.value?.workingAgeStructure?.under18 || data?.value?.workingAgeStructure?.under15) * 100).toFixed(1))}%`}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_3_normal_1')}
                {data?.value?.workingAgeStructure?.under18
                  ? <strong>{t('placeme.demography_tile.form_span_3_strong_1')}</strong>
                  : <strong>{t('placeme.demography_tile.form_span_3_strong_1.foreign')}</strong>}
                {t('placeme.demography_tile.form_span_3_normal_2')}
              </TileFormSpan>
            </TileFormColumn>
            <TileFormColumn
              align='center'
              width='40%'
            >
              <Banner>
                <span>{t('placeme.demography_tile.banner')}</span>
                <Price
                  currency={t('generic.years')}
                  digits={0}
                  value={data?.value?.averageAge}
                />
              </Banner>
            </TileFormColumn>
          </TileFormRow>
        </TileFormSection>
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.demography_tile.section_title_2')}</TileFormSectionTitle>
          <TileFormRow
            width='100%'
            wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
          >
            <TileFormColumn
              align='start'
              width={!isMobileDevice ? '70%' : '100%'}
            >
              <TileFormRowWithData legend='#362FB7'>
                <span>{t('placeme.demography_tile.row_with_data_3_span_1')}</span>
                <span>{`${parseFloat((data?.value?.sexStructure?.f * 100).toFixed(1))}%`}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_4')}
              </TileFormSpan>
              <TileFormRowWithData legend='#7E7AD2'>
                <span>{t('placeme.demography_tile.row_with_data_4_span_1')}</span>
                <span>{`${parseFloat((data?.value?.sexStructure?.m * 100).toFixed(1))}%`}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.demography_tile.form_span_5')}
              </TileFormSpan>
            </TileFormColumn>
            <TileFormColumn width={!isMobileDevice ? '30%' : '100%'}>
              {isPdf
                ? (
                  <img
                    alt='chart'
                    src={`data:image/svg+xml;base64,{{ donutchart_${tileId.replace(/-/g, '_')
                    } }}`}
                  />
                )
                : (
                  <DoughnutChart
                    data={[data?.value?.sexStructure?.f * 100, data?.value?.sexStructure?.m * 100]
                      .map(number => parseFloat(number.toFixed(1)))}
                    innerLabels
                    labels={[t('placeme.demography_tile.doughnut_chart.women'), t('placeme.demography_tile.doughnut_chart.men')]}
                    valueType='percentages'
                    width={150}
                  />
                )}

            </TileFormColumn>
          </TileFormRow>
        </TileFormSection>
        <TileFormSection>
          <TileFormSectionTitle>{value?.country !== 'NL' ? t('placeme.demography_tile.section_title_3') : t('placeme.demography_tile.section_title_3.netherlands')}</TileFormSectionTitle>
          {isPdf
            ? (
              <img
                alt='chart'
                src={`data:image/svg+xml;base64,{{ barchart_${tileId.replace(/-/g, '_')
                } }}`}
              />
            )
            : (
              <BarChart
                axisLabels={{
                  xAxis: t('placeme.demography_tile.bar_chart_axis_x_label'),
                }}
                beginsAtZero
                dataset={value?.country !== 'NL'
                  ? [
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_1'),
                      data: sortedAgeRange.map(ar => (ar.f)),
                    },
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_2'),
                      data: sortedAgeRange.map(ar => (ar.m)),
                    },
                  ]
                  : [
                    {
                      label: t('placeme.demography_tile.bar_chart_dataset_label_1.netherlands'),
                      data: sortedAgeRange.map(ar => (ar.t)),
                    },
                  ]}
                labels={sortedAgeRange.map(ar => ar.label)}
                precision
              />
            )}

        </TileFormSection>
      </TileFormWrapper>
    )
}

export default DemographyTile
