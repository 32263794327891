
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { IHintsGliderProps } from './@types/IHintsGlider.props'
import { Hint } from '../../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    width: 100%;

    .glider {
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-bottom: 1rem;
    }

    .glider::-webkit-scrollbar {
      display: none;
    }

    .glider-slide {
      background-color: ${palette.light.white};
    }

    .glider-prev {
      bottom: -2.5rem;
      top: unset;
      left: unset;
      right: 3rem;
    }

    .glider-next {
      bottom: -2.5rem;
      top: unset;
      right: 1rem;
    }
  `
})

const Counter = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.dark.normal};
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    line-height: ${typography.small.pt_13_semibold.lineHeight};

    > span:first-child {
      color: ${palette.blue};
    }

    > span:nth-child(2) {
      margin: 0 0.25rem;
    }
  `
})

const BottomWrapper = styled.div`
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const HintsGlider = ({ hints }: IHintsGliderProps): JSX.Element => {
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const handleScrollUp = () => {
    if (currentSlide === hints.length - 1) {
      setCurrentSlide(0)
    } else {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const handleScrollDown = () => {
    if (currentSlide === 0) {
      setCurrentSlide(hints.length - 1)
    } else {
      setCurrentSlide(currentSlide - 1)
    }
  }

  return (
    hints.length
      ? (
        <Wrapper>
          <Hint
            key={hints[currentSlide]?.content}
            content={hints[currentSlide]?.content}
            example={hints[currentSlide]?.example}
            nextPage={handleScrollUp}
            pagesAmount={hints?.length}
            prevPage={handleScrollDown}
            t={t}
          />
          {hints.length > 1 && (
            <BottomWrapper>
              <Counter>
                <span>{currentSlide + 1}</span>
                <span>z</span>
                <span>{hints.length}</span>
              </Counter>
            </BottomWrapper>
          )}
        </Wrapper>
      )
      : <></>
  )
}
