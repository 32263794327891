import { DataType } from './@types/ISalesPotentialTileData'

export type SalesPotentialLabels = {key: DataType, value: string, unit?: string, step?: number,
  min?: number, max?: number}[];

export const labels: SalesPotentialLabels = [
  {
    key: 'inhabitants',
    value: 'placeme.sales_potential_tile.radar.inhabitants',
    unit: 'placeme.sales_potential_tile.params.inhabitants',
    step: 100,
  },
  {
    key: 'carTraffic',
    value: 'placeme.sales_potential_tile.radar.car_traffic',
    unit: 'placeme.sales_potential_tile.params.cars',
    step: 5,
  },
  {
    key: 'footTraffic',
    value: 'placeme.sales_potential_tile.radar.foot_traffic',
    unit: 'placeme.sales_potential_tile.params.people',
    step: 5,
  },
  {
    key: 'purchasing',
    value: 'placeme.sales_potential_tile.radar.purchasing',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 100,
  },
  {
    key: 'industrialBuildings',
    value: 'placeme.sales_potential_tile.radar.industrial_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'residentialBuildings',
    value: 'placeme.sales_potential_tile.radar.residential_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'officeBuildings',
    value: 'placeme.sales_potential_tile.radar.office_buildings',
    unit: 'placeme.sales_potential_tile.params.buildings',
    step: 5,
  },
  {
    key: 'shopArea',
    value: 'placeme.sales_potential_tile.radar.shop_area',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 1,
  },
  {
    key: 'storeType',
    value: 'placeme.sales_potential_tile.radar.store_type',
  },
  {
    key: 'averageShoppingValue',
    value: 'placeme.sales_potential_tile.radar.average_shopping_value',
    unit: 'placeme.sales_potential_tile.params.currency',
    step: 0.5,
  },
  {
    key: 'preferences',
    value: 'placeme.sales_potential_tile.radar.preferences',
  },
  {
    key: 'greenery',
    value: 'placeme.sales_potential_tile.radar.greenery',
    unit: 'placeme.sales_potential_tile.params.area',
    step: 5,
  },
]
