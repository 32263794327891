/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { addDays, format } from 'date-fns'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { mediaMax as media, theme } from '@dataplace.ai/ui-components/themes'
import styled, { css } from 'styled-components'
import { Input } from '@dataplace.ai/ui-components/atoms'
import { getDayjs } from '@dataplace.ai/functions/utils/filtering'
import { createFlashMessage } from '@dataplace.ai/functions/utils'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme

  return css`
    
    background-color: ${palette.white};
    border-radius: ${corners.default.borderRadius};
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: min-content;
    margin: 0 auto;
    
    ${media.mobile`
      transform: scale(0.7);
      margin-left: -55px;
      margin-top: -50px;
    `}

    .rdrDateRangePickerWrapper {
      overflow: hidden;
      width: 100%;
    }
    
    .rdrDefinedRangesWrapper, 
    .rdrMonthAndYearPickers, 
    .rdrNextPrevButton > i {
      display: none;
    }

    .rdrNextPrevButton {
      background-color: transparent;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      position: relative;
    }

    .rdrNextPrevButton.rdrPprevButton {
      background-image: url('assets/icons/arrows/arrLeft.svg');
    }

    .rdrNextPrevButton.rdrNextButton {
      background-image: url('assets/icons/arrows/arrRight.svg');
    }

    .rdrMonthName {
      text-align: center;
    }
    
    .rdrMonthAndYearWrapper {
      padding: 0;
      margin-bottom: -50px;
    }
    
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: ${palette.black}
    }

    .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span::after,
    .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span::after {
      background: ${palette.light.white} !important;
    }

    .rdrStartEdge, .rdrEndEdge {
      background-color: ${palette.results.green.dark};
    }

    .rdrDayToday .rdrDayNumber span::after,
    .rdrDayToday .rdrDayNumber span::after{
      background: ${palette.black} !important;
    }

    .rdrStartEdge ~ span > span, .rdrEndEdge ~ span > span {
      color: ${palette.light.white} !important;
    }

    .rdrSelected {
      border-radius: 0.25rem;
    }
    
    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge,
    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge,
    .rdrStartEdge, 
    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrEndEdge, 
    .rdrDayEndPreview {
      border-radius: 0.25rem !important; 
    }
  `
})

const InputsWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme

  return css`
    display: flex;
    align-items: center;
  color: ${palette.dark.normal};
  white-space: nowrap;

  > input {
      max-width: 12ch;
    }
    
    > p:first-child {
      margin: 0;
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  
    > p {
      margin: 0 0.25rem;
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }
  `
})

interface IRangeCalendarProps {
  onChange: (startDate?: Date, endDate?: Date) => void
  months?: number
  minDate?: Date
  maxDate?: Date
  startDate?: Date
  endDate?: Date
}

interface ISelection {
  startDate?: Date,
  endDate?: Date,
  key: string,
}

export const RangeCalendar: React.FC<IRangeCalendarProps> = ({
  onChange, months, minDate, maxDate, startDate, endDate,
}) => {
  const { palette } = theme()

  const [from, setFrom] = useState<string>(format(startDate || new Date(), 'dd.MM.yyyy'))
  const [to, setTo] = useState<string>(format(endDate || addDays(new Date(), 7), 'dd.MM.yyyy'))
  const [state, setState] = useState<ISelection[]>([
    {
      startDate: startDate || new Date(),
      endDate: endDate || addDays(new Date(), 7),
      key: 'selection',
    },
  ])

  useEffect(() => {
    const startDate = state[0]?.startDate
    const endDate = state[0]?.endDate
    if (startDate && endDate) {
      onChange(startDate <= endDate ? startDate : undefined, endDate >= startDate ? endDate : undefined)
    } else {
      onChange(undefined, undefined)
    }
  }, [state])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (range: any) => {
    setState([range.selection])

    setFrom(format(range.selection.startDate, 'dd.MM.yyyy'))
    setTo(format(range.selection.endDate, 'dd.MM.yyyy'))
  }

  const handleInputChange = (date: string, direction: string) => {
    const value = date.trim()
    const dayJsDate = getDayjs(value)
    const inputIsValid = direction === 'from'
      ? validateInputField(value, state[0]?.endDate, direction, true)
      : validateInputField(value, state[0]?.startDate, direction, true)
    if (direction === 'from') {
      setFrom(value)
      setState([{
        ...state[0],
        startDate: inputIsValid ? dayJsDate.toDate() : undefined,
      }])
    }
    if (direction === 'to') {
      setTo(value)
      setState([{
        ...state[0],
        endDate: inputIsValid ? dayJsDate.toDate() : undefined,
      }])
    }
  }

  const validateInputField = (date: string, otherDate?: string | Date, direction?: string, notify = false): boolean => {
    const dayJsDate = getDayjs(date)
    const dateRangeCorrect = dayJsDate.isValid()
      && dayJsDate.toDate() >= new Date(2020, 0, 1)
      && dayJsDate.toDate() <= new Date()
    if (!dayJsDate.isValid() && date.length === 10 && notify) {
      createFlashMessage({
        message: 'status.error.input.date_is_invalid',
      })
    }
    if (dayJsDate.isValid() && !dateRangeCorrect && notify) {
      createFlashMessage({
        message: 'status.error.input.date_not_in_correct_range',
      })
    }
    if (otherDate && direction) {
      const otherDayJsDate = getDayjs(otherDate)
      if (otherDayJsDate.isValid()) {
        const dateInCorrectOrder = direction === 'from'
          ? dayJsDate.toDate() <= otherDayJsDate.toDate()
          : dayJsDate.toDate() >= otherDayJsDate.toDate()
        if (dayJsDate.isValid() && !dateInCorrectOrder && notify) {
          createFlashMessage({
            message: 'status.error.input.date_not_in_correct_order',
          })
        }
        if (direction === 'from') {
          return dayJsDate.isValid()
            && dayJsDate.toDate() >= new Date(2020, 0, 1)
            && dayJsDate.toDate() <= new Date()
            && dateInCorrectOrder
        }
        if (direction === 'to') {
          return dayJsDate.isValid()
            && dayJsDate.toDate() >= new Date(2020, 0, 1)
            && dayJsDate.toDate() <= new Date()
            && dateInCorrectOrder
        }
      }
    }
    return dateRangeCorrect
  }

  return (
    <Wrapper>
      <InputsWrapper>
        <p>Wybierz okres</p>
        <p>od</p>
        <Input
          error={validateInputField(from, to, 'from') ? undefined : 'error'}
          onChange={(e) => handleInputChange(e.target.value, 'from')}
          placeholder='25.05.2021'
          value={from}
        />
        <p>do</p>
        <Input
          error={validateInputField(to, from, 'to') ? undefined : 'error'}
          onChange={(e) => handleInputChange(e.target.value, 'to')}
          placeholder='25.05.2021'
          value={to}
        />
      </InputsWrapper>
      <DateRangePicker
        direction='horizontal'
        inputRanges={[]}
        maxDate={maxDate}
        minDate={minDate}
        months={months || 2}
        onChange={handleSelect}
        rangeColors={[palette.results.green.opaque]}
        ranges={state}
        showDateDisplay={false}
        staticRanges={[]}
      />
    </Wrapper>
  )
}

export default RangeCalendar
