import { RootState } from 'apps/placeme/src/redux/store'
import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'
import { DropdownTile } from '../DropdownTile'

interface IWrapper {
  isOpen?: boolean
}

const Wrapper = styled.div<IWrapper>(
  ({ isOpen }) =>
    css`
      display: flex;
      flex-direction: column;
      margin: 1rem 0 2rem 0;
      ${!isOpen
      && css`
        margin-top: 0;
      `}
    `,
)

interface ISidebarList {
  isOpen?: boolean
  data: ICombinedSectionTile[]
  handleCategoryOpen: (categoryId: string) => void
  showDisabledByPricingPlan: boolean | undefined
}

export const SidebarList = ({
  isOpen,
  data,
  handleCategoryOpen,
  showDisabledByPricingPlan,
}: ISidebarList): JSX.Element => {
  // constants
  const {
    values, canBeSave,
  } = useSelector(
    (state: RootState) => state.analysis,
  )
  const { currentSubscriptionData } = useSelector(
    (state: RootState) => state.location,
  )

  // functions
  const handleCategoryDisabled = (sectionId: string) => {
    let isDisabled = false
    if (!canBeSave) {
      if (values.length) {
        if (values.find(val => val.id === sectionId)) {
          isDisabled = false
        } else {
          isDisabled = true
        }
      } else {
        isDisabled = true
      }
    }
    return isDisabled
  }
  return (
    <Wrapper isOpen={isOpen}>
      {data.map((sectionTile) => (
        <DropdownTile
          key={sectionTile.id}
          disabled={handleCategoryDisabled(sectionTile.id)}
          full={isOpen}
          handleCategoryOpen={handleCategoryOpen}
          isCategoryOpen={sectionTile.isCategoryOpen}
          sectionTile={sectionTile}
          showDisabledByPricingPlan={showDisabledByPricingPlan}
          subscriptionType={currentSubscriptionData?.value?.planExact || ''}
        />
      ))}
    </Wrapper>
  )
}

SidebarList.defaultProps = {
  isOpen: true,
}
