/* eslint-disable max-lines */
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRowWithData, LinkWithIconCheckOnMap, MapTile, TitleFormSectionSubTitle,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import styled, { css } from 'styled-components'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { config } from 'apps/placeme/src/config'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { MainDropdown, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { subDays } from 'date-fns'
import { customerOriginTableData } from './data'
import { ICustomerOriginTileData } from './@types/ICustomerOriginTileData'
import { DateRangeSelector } from '../../../../molecules'
import { TileFooter } from '../../../../atoms'
import { saveNewRangeAction, saveTileData } from '../../../../../slice/analysisSlice'
import { RootState } from '../../../../../../../redux/store'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { SettingsBox } from '../../../../molecules/SettingsBox/SettingsBox'

const { v4: uuidv4 } = require('uuid')

const RoundedSpan = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: ${palette.product.location.medium};
    color: ${palette.light.white};
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    >p{
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
      text-align: center;
      }
  `
})

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  () => css`
      margin-bottom: 1rem;
    `,
)

const LegendWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme

  return css`
    display: flex;
    margin-top: 1rem;
    width:100%;

    >div{
      display: flex;
      align-items: center;

      >p{
        margin-left: 1.5rem;
        color: ${palette.black};
        font-size: ${typography.small.pt_13_regular.fontSize};
        font-weight: ${typography.small.pt_13_regular.fontWeight};
        line-height: ${typography.small.pt_13_regular.lineHeight};
      }

      >span{
            width: 60px;
            height: 60px;
            border: 1px solid;
      }

      :first-of-type{
        >span{
            border-color: #70C43B;
            background-color: rgba(112, 196, 59, 0.2);
          }
      }

      :last-of-type{
        margin-left: 0.5rem;
        >span{
            border-color: #F08F7F;
            background-color: rgba(240, 143, 127, 0.2);
          }
      }
    }
  `
})

const mapTypes = ['destination_road', 'area']

export const CustomerOriginTile: React.FC<{data: ICustomerOriginTileData, isExtraPaid?: boolean, tileId: string}> = ({
  data, isExtraPaid, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { labels } = customerOriginTableData

  const {
    creditsAmount, values,
  } = useSelector((state: RootState) => state.analysis)

  const {
    currentSubscriptionData, value,
  } = useSelector((state: RootState) => state.location)

  const [dateRanges, setDateRanges] = useState<{startDate?: Date, endDate?: Date}>({})
  const [accepted, setAccepted] = useState<boolean>(false)
  const [token, setToken] = useState('')
  const [mapType, setMapType] = useState<string>('destination_road')
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  const catchmentId = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  // functions
  const handleSubmit = () => {
    if (creditsAmount) {
      const chosenRange = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
        t.id === tileId)?.chosenRange

      if (token && !chosenRange?.catchmentId) {
        dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'traffic', tileId, {
          id: `${tileId}-250-line`,
          value: 250,
          type: 'line',
        }))
      }
      analytics?.track('Tile Data Generated', {
        tile: tileId?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
    }
  }

  const fetchData = useCallback(async () => {
    if (accepted && catchmentId) {
      const body = {
        catchmentId,
        dataRanges: {
          startDate: dateRanges.startDate?.getTime(),
          endDate: dateRanges.endDate?.getTime(),
        },
      }

      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post<ITileData>(ENDPOINTS.CUSTOMER_ORIGIN_TILE, body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      dispatch(saveTileData('traffic', tileId, saveData))
    }
  }, [accepted, token, catchmentId])

  const handleMapSelect = (id: string) => {
    setMapType(id)
  }

  const handleLayer = () => {
    if (data?.value) {
      const features : IFeatureCollection['data']['features'] = []
      if (mapType === 'destination_road') {
        data?.value?.sources?.roadLayerCustomOrigin?.data?.features?.forEach(feat => {
          features.push({
            geometry: feat?.geometry,
            properties: {
              style: {
                color: '#348700',
              },
              width: '1px',
            },
            type: 'Feature',
          })
        })
      }
      else {
        data?.value?.sources?.areaCustomOrigin?.data?.features?.forEach(feat => {
          features.push({
            geometry: feat?.geometry,
            properties:{
              style: {
                ...feat?.properties?.style,
                weight: 0.4,
              },
            },
            type: 'Feature',
          })
        })
      }
      data?.value?.statistics?.top5?.forEach(point =>
      {
        features.push({
          geometry: {
            coordinates: [point?.lng, point?.lat],
            type: 'Point',
          },
          properties:{
            title: `<div style="max-width:450px; display: flex; flex-direction: column; flex-wrap: wrap;"><span>${point?.address}</span><span>${`${t('placeme.customer_origin_tile.section_title_1')}: ${formatNumber(point?.distance)} m`}</span></div>`,
            pinnedItem: {
              class: 'poi-img',
              html: `<span style="display: flex; width: 1.25rem; height: 1.25rem; border-radius: 50%; color: white; background-color: #7E7AD2; align-items: center; justify-content: center;"><p style="text-align: center;">${point?.pointId}</p></span>`,
            },
          },
          type: 'Feature',
        })
      })
      return [{
        id: 'dest_layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'destination_road',
          },
        },
      }]
    }
    return undefined
  }

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (catchmentId && !data?.value && accepted) {
      fetchData()
    }
  }, [fetchData, catchmentId, !data?.value, accepted])

  useEffect(() => {
    if (catchmentId) {
      setAccepted(true)
    }
  }, [catchmentId])

  return (
    <StyledTileFormWrapper background={data?.value ? 'light' : 'dark'}>

      {(!data?.value && !accepted) && (
        <DateRangeSelector
          accepted={accepted}
          description={t('placeme.customer_origin_tile.date_range_description')}
          maxDate={subDays(new Date(), 3)}
          minDate={new Date(2021, 10, 15)}
          onChange={(startDate, endDate) => setDateRanges({
            startDate,
            endDate,
          })}
          setAccepted={setAccepted}
          title={t('placeme.customer_origin_tile.date_range_title')}
          value={dateRanges}
        />
      )}
      {(!data?.value && !accepted) && (
        <TileFooter
          disabled={!dateRanges?.startDate || !dateRanges?.endDate}
          isExtraPaid={isExtraPaid}
          isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
          label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
          onAccept={handleSubmit}
          onCancel={() => {
            const chosenRange = values?.find(c => c.id === 'traffic')?.tiles?.find(t =>
              t.id === tileId)?.chosenRange
            analytics?.track('Tile Cancel Button Clicked', {
              tile: tileId?.split('-')[0],
              range: {
                type: chosenRange?.type,
                value: chosenRange?.value,
              },
            })
          }}
          tile='customer_origin'
          value='1'
        />
      )}
      {(accepted || data?.value) && (
        <>
          {(!data || data?.loading)
            ? <Loader />
            : (
              <>
                <TileFormSection>
                  <SettingsBox
                    noEdit
                    sectionTile='traffic'
                    tile={tileId}
                    typeRanges={{
                      date: {
                        startDate: data?.value?.dataRanges?.startDate,
                        endDate: data?.value?.dataRanges?.endDate,
                      },
                    }}
                  />
                  <TileFormSectionTitle>{t('placeme.customer_origin_tile.section_title_1')}</TileFormSectionTitle>
                  <TileFormRowWithData>
                    <span>{t('placeme.customer_origin_tile.row_with_data_1_span_1')}</span>
                    <span>
                      {formatNumber(data?.value?.statistics?.averageDistance)}
                      {' '}
                      km
                    </span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.customer_origin_tile.form_span_1')}
                  </TileFormSpan>
                  <TileFormRowWithData>
                    <span>{t('placeme.customer_origin_tile.row_with_data_1_span_2')}</span>
                    <span>
                      {formatNumber(data?.value?.statistics?.maxDistance)}
                      {' '}
                      km
                    </span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.customer_origin_tile.form_span_2')}
                  </TileFormSpan>
                </TileFormSection>

                <TileFormSection>
                  <TileFormSectionTitle>{t('placeme.customer_origin_tile.section_title_2')}</TileFormSectionTitle>
                  <TileFormSpan
                    style={{
                      marginBottom: '1rem',
                    }}
                  >
                    {t('placeme.customer_origin_tile.form_span_3')}
                  </TileFormSpan>
                  <Table
                    content={data?.value?.statistics?.top5.map((value) =>
                      [<RoundedSpan key={value.pointId}><p>{value.pointId}</p></RoundedSpan>,
                        <span key={value.address}>{value.address}</span>,
                        <span
                          key={value.distance}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {formatNumber(value.distance)}
                        </span>,
                      ])}
                    customer
                    gap='1rem'
                    headerTemplate='1fr 5fr 2fr'
                    headerTextAlign='left'
                    labels={labels.map((label) => (
                      <span key={uuidv4()}>{t(label)}</span>
                    ))}
                    rowTemplate='1fr 5fr 2fr'
                  />
                </TileFormSection>
                <StyledTitleFormSectionSubTitle>
                  <span>{t('placeme.customer_origin.map_title')}</span>
                  <MainDropdown
                    content={(
                      <>
                        {mapTypes.map((s) => (
                          <button
                            key={s}
                            onClick={() => handleMapSelect(s)}
                            style={{
                              textTransform: 'none',
                            }}
                            type='button'
                          >
                            {t(`placeme.map_type.${s}`)}
                          </button>
                        ))}
                      </>
                    )}
                    header={(
                      <p>
                        {t('placeme.map_types')}
                        :
                        {mapType && <span>{t(`placeme.map_type.${mapType}`)}</span>}
                      </p>
                    )}
                    style={{
                      position: 'relative',
                    }}
                  />
                  <LinkWithIconCheckOnMap onClick={() => setIsMapDisplayed(!isMapDisplayed)} />
                </StyledTitleFormSectionSubTitle>
                <MapTile
                  dragging
                  height='500px'
                  layers={handleLayer()}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  width='100%'
                  zoom={15}
                  zoomControl
                />
                {mapType === 'area' && (
                  <LegendWrapper>
                    <div>
                      <span />
                      <p>{t('placeme.customer.legend_1.description')}</p>
                    </div>
                    <div>
                      <span />
                      <p>{t('placeme.customer.legend_2.description')}</p>
                    </div>
                  </LegendWrapper>
                )}
                <TileFormSection />
              </>
            )}
          {isMapDisplayed && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={handleLayer()}
              location={value}
              mapId={`customer-map-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'customer_origin')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapDisplayed}
              zoom={15}
            />
          )}
        </>
      )}
    </StyledTileFormWrapper>
  )
}

export default CustomerOriginTile
