
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  IconButton,
  LinkWithIconCheckOnMap,
  MapTile,
  TileFormParagraph,
  TileFormRowWithData,
  TileFormSection,
  TileFormWrapper, TitleFormSectionSubTitle,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import { SelectButton } from '@dataplace.ai/ui-components/molecules/SelectButton'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ILocation } from '@dataplace.ai/types'
import { ReactComponent as HelpCircle } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { ReactComponent as Edit } from '../../../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/edit.svg'
import { LocalizationSelector, LocalizationSelectorWrapper, TileFooter } from '../../../../atoms'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { saveTileData } from '../../../../../slice/analysisSlice'
import { ILocalMarketShareTileData } from './@types/ILocalMarketShareTileData'
import { RootState } from '../../../../../../../redux/store'

const { v4: uuidv4 } = require('uuid')

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  () => css`
      margin-bottom: 0;

      > span:first-child {
        flex-grow: unset;
      }

      button:last-child {
        margin-left: auto;
      }
    `,
)

const StyledHelpCircle = styled(HelpCircle)(({ theme }) => {
  const { palette } = theme
  return css`
    cursor: pointer;
    min-width: 1rem;
    width: 1rem;
    margin-bottom: -0.2rem;

    > path {
      fill: ${palette.blue};
    }`
})

const TooltipWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners, shadows,
  } = theme
  return css`
    background-color: rgba(21, 24, 31, 0.8);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    span {
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    ul {
      margin: 1rem 0 1rem 1rem;
    }

    li {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    img {
      max-height: 250px;
      max-width: 250px;
    }
  `
})

const PointsWrapper = styled.div(() => css`
      display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;
    `)

const Point = styled.div(({ theme }) => {
  const { corners } = theme
  return css`
    display: flex;
    flex-direction: row;
    width: 40%;
    margin-right: 10%;

    :before {
      content: '';
      width: 1.5rem;
      height: 100%;
      border-radius: ${corners.default.borderRadius};
      margin-right: 0.5rem;
      background-color: ${`rgb(${uuidv4() * 255}, ${uuidv4() * 255}, ${uuidv4() * 255})`};
    }
  `
})

const PointDataWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
  `
})

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

const PointData = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;

    > span:first-child {
      margin-right: 0.5rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }
    > span:last-child {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const ValueWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme

  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${palette.light.main};
    border: 1px dashed ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    color: ${palette.dark.normal};

    > div:first-child {
      display: flex;
      align-items: center;

      > span:nth-child(1) {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > span:nth-child(2) {
      flex-grow: 1;
      padding: 0 1rem;
      color: ${palette.blue};
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }
    }
  `
})

// const BoldSpan = styled.span(({ theme }) => {
//   const {
//     palette, typography,
//   } = theme
//   return css`
//     color: ${palette.black};
//     font-size: ${typography.small.pt_13_semibold.fontSize};
//     font-weight: ${typography.small.pt_13_semibold.fontWeight};
//     line-height: ${typography.small.pt_13_semibold.lineHeight};
//   `
// })

export const LocalMarketShareTile: React.FC<{
  data: ILocalMarketShareTileData, isExtraPaid?:boolean, tileId: string }> = ({
  data, isExtraPaid, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [accepted, setAccepted] = useState<boolean>(false)

  const [localizations, setLocalizations] = useState<ILocation[]>([])
  const [localLocalizations, setLocalLocalizations] = useState<ILocation[]>([])
  const { analytics } = useContext(AnalyticsContext)
  const {
    value, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { values } = useSelector((state: RootState) => state.analysis)

  const handleSubmit = () => {
    const chosenRange = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange
    analytics?.track('Tile Data Generated', {
      location: {
        ...value,
      },
      tile: tileId?.split('-')[0],
      range: {
        type: chosenRange?.type,
        length: chosenRange?.value,
      },
    })
    setLocalizations(localLocalizations)
    setAccepted(true)
  }

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const fetchData = useCallback(async () => {
    if (localizations.length) {
      const body = localizations.length
        ? {
          categoryId: 'mock-category',
          location: {
            lat: value?.lat,
            lng: value?.lng,
          },
          addresses: localizations?.map(l => ({
            id: l.placeId,
            address: l.address,
            lat: l.lat,
            lng: l.lng,
          })),
        }
        : []

      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post<ITileData>(ENDPOINTS.LOCAL_MARKET_SHARE_TILE, body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      dispatch(saveTileData('potential', 'local_market_share', saveData))
    }
  }, [localizations, token])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // const handleDelete = (localizationId : string) => {
  //   const updatedList = localLocalizations.filter((local) => local.placeId !== localizationId)
  //   setLocalLocalizations(updatedList)
  //   setLocalizations(updatedList)
  // }

  const tooltipContent = () => (
    <TooltipWrapper>
      <span>{t('placeme.local_market_share_tile.tooltip_title')}</span>
      <ul>
        <li>{t('placeme.local_market_share_tile.list_1')}</li>
        <li>{t('placeme.local_market_share_tile.list_2')}</li>
        <li>{t('placeme.local_market_share_tile.list_3')}</li>
      </ul>
      <img
        alt='map'
        src='assets/images/rangeMap.svg'
      />
    </TooltipWrapper>
  )

  const addresses = () => {
    const addressesArray : string[] = []
    localizations.map(item => addressesArray.push(item.address))
    return addressesArray
  }

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>
      <TileFormSection>
        <TileFormRowWithData>
          <span>{t('placeme.local_market_share_tile.row_with_data_1_span_1')}</span>
        </TileFormRowWithData>
        <TileFormParagraph>
          {t('placeme.local_market_share_tile.paragraph_1')}
          <Popup
            closeOnDocumentClick
            on={['hover', 'focus']}
            position='right center'
            trigger={<StyledHelpCircle />}
          >
            {tooltipContent()}
          </Popup>
        </TileFormParagraph>

        {!localizations.length && (
          <LocalizationSelectorWrapper>
            {!!localLocalizations.length
              && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_has_items_title')}</NormalSpan>}
            {!localLocalizations.length
              && <NormalSpan>{t('placeme.local_market_share_tile.localization_selector_no_items')}</NormalSpan>}
            <LocalizationSelector
              instantAdd
              max={5}
              multiple
              onChange={setLocalLocalizations}
              tile={tileId}
            />
          </LocalizationSelectorWrapper>
        )}

        {!!data?.value?.localMarketShare && accepted && (
          <ValueWrapper>
            <div>
              <span>{t('placeme.localization_selector.points_categories')}</span>
              <span>sklepy spozywcze</span>
              <IconButton onClick={() => setAccepted(false)}>
                <Edit />
              </IconButton>
            </div>
            {`${t('placeme.localization_selector.points_added_to_analyse')} ${addresses().join(', ')}`}
          </ValueWrapper>
        )}

      </TileFormSection>
      {!!data?.value?.localMarketShare?.length && accepted && (
        <>
          <StyledTitleFormSectionSubTitle>
            <span>{t('placeme.local_market_share_tile.section_sub_title_1_span_1')}</span>
          </StyledTitleFormSectionSubTitle>
          <StyledTitleFormSectionSubTitle>
            <span>
              {t('placeme.local_market_share_tile.section_sub_title_1_span_2')}
            </span>
            <SelectButton
              options={addresses()}
              placeholder={t('generic.choose')}
            />
            <LinkWithIconCheckOnMap />
          </StyledTitleFormSectionSubTitle>
          <MapTile
            height='500px'
            mapId='example-map-data-tile'
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            width='100%'
            zoomControl
          />
          <StyledTitleFormSectionSubTitle>
            <span>{t('placeme.local_market_share_tile.section_sub_title_3_span_1')}</span>
          </StyledTitleFormSectionSubTitle>
          <TileFormParagraph>
            {t('placeme.local_market_share_tile.paragraph_2')}
          </TileFormParagraph>
          <PointsWrapper>
            {data?.value?.localMarketShare?.map(row => (
              <Point key={row?.address}>
                <PointDataWrapper>
                  <span>{row?.address}</span>
                  <PointData>
                    <span>{`${t('placeme.local_market_share_tile.average_share')}:`}</span>
                    <span>{`${row?.averageShareInDominationZone * 100}%`}</span>
                  </PointData>
                  <PointData>
                    <span>{`${t('placeme.local_market_share_tile.domination')}:`}</span>
                    <span>{row?.dominationInTheZone}</span>
                  </PointData>
                </PointDataWrapper>
              </Point>
            ))}
          </PointsWrapper>
        </>
      )}

      {!localizations.length && (
        <TileFooter
          disabled={!localLocalizations.length}
          isExtraPaid={isExtraPaid}
          isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
          label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
          onAccept={handleSubmit}
          onCancel={() => {
            const chosenRange = values?.find(c => c.id === 'potential')?.tiles?.find(t =>
              t.id === tileId)?.chosenRange
            analytics?.track('Tile Cancel Button Clicked', {
              location: {
                ...value,
              },
              tile: tileId?.split('-')[0],
              range: {
                type: chosenRange?.type,
                value: chosenRange?.value,
              },
            })
          }}
          tile='local_market_share'
          value='10'
        />
      )}

    </StyledTileFormWrapper>
  )
}
