/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  CloseIcon,
  TileFormParagraph,
} from '@dataplace.ai/ui-components/atoms'
import { ResourcesSelector } from '@dataplace.ai/ui-components/organisms'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { useTranslation } from 'react-i18next'
import { AnalyticsContext } from '@dataplace.ai/features'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { carTimeRanges, contentTimeRanges, footTimeRanges } from './data'
import { TileFooter } from '../../atoms'
import { RootState } from '../../../../../redux/store'
import { ITimeSelectorProps } from './@types/ITimeSelector.props'
import { ReactComponent as EyeIcon } from '../../../../../../../../libs/shared/assets/src/lib/icons/eyeIcon.svg'

const SelectorWrapper = styled.span(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: ${palette.light.white};
    border: 1px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};

    > span:first-child,
    > span > div > span {
      color: ${palette.dark.normal};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
    }

    > span:first-child {
      margin-bottom: 0.5rem;
    }
  `
})

const Wrapper = styled.span(
  () => css`
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
  `,
)

const ListSpan = styled.span(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    width: 50%;
    position: relative;
    padding: 0.375rem 0.5rem;
    flex-direction: row;
    margin: 0.25rem 0;
    align-items: center;
    background-color: ${palette.light.main};
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};

    > span {
      flex-grow: 1;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    :hover > span {
      color: ${palette.blue};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    > span > span {
      margin-right: 0.25rem;
    }
  `
})

// const SelectedSection = styled.div(({ theme }) => {
//   const {
//     palette, typography, corners,
//   } = theme

//   return css`
//     display: flex;
//     flex-direction: column;
//     background: ${palette.light.white};
//     border: 1.5px dashed ${palette.light.darkest};
//     border-radius: ${corners.default.borderRadius};
//     padding: 1rem;

//     > span > svg {
//       cursor: pointer;
//     }

//     > span {
//       display: flex;
//     }

//     > span > span {
//       flex-grow: 1;
//       color: ${palette.black};
//       font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
//       font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
//       line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
//       letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
//       text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
//     }

//     > div {
//       margin: 0.5rem 0;
//       display: flex;
//       color: ${palette.dark.normal};
//       font-size: ${typography.tiny.pt_12_regular.fontSize};
//       font-weight: ${typography.tiny.pt_12_regular.fontWeight};
//       line-height: ${typography.tiny.pt_12_regular.lineHeight};
//     }

//     > div > span,
//     > div > span > span {
//       margin-right: 0.25rem;
//     }
//   `
// })

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

const LegendIcon = styled.div<{opacity?: number}>(
  ({ opacity }) => css`
    background-color: #423AB3;
    opacity: ${opacity};
    width: 20px;
    height: 20px;
    margin: auto 10px auto 0;
  `,
)

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  margin-bottom: 0;
`

const ValuesWrapper = styled.div(() => css`
    display: flex;
    flex-direction: column;
    width: 100%;

  `)

const ValueTile = styled.div<{active: boolean, isPdf?: boolean}>(({
  theme, active, isPdf,
}) => {
  const {
    palette, typography,
  } = theme

  return css`
    display: flex;
    justify-content: ${isPdf ? 'flex-start' : 'space-between'};
    padding: 15px;
    background: white;
    border: 1px solid #EDF0F5;
    box-sizing: border-box;
    border-radius: 6px;
    color: ${palette.black};
    >div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        >p{
          font-size: ${typography.main.pt_15_semibold.fontSize};
          font-weight: ${typography.main.pt_15_semibold.fontWeight};
          line-height: ${typography.main.pt_15_semibold.lineHeight};

          >strong{
            font-size: ${typography.big.pt_28_semibold.fontSize};
            font-weight: ${typography.big.pt_28_semibold.fontWeight};
            line-height: ${typography.big.pt_28_semibold.lineHeight};
          }
        }

        >span{
          font-size: ${typography.small.pt_13_regular.fontSize};
          font-weight: ${typography.small.pt_13_regular.fontWeight};
          line-height: ${typography.small.pt_13_regular.lineHeight};
          color: ${palette.dark.normal};
          margin-top: 5px;
        }
    }
      .icon{
        display: ${active ? 'block' : 'none'};
        cursor: pointer;
      }

      :hover{
        .icon{
          display: block;
        }
      }

  ` })

interface IClickableLabel {
  resource: {
    label: string
    area: number
    active?:boolean
  }
  handleValueLabelClick?: (id: string) => void
  color?: string
  opacity?: number
  isPdf?: boolean
}

export const ClickableLabel: React.FC<IClickableLabel> = ({
  resource, handleValueLabelClick, color, opacity, isPdf,
}) => {
  const { t } = useTranslation()
  return (
    <ValueTile
      key={resource?.label}
      active={resource?.active || false}
      isPdf={isPdf}
    >
      {isPdf
      && (
        <LegendIcon
          color={color}
          opacity={opacity}
        />
      ) }
      <div>
        <p>
          {contentTimeRanges?.find(range => range?.id === resource?.label)?.content}
          {resource?.label?.split('_')[0] === 'walk'
            ? t('placeme.range_tile.minutes_by_foot')
            : t('placeme.range_tile.minutes_by_car')}
          {' '}
          <strong>{formatNumber(resource?.area * 1000000)}</strong>
        </p>
        <span>{t('placeme.range_tile.area_in_m2')}</span>
      </div>
      <EyeIcon
        className='icon'
        onClick={() => {
          if (handleValueLabelClick) {
            handleValueLabelClick(resource?.label)
          }
        }}
      />
    </ValueTile>
  )
}

export const TimeSelector = ({
  onSubmit,
  layers,
  tile,
  isExtraPaid,
  setAccepted,
  accepted,
  timeRanges,
  handleValueLabelClick,
  isPdf,
}: ITimeSelectorProps): JSX.Element => {
  const { t } = useTranslation()
  const [carRanges, setCarRanges] = useState<ResourceWithId[]>([])
  const [footRanges, setFootRanges] = useState<ResourceWithId[]>([])
  const [timeRangesWithActive, setTimeRangesWithActive] = useState<ITimeSelectorProps['timeRanges']>()
  const {
    value, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  const {
    creditsAmount, values,
  } = useSelector((state: RootState) => state.analysis)
  const { analytics } = useContext(AnalyticsContext)

  const hasItems = !!carRanges.length || !!footRanges.length
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const isDataValue = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
    t.id === tile)?.data?.value

  const timeRangesWithActivePDF = JSON.parse(localStorage?.getItem('accessRangePDF') || '[]') as ITimeSelectorProps['timeRanges']
  const layersPDF = JSON.parse(localStorage?.getItem('layersAccessRangePDF') || '[]') as IFeatureCollection

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleDelete = (resourceId: string) => {
    setCarRanges(carRanges.filter(range => range.id !== resourceId))
    setFootRanges(footRanges.filter(range => range.id !== resourceId))
  }

  const onAccept = () => {
    if (creditsAmount && setAccepted) {
      const chosenRange = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
        t.id === tile)?.chosenRange
      analytics?.track('Tile Data Generated', {
        location: {
          ...value,
        },
        tile: tile?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
      onSubmit([...carRanges, ...footRanges])
      setAccepted(true)
    }
  }

  const handleValueLabelClickWithActive = (label: string) => {
    if (handleValueLabelClick) {
      const newActive : ITimeSelectorProps['timeRanges'] = []
      timeRangesWithActive?.forEach(range => (range?.label === label
        ? newActive.push({
          ...range,
          active: true,
        })
        : newActive.push(range)))
      setTimeRangesWithActive(newActive)
      localStorage.setItem('accessRangePDF', JSON.stringify(newActive))
    }
  }

  useEffect(() => {
    if (timeRanges) {
      setTimeRangesWithActive(timeRanges?.map(range => ({
        ...range,
        active: false,
      })))
      if (timeRangesWithActive) {
        localStorage.setItem('accessRangePDF', JSON.stringify(timeRangesWithActive))
      }
    }
  }, [timeRanges])

  useEffect(() => {
    const activeTimeRange = timeRangesWithActive?.find(range => range?.active === true)
    if (handleValueLabelClick && timeRangesWithActive && activeTimeRange) {
      handleValueLabelClick(activeTimeRange?.label)
    }
  }, [timeRangesWithActive])

  useEffect(() => {
    if (layers) {
      localStorage.setItem('layersAccessRangePDF', JSON.stringify(layers[0]?.layer))
    }
  }, [layers])

  return (
    <>
      {!hasItems && !isDataValue && (
        <TitleFormSectionSubTitle>
          {t('placeme.time_selector.title')}
        </TitleFormSectionSubTitle>
      )}

      <Wrapper>
        {!accepted && !isDataValue && !isPdf && (
          <SelectorWrapper>
            <span>{t('placeme.time_selector.description')}</span>
            {hasItems && (
              <>
                {footRanges.map((resource) => (
                  <ListSpan key={resource.id}>
                    <span>
                      {resource.content}
                      {t('placeme.time_selector.by_foot')}
                    </span>
                    <CloseIcon onClick={() => handleDelete(resource.id)} />
                  </ListSpan>
                ))}
                {carRanges.map((resource) => (
                  <ListSpan key={resource.id}>
                    <span>
                      {resource.content}
                      {t('placeme.time_selector.by_car')}
                    </span>
                    <CloseIcon onClick={() => handleDelete(resource.id)} />
                  </ListSpan>
                ))}
              </>
            )}
            <>
              <TitleFormSectionSubTitle>
                {t('placeme.time_selector.by_foot')}
              </TitleFormSectionSubTitle>
              <ResourcesSelector
                multiSelect
                onChange={(resources) =>
                  setFootRanges(
                    footTimeRanges.filter((ft) => resources.includes(ft.id)),
                  )}
                resources={footTimeRanges}
                value={footRanges.map((fr) => fr.id)}
              />
              <TitleFormSectionSubTitle>
                {t('placeme.time_selector.by_car')}
              </TitleFormSectionSubTitle>
              <ResourcesSelector
                multiSelect
                onChange={(resources) =>
                  setCarRanges(
                    carTimeRanges.filter((ct) => resources.includes(ct.id)),
                  )}
                resources={carTimeRanges}
                value={carRanges.map((cr) => cr.id)}
              />
            </>
          </SelectorWrapper>
        )}
        {(isDataValue || isPdf) && (
          <ValuesWrapper>
            {!isPdf
              ? timeRangesWithActive?.map((resource) => (
                <ClickableLabel
                  key={resource?.label}
                  handleValueLabelClick={handleValueLabelClickWithActive}
                  resource={resource}
                />
              ))
              : timeRangesWithActivePDF?.map((resource, i) => (
                <ClickableLabel
                  key={resource?.label}
                  color={layersPDF?.data.features[i].properties.style?.fillColor}
                  handleValueLabelClick={handleValueLabelClickWithActive}
                  isPdf={isPdf}
                  opacity={layersPDF?.data.features[i].properties.style?.fillOpacity}
                  resource={resource}
                />
              ))}
          </ValuesWrapper>
        )}
        {(!isDataValue && !isPdf) && (
          <TileFooter
            disabled={!hasItems}
            isExtraPaid={isExtraPaid}
            isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
            label={isExtraPaid
              ? `${t('generic.apply_and_buy')}`
              : `${t('generic.apply')}`}
            onAccept={onAccept}
            onCancel={() => {
              const chosenRange = values?.find(c => c.id === 'access_logistics')?.tiles?.find(t =>
                t.id === tile)?.chosenRange
              analytics?.track('Tile Cancel Button Clicked', {
                location: {
                  ...value,
                },
                tile: tile?.split('-')[0],
                range: {
                  type: chosenRange?.type,
                  length: chosenRange?.value,
                },
              })
            }}
            tile='access_range'
            value={(carRanges.length + footRanges.length).toString() || ''}
          />
        )}
        {isDataValue && (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.time_selector.ranges_on_the_map')}</span>
              <LinkWithIconCheckOnMap onClick={handleMapOpen} />
            </StyledTitleFormSectionSubTitle>
            <TileFormParagraph>
              {t('placeme.access_range_tile.map_description.on_click')}
            </TileFormParagraph>
            {!isPdf && (
              <MapWrapper>
                <MapTile
                  dragging
                  height='100%'
                  layers={layers || undefined}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={value?.address}
                  width='100%'
                  zoom={12}
                  zoomControl
                />
              </MapWrapper>
            ) }
          </>
        )}

        {isMapDisplayed && !isPdf
          && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={layers || undefined}
              location={value}
              mapId={`time-select-access-map-${values?.find(c => c.id === 'access_logistic')?.tiles?.find(t => t.id === 'access_range')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapDisplayed}
              zoom={12}
            />
          )}
        {isPdf && (
          <img
            alt='map'
            src={`data:image/png;base64,{{ access_range_${tile.replace(/-/g, '_')
            } }}`}
            style={{
              margin: '50px auto 0 auto',
              width: '80%',
              borderRadius: '8px',
            }}
          />
        )}
      </Wrapper>
    </>
  )
}
