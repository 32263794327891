import { formatDate } from '@dataplace.ai/functions/utils'
import { PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { WarningModal } from '@dataplace.ai/ui-components/molecules'
import { saveChosenRangeAction, saveTileData } from '../../../slice/analysisSlice'
import { ITypeRange } from '../TypeRangeSelector'
import { ReactComponent as Edit } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/edit.svg'

const Separator = styled.span`
  margin: 0 0.2rem;
`

const Settings = styled.div(
  ({ theme }) => {
    const {
      typography, palette, corners,
    } = theme
    return css`
    display: flex;
    flex-direction: column;
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: ${palette.light.main};

    span {
      color: #484E5E;
    }

    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;

      > p {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        margin-bottom: 0.5rem;
      }

      > svg {
        cursor: pointer;
      }
    }
` },
)

export interface ISettingsBoxProps {
  tile: string,
  sectionTile: string,
  typeRanges: ITypeRange,
  matchLabelToRatio?: (n: string) => string,
  // eslint-disable-next-line react/no-unused-prop-types
  // eslint-disable-next-line react/require-default-props
  setAccepted?: React.Dispatch<React.SetStateAction<boolean>>
  isPdf?: boolean,
  noEdit?: boolean
}

export const SettingsBox = ({
  tile, sectionTile, typeRanges, isPdf, setAccepted, noEdit,
}: ISettingsBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { canBeSave } = useSelector((state: RootState) => state.analysis)
  const [isModalOpen, toggle] = useState(false)

  const handleModal = () => {
    toggle(!isModalOpen)
  }

  const handleDeleteRange = () => {
    if (setAccepted) setAccepted(false)
    dispatch(saveChosenRangeAction(sectionTile, tile, undefined))
    dispatch(saveTileData(sectionTile, tile, undefined))
    toggle(!isModalOpen)
  }

  const handleDate = (date: number) => {
    const newDate = new Date(date)
    return formatDate(newDate.toDateString())
  }

  return (
    <Settings>
      <PopupWithOverlay
        onClose={() => toggle(false)}
        open={isModalOpen}
      >
        <WarningModal
          agreementButtonText={t('generic.delete')}
          cancelButtonText={t('generic.cancel')}
          description={(
            <>
              {t('tile.delete.warning')}
              <br />
              {`${t('tile.delete.save_report')}.`}
            </>
          )}
          handleAgreement={handleDeleteRange}
          handleCancel={handleModal}
          heading={t('tile.delete.heading')}
          redWarning={t('tile.delete.red_warning')}
        />
      </PopupWithOverlay>
      { canBeSave && !isPdf && (
        <span>
          <p>{`${t('placeme.date_range_selector.settings')}`}</p>
          {!noEdit && <Edit onClick={handleModal} />}
        </span>
      )}
      <div>
        {typeRanges?.cost !== undefined && (
          <span>
            {t('placeme.type_range_selector.settings.cost')}
            {typeRanges?.cost}
            PLN
          </span>
        )}
        {(typeRanges?.area !== undefined && typeRanges?.cost !== undefined) && <Separator>|</Separator>}
        {typeRanges?.area && (
          <span>
            {t('placeme.type_range_selector.settings.area')}
            {typeRanges?.area}
            m2
          </span>
        )}
        {(typeRanges?.roundTheClock !== undefined) && <Separator>|</Separator>}
        {typeRanges?.roundTheClock !== undefined && (
          <span>
            {t('placeme.type_range_selector.settings.round_the_clock')}
            {typeRanges?.roundTheClock === 1 ? t('placeme.round_the_clock_yes') : t('placeme.round_the_clock_no')}
          </span>
        )}
        {(typeRanges?.hoursPerWeek !== undefined) && <Separator>|</Separator>}
        {typeRanges?.hoursPerWeek !== undefined && (
          <span>
            {t('placeme.type_range_selector.settings.hours_per_week')}
            {typeRanges?.hoursPerWeek}
            h
          </span>
        )}
        {(typeRanges?.hoursOnSaturday !== undefined) && <Separator>|</Separator>}
        {typeRanges?.hoursOnSaturday !== undefined && (
          <span>
            {t('placeme.type_range_selector.settings.hour?.hours_on_saturday')}
            {typeRanges?.hoursOnSaturday}
            h
          </span>
        )}
        {(typeRanges?.hoursOnSunday !== undefined) && <Separator>|</Separator>}
        {typeRanges?.hoursOnSunday !== undefined && (
          <span>
            {t('placeme.type_range_selector.settings.hours_on_sunday')}
            {typeRanges?.hoursOnSunday}
            h
          </span>
        )}
        {typeRanges?.date
        && (
          <span>
            {t('placeme.type_date.date_range')}
            {' '}
            {handleDate(typeRanges?.date?.startDate)}
            {' '}
            -
            {' '}
            {handleDate(typeRanges?.date?.endDate)}
          </span>
        )}
      </div>
    </Settings>
  )
}
