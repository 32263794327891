
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { tilesData } from 'apps/placeme/src/features/Analyse/utils/tilesData'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { subDays } from 'date-fns'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { ITrafficVisualisationTileData } from './@types/ITrafficVisualisationTileData'
import { RootState } from '../../../../../../../redux/store'
import { RangeAndDateTile } from '../../../../molecules'
import { SettingsBox } from '../../../../molecules/SettingsBox'

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

interface IExtendedFeatureCollection extends IFeatureCollection {
  data:{
    features:{
      type: string;
      geometry: {
        coordinates: number[];
        type: string;
      };
      properties: {
        title?: string | HTMLElement,
        pinnedItem?: {
          class: string;
          html: string;
        };
        id?: string,
        style:{
          color: string,
          fillColor:string,
          weight:number,
        }

      };
    }[];
  }}

export const TrafficVisualisationMapTile:
React.FC<{data: ITrafficVisualisationTileData, tileId: string, userId: string, isPdf: boolean, }> = ({
  data, tileId, userId, isPdf,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  const [accepted, setAccepted] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })

  useEffect(() => {
    if (!data) {
      setAccepted(false)
    }
  }, [data])

  useEffect(() => {
    if (data?.value && mapLocation) {
      dispatch(saveTileData('visualization', tileId, {
        ...data,
        mapLocation,
      }))
    }
  }, [mapLocation])

  const getCatchmentId = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const getLayers = () => {
    if (data?.value) {
      const features : IExtendedFeatureCollection['data']['features'] = []

      data?.value?.trafficVisualisationMap?.features?.forEach(feature => {
        features.push({
          geometry: feature?.geometry,
          properties: {
            ...feature?.properties,
          },
          type: 'Feature',
        }) })

      return [{
        id: `traffic_layer_${getCatchmentId}`,
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'traffic_layer_map',
          },
        },
      }]
    }
    return undefined
  }

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>
      {((!accepted && !data?.value) || (!accepted && !data?.value && !data?.loading) || !data)
        ? (
          <RangeAndDateTile
            accepted={accepted}
            category='visualization'
            maxDate={subDays(new Date(), 3)}
            maxRanges={tilesData.find(cat => cat.id === 'visualization')?.tiles.find(tile => tile.id === tileId.split('-')[0])?.maxRanges}
            minDate={new Date(2021, 10, 15)}
            setAccepted={setAccepted}
            tile={tileId}
            userId={userId}
          />
        )
        : ((!data || data?.loading
          ? <Loader />
          : (
            <TileFormWrapper>
              <SettingsBox
                isPdf={isPdf}
                noEdit
                sectionTile='visualization'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  date: {
                    startDate: data?.value?.dataRanges?.startDate,
                    endDate: data?.value?.dataRanges?.endDate,
                  },
                }}
              />
              <TitleFormSectionSubTitle>
                <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                {!isPdf && <LinkWithIconCheckOnMap onClick={handleMapOpen} />}
              </TitleFormSectionSubTitle>
              {!isPdf
              && (
                <MapTile
                  dragging
                  height='300px'
                  layers={getLayers()}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={value?.address}
                  setMapLocation={setMapLocation}
                  width='100%'
                  zoom={15}
                  zoomControl
                />
              ) }
              {isMapDisplayed && !isPdf
                && (
                  <MapVisualizationModal
                    isDisplayed={isMapDisplayed}
                    layers={getLayers()}
                    location={value}
                    mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'traffic_visualization')?.chosenRange?.catchmentId}`}
                    setIsDisplay={setIsMapDisplayed}
                    zoom={15}
                  />
                ) }
              {isPdf && (
                <img
                  alt='map'
                  src={`data:image/png;base64,{{ traffic_vis_map_${tileId.replace(/-/g, '_')
                  } }}`}
                  style={{
                    width: '80%',
                    margin: '50px auto 0 auto',
                    borderRadius: '8px',
                  }}
                />
              )}

            </TileFormWrapper>
          )))}
    </StyledTileFormWrapper>
  )
}

export default TrafficVisualisationMapTile
