import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { INavlinkProps } from './@types/INavlinkProps.props'

const StyledNavLink = styled(NavLink)(({
  theme, color,
}) => {
  const { palette } = theme
  return css`
    color:${color === 'main' ? palette.dark.main : palette.product.marketing.main};
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;

    &:visited{
      color:${palette.dark.main};
    }

    img{
      margin: 0 0.4rem;
    }

    :last-child{
      img{
        display: none;
      }
    }
  `
})

export const Navlink = ({
  children, to, color,
}: INavlinkProps) :JSX.Element => (
  <StyledNavLink
    activeStyle={{
      fontWeight: 'bold',
    }}
    color={color}
    to={to}
  >
    {children}
    <img
      alt='arrow'
      src='assets/icons/breadcrumbsArrow.svg'
    />
  </StyledNavLink>

)
