/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react'
import {
  TileFormWrapper,
  TileFormSpan,
  TileFormSection,
  TileFormSectionTitle,
  TileFormRow,
  TileFormColumn,
  DoughnutChart,
  TileFormRowWithData,
  TitleFormSectionSubTitle,
  MapTile,
  LinkWithIconCheckOnMap,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { AddMapView, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { tilesData } from 'apps/placeme/src/features/Analyse/utils/tilesData'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { subDays } from 'date-fns'
import { ITrafficVisualisationTileData } from './@types/ITrafficVisualisationTileData'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { RangeAndDateTile } from '../../../../molecules'
import { SettingsBox } from '../../../../molecules/SettingsBox'

const StyledTileFormWrapper = styled(TileFormWrapper)<{background: 'light' | 'dark'}>(({
  theme, background,
}) => {
  const { palette } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.light.main};
  `
})

interface IExtendedFeatureCollection extends IFeatureCollection {
  data:{
    features:{
      type: string;
      geometry: {
        coordinates: number[];
        type: string;
      };
      properties: {
        title?: string | HTMLElement,
        pinnedItem?: {
          class: string;
          html: string;
        };
        id?: string,
        style:{
          color: string,
          fillColor:string,
          weight:number,
        }

      };
    }[];
  }}

export const TrafficVisualisationTile: React.FC<{
  data: ITrafficVisualisationTileData, tileId: string, userId: string, isExtraPaid?: boolean, isPdf?:boolean}> = ({
  data, tileId, userId, isExtraPaid, isPdf,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobileDevice = useCheckForMobileDevice()
  const [mapVisible, setMapVisible] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const { values } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const [accepted, setAccepted] = useState<boolean>(false)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const handleAddMap = () => {
    setMapVisible(true)
  }

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  useEffect(() => {
    if (!data) {
      setAccepted(false)
    }
    if (data?.value) setAccepted(true)
  }, [data])

  const getCatchmentId = values?.find(c => c.id === 'visualization')?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const getLayers = () => {
    if (data?.value) {
      const features : IExtendedFeatureCollection['data']['features'] = []

      data?.value?.map?.features?.forEach(feature => {
        features.push({
          geometry: feature?.geometry,
          properties: {
            ...feature?.properties,
          },
          type: 'Feature',
        }) })

      return [{
        id: `traffic_layer_${getCatchmentId}`,
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'traffic_layer_map',
          },
        },
      }]
    }
    return undefined
  }

  return (
    <StyledTileFormWrapper background={accepted ? 'light' : 'dark'}>

      {((!accepted || (!accepted && data && !data?.loading) || !data) && !isPdf)
        ? (
          <RangeAndDateTile
            accepted={accepted}
            category='traffic'
            isExtraPaid={isExtraPaid}
            maxDate={subDays(new Date(), 3)}
            maxRanges={tilesData.find(cat => cat.id === 'traffic')?.tiles.find(tile => tile.id === tileId.split('-')[0])?.maxRanges}
            minDate={new Date(2021, 10, 15)}
            setAccepted={setAccepted}
            tile={tileId}
            userId={userId}
          />
        )
        : (!data || data?.loading
          ? <Loader />
          : (
            <>
              <SettingsBox
                isPdf={isPdf}
                noEdit
                sectionTile='traffic'
                setAccepted={setAccepted}
                tile={tileId}
                typeRanges={{
                  date: {
                    startDate: data?.value?.dataRanges?.startDate,
                    endDate: data?.value?.dataRanges?.endDate,
                  },
                }}
              />
              <TileFormSection>
                <TileFormSectionTitle>{t('placeme.traffic_visualisation_tile.section_title_1')}</TileFormSectionTitle>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_1_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.dailyAverageUniqueTraffic)}</span>
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_1')}
                </TileFormSpan>
                <TitleFormSectionSubTitle>{t('placeme.traffic_visualisation_tile.section_sub_title_1')}</TitleFormSectionSubTitle>
                <TileFormRow
                  width='100%'
                  wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
                >
                  <TileFormColumn
                    align='start'
                    width={!isMobileDevice ? '60%' : '100%'}
                  >
                    <TileFormRowWithData legend='#362FB7'>
                      <span>{t('placeme.traffic_visualisation_tile.row_with_data_2_span_1')}</span>
                      <span>{`${parseFloat((data?.value?.visitorsStructure?.stayingInTheArea * 100).toFixed(1))}%`}</span>
                    </TileFormRowWithData>
                    <TileFormSpan>
                      {t('placeme.traffic_visualisation_tile.form_span_2')}
                    </TileFormSpan>
                    <TileFormRowWithData legend='#7E7AD2'>
                      <span>{t('placeme.traffic_visualisation_tile.row_with_data_3_span_1')}</span>
                      <span>{`${parseFloat((data?.value?.visitorsStructure?.passingByTheArea * 100).toFixed(1))}%`}</span>
                    </TileFormRowWithData>
                    <TileFormSpan>
                      {t('placeme.traffic_visualisation_tile.form_span_3')}
                    </TileFormSpan>
                  </TileFormColumn>
                  <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                    {isPdf
                      ? (
                        <img
                          alt='chart'
                          src={`data:image/svg+xml;base64,{{ chart1_${tileId.replace(/-/g, '_')
                          } }}`}
                        />
                      )
                      : (
                        <DoughnutChart
                          data={[data?.value?.visitorsStructure?.stayingInTheArea * 100,
                            data?.value?.visitorsStructure?.passingByTheArea * 100]
                            .map(number => parseFloat(number.toFixed(1)))}
                          innerLabels
                          labels={[t('placeme.traffic_visualisation_tile.doughnut_chart_1_label_1'),
                            t('placeme.traffic_visualisation_tile.doughnut_chart_1_label_2')]}
                          valueType='percentages'
                          width={230}
                        />
                      )}
                  </TileFormColumn>
                </TileFormRow>
              </TileFormSection>
              <TileFormSection>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_4_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.averageTrafficPerWeek)}</span>
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_4')}
                </TileFormSpan>
                <TileFormRowWithData>
                  <span>{t('placeme.traffic_visualisation_tile.row_with_data_5_span_1')}</span>
                  <span>{formatNumber(data?.value?.dailyAverage?.averageTrafficWeekend)}</span>
                </TileFormRowWithData>
                <TileFormSpan>
                  {t('placeme.traffic_visualisation_tile.form_span_5')}
                </TileFormSpan>
              </TileFormSection>
              {data?.value?.transportModeStructure && (
                <TileFormSection>
                  <TitleFormSectionSubTitle>{t('placeme.traffic_visualisation_tile.section_sub_title_2')}</TitleFormSectionSubTitle>
                  <TileFormRow
                    width='100%'
                    wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
                  >
                    <TileFormColumn
                      align='start'
                      width={!isMobileDevice ? '60%' : '100%'}
                    >
                      <TileFormRowWithData legend='#362FB7'>
                        <span>{t('placeme.traffic_visualisation_tile.row_with_data_6_span_1')}</span>
                        <span>{`${parseFloat((data?.value?.transportModeStructure?.onFoot * 100).toFixed(1))}%`}</span>
                      </TileFormRowWithData>
                      <TileFormSpan>
                        {t('placeme.traffic_visualisation_tile.form_span_6')}
                      </TileFormSpan>
                      <TileFormRowWithData legend='#7E7AD2'>
                        <span>{t('placeme.traffic_visualisation_tile.row_with_data_7_span_1')}</span>
                        <span>{`${parseFloat((data?.value?.transportModeStructure?.byCar * 100).toFixed(1))}%`}</span>
                      </TileFormRowWithData>
                      <TileFormSpan>
                        {t('placeme.traffic_visualisation_tile.form_span_7')}
                      </TileFormSpan>
                    </TileFormColumn>
                    <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                      {isPdf
                        ? (
                          <img
                            alt='chart'
                            src={`data:image/svg+xml;base64,{{ chart2_${tileId.replace(/-/g, '_')
                            } }}`}
                          />
                        )
                        : (
                          <DoughnutChart
                            data={[data?.value?.transportModeStructure?.onFoot * 100,
                              data?.value?.transportModeStructure?.byCar * 100]
                              .map(number => parseFloat(number.toFixed(1)))}
                            innerLabels
                            labels={[t('placeme.traffic_visualisation_tile.doughnut_chart_2_label_1'),
                              t('placeme.traffic_visualisation_tile.doughnut_chart_2_label_2')]}
                            valueType='percentages'
                            width={230}
                          />
                        )}
                    </TileFormColumn>
                  </TileFormRow>
                  <TileFormRowWithData>
                    <span>{t('placeme.traffic_visualisation_tile.row_with_data_8_span_1')}</span>
                    <span>{formatNumber(data?.value?.transportModeStructure?.averageNumberOfCarPassingByTheArea)}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.traffic_visualisation_tile.form_span_8')}
                  </TileFormSpan>
                </TileFormSection>
              )}
              <TileFormSection>
                {(!mapVisible && !isPdf) && (
                  <AddMapView
                    buttonValue={1}
                    description='placeme.add_traffic_visualisation_map.description'
                    onChange={handleAddMap}
                    title='placeme.add_traffic_visualisation_map.title'
                  />
                )}
                {mapVisible && (
                  <>
                    <TitleFormSectionSubTitle>
                      <span>{t('placeme.traffic_visualisation_tile.section_sub_title_2_span_1')}</span>
                      <LinkWithIconCheckOnMap onClick={handleMapOpen} />
                    </TitleFormSectionSubTitle>
                    <MapTile
                      dragging
                      height='300px'
                      layers={getLayers()}
                      location={value}
                      mapId='example-map-data-tile'
                      pinDisplayed
                      popupHeading={`${t('generic.chosen_location')}:`}
                      popupParagraph={value?.address}
                      width='100%'
                      zoom={15}
                      zoomControl
                    />
                  </>
                )}

                {isMapDisplayed && !isPdf && (
                  <MapVisualizationModal
                    isDisplayed={isMapDisplayed}
                    layers={getLayers()}
                    location={value}
                    mapId={`traffic-visualisation-tile-${values?.find(c => c.id === 'traffic')?.tiles?.find(t => t.id === 'traffic_visualization')?.chosenRange?.catchmentId}`}
                    setIsDisplay={setIsMapDisplayed}
                    zoom={15}
                  />
                )}
                {isPdf && (
                  <img
                    alt='map'
                    src={`data:image/png;base64,{{ traffic_map_${tileId.replace(/-/g, '_')
                    } }}`}
                    style={{
                      width: '80%',
                      margin: '50px auto 0 auto',
                      borderRadius: '8px',
                    }}
                  />
                )}
              </TileFormSection>
            </>
          ))}

    </StyledTileFormWrapper>
  )
}

export default TrafficVisualisationTile
