
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AddMapView, MapVisualizationModal, NestedSelect } from '@dataplace.ai/ui-components/molecules'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  MapTile,
  IMapLocationProps,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { Trans, useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { buildingsTableData1, buildingsTableData2 } from './data'
import { RootState } from '../../../../../../../redux/store'
import { IBuildingsFunctions, IBuildingsTileData, IBuildingsTypes } from './@types/IBuildingsTileData'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

const MapWrapper = styled.div(
  () => css`
    display: flex;
    margin: 1rem 0;
    width: 100%;
    height: 336px;
  `,
)

const MainLabel = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color: ${palette.black};
  font-size: ${typography.main.pt_15_semibold.fontSize};
  font-weight: ${typography.main.pt_15_semibold.fontWeight};
  line-height: ${typography.main.pt_15_semibold.lineHeight};
`
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)
const StyledSpan = styled.span`
  :nth-child(5n+1){
    justify-content: flex-start !important;
    text-align: start;
  }
`

const TableWrapper = styled.div`
  margin: 30px 0;
`

const LegendWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-right: 10px;
   >div{
     display: flex;
     align-items: center;
     
     >p{
       color: ${palette.black};
       font-size: 9px;
        white-space: nowrap;
     }
   }
 `
})

const LegendColor = styled.span<{color: string}>(({ color }) => css`
      background-color: ${color};
      width: 10px;
      height:10px;
      border-radius: 2px;
      margin-right: 5px;
    `)

const categories: ResourceWithId[] = [
  {
    id: 'types',
    content: <Trans>placeme.buildings_category.types</Trans>,
  },
  {
    id: 'functions',
    content: <Trans>placeme.buildings_category.functions</Trans>,
  },
]

export const BuildingsTile: React.FC<{data: IBuildingsTileData, isPdf?: boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [val, setValue] = useState<ResourceWithId[]>(categories)
  const { labels: labels1 } = buildingsTableData1
  const { labels: labels2 } = buildingsTableData2
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 15,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })

  // hooks
  useEffect(() => {
    if (data?.value && mapLocation) {
      dispatch(saveTileData('surroundings', tileId, {
        ...data,
        mapLocation,
      }))
    }
  }, [mapLocation])

  // functions

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleAddMap = () => {
    setMapVisible(true)
  }

  const typesLabels = () => {
    const newArray = labels1.map((label, index) => {
      if (index === 0) return <MainLabel key={label}>{t(label)}</MainLabel>
      return <span key={label}>{t(label)}</span>
    })
    newArray.push(<span />)
    return newArray
  }

  const functionsLabels = () => {
    const newArray = labels2.map((label, index) => {
      if (index === 0) return <MainLabel key={label}>{t(label)}</MainLabel>
      return <span key={label}>{t(label)}</span>
    })
    newArray.push(<span />)
    return newArray
  }

  const functionsMainContent = () => {
    if (data && data.value) {
      const functions = data.value.functions as unknown as IBuildingsTypes[]
      return functions.map((item) => {
        const values = item.values.slice(0, 3).map((val) => (
          <span
            key={val}
            style={{
              textAlign: 'right',
            }}
          >
            {val}
          </span>
        ))
        values.unshift(
          <MainLabel
            key={item.label}

          >
            {t(`placeme.buildings.functions.${item.label.toLocaleLowerCase().split(/[_ -]+/)
              .join('_')}`)}
          </MainLabel>,
        )
        return values
      })
    }
    return []
  }

  const typesMainContent = () => {
    if (data && data.value) {
      const types = data.value.types as unknown as IBuildingsTypes[]
      return types.map((item) => {
        const values = item.values.slice(0, 3).map((val) => (
          <span
            key={val}
            style={{
              textAlign: 'right',
            }}
          >
            {val}
          </span>
        ))
        values.unshift(
          <MainLabel
            key={item.label}
          >
            {t(`placeme.buildings.types.${item.label.toLocaleLowerCase().split(/[_ -]+/)
              .join('_')}`)}
          </MainLabel>,
        )
        return values
      })
    }
    return []
  }

  const typesSubContent = () => {
    if (data && data.value) {
      const types = data.value.types as unknown as IBuildingsTypes[]
      const returnArray: JSX.Element[][][] = []
      types.forEach(item => {
        const array = item.subtypes.map(subitem => {
          const values = subitem.values.slice(0, 3).map(val => (
            <StyledSpan
              key={val}
              style={{
                textAlign: 'right',
              }}
            >
              {val}
            </StyledSpan>
          ))
          values.unshift(
            <StyledSpan
              key={item.label}
            >
              {t(`placeme.buildings.subtypes.${subitem.label.toLocaleLowerCase().split(/[_ -]+/)
                .join('_')}`)}
            </StyledSpan>,
          )
          values.push(<StyledSpan key={uuidv4()}> </StyledSpan>)
          return values
        })
        returnArray.push(array)
      })
      return returnArray
    }
    return []
  }
  const functionsSubContent = () => {
    if (data && data.value) {
      const functions = data?.value?.functions as unknown as IBuildingsFunctions[]
      const returnArray: JSX.Element[][][] = []
      functions.forEach(item => {
        const array = item.subfunctions.map(subitem => {
          const values = subitem.values.slice(0, 3).map(val => (
            <StyledSpan
              key={val}
              style={{
                textAlign: 'right',
              }}
            >
              {val}
            </StyledSpan>
          ))
          values.unshift(
            <StyledSpan
              key={item.label}
            >
              {t(`placeme.buildings.subfunctions.${subitem.label.toLocaleLowerCase().split(/[_ -]+/)
                .join('_')}`)}
            </StyledSpan>,
          )
          values.push(<StyledSpan key={uuidv4()}> </StyledSpan>)
          return values
        })
        returnArray.push(array)
      })
      return returnArray
    }
    return []
  }
  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        {!isPdf
        && (
          <NestedSelectWrapper>
            <span>{t('placeme.buildings_tile.categories')}</span>
            <NestedSelect
              name=''
              onChange={setValue}
              options={categories}
              selected={val}
              width='50%'
            />
          </NestedSelectWrapper>
        ) }
        {val.map(v => v.id).includes('types')
        && !isPdf && (
          <TableWrapper>
            <Table
              buildings
              content={typesMainContent()}
              expandable={typesSubContent()}
              expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              gap='1rem'
              headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              headerTextAlign='right'
              labels={typesLabels()}
              rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
            />
          </TableWrapper>
        )}
        {isPdf
          && (Object.entries(data.value.types).map(item => (
            <div key={item[1].label}>
              {`{{table1_${item[1].label.replace(/-|\s/g, '_')}_${tileId?.replace(/-/g, '_')} | safe}}`}
            </div>
          )))}
        {val.map(v => v.id).includes('functions')
        && !isPdf && (
          <TableWrapper>
            <Table
              buildings
              content={functionsMainContent()}
              expandable={functionsSubContent()}
              expandableRowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              gap='1rem'
              headerTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
              headerTextAlign='right'
              labels={functionsLabels()}
              rowTemplate='3fr 1.2fr 1.2fr 1.2fr 0.2fr'
            />
          </TableWrapper>
        )}
        {isPdf
          && (Object.entries(data.value.functions).map(item => (
            <div key={item[1].label}>
              {`{{table2_${item[1].label.replace(/-|\s/g, '_')}_${tileId?.replace(/-/g, '_')} | safe}}`}
            </div>
          )))}
        {(!mapVisible && canBeSave && !isPdf) && (
          <AddMapView
            buttonValue={0}
            description='placeme.add_buildings_map.description'
            onChange={handleAddMap}
            title='placeme.add_buildings_map.title'
          />
        )}
        {((val.length > 0 && (mapVisible || (!canBeSave && data?.value))) || isPdf) && (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.buildings_tile.points_on_map')}</span>
              {!isPdf && <LinkWithIconCheckOnMap onClick={handleMapOpen} />}
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              {data?.value?.legend?.length
              && (
                <>
                  <LegendWrapper>
                    {data?.value?.legend?.map((item) => (
                      <div key={uuidv4()}>
                        <LegendColor color={item?.color} />
                        <p>{item?.label}</p>
                      </div>
                    ))}
                  </LegendWrapper>
                  {isPdf
                  && (
                    <img
                      alt='map'
                      src={`data:image/png;base64,{{ buildings_map_${tileId.replace(/-/g, '_')
                      } }}`}
                      style={{
                        width: '70%',
                        marginLeft: '50px',
                        borderRadius: '8px',
                      }}
                    />
                  ) }
                </>
              )}
              {!isPdf
              && (
                <MapTile
                  dragging
                  height='100%'
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={`${t('generic.chosen_location')}:`}
                  popupParagraph={value?.address}
                  setMapLocation={setMapLocation}
                  styleLayer={{
                    id: data?.value?.buildingsMap?.id,
                    styleId: data?.value?.buildingsMap?.styleId,
                    user: data?.value?.buildingsMap?.user,
                  }}
                  width='100%'
                  zoom={16}
                  zoomControl
                />
              ) }
            </MapWrapper>
          </>
        ) }
        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            legend={data?.value?.legend?.length
              ? (
                <LegendWrapper>
                  {data?.value?.legend?.map((item) => (
                    <div key={uuidv4()}>
                      <LegendColor color={item?.color} />
                      <p>{item?.label}</p>
                    </div>
                  ))}
                </LegendWrapper>
              )
              : undefined}
            location={value}
            mapId={`buildings-tile-${values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === 'buildings')?.chosenRange?.catchmentId}`}
            setIsDisplay={setIsMapDisplayed}
            styleLayer={{
              id: data?.value?.buildingsMap?.id,
              styleId: data?.value?.buildingsMap?.styleId,
              user: data?.value?.buildingsMap?.user,
            }}
            zoom={17}
          />
        )}

      </Wrapper>
    )
}
