import React, { useState, useEffect, useMemo, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { MapVisualizationModal, WarningModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { CoinsButton, PopupWithOverlay, TextButton } from '@dataplace.ai/ui-components/atoms'
import { IGeojson } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IGeojson'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { bigBenCategoryId } from '@dataplace.ai/constants'
import { TileHeader } from '../TileHeader'
import { ITile } from '../../../slice/@types/ITile'
import { RangeSelection } from '../RangeSelection'
import { ReactComponent as Info } from '../../../../../../../../libs/shared/assets/src/lib/icons/largeIcons/info.svg'
import { RootState } from '../../../../../redux/store'
import {
  deleteTileAction,
  fetchTileDataAction,
  saveChosenRangeAction,
  saveNewRangeAction,
  saveTileData,
} from '../../../slice/analysisSlice'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { TileNotes } from '../TileNotes'
import { tilesWithDoublePropertiesChoose, tilesWithoutRangeChoosing } from './constant/TilesWithDoublePropertiesChoose'
import { IPdfData } from '../../../slice/@types/IPdfData'

const Wrapper = styled.div<{fullWidth: boolean, isPdf?: boolean}>(({
  theme, fullWidth, isPdf,
}) => {
  const {
    palette, shadows, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    box-shadow: ${shadows.tiny.boxShadow};
    border-left: 0.25rem solid ${palette.product.location.main};
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.main};
    width: ${fullWidth || isPdf ? '200%' : '74%'};
    height: min-content;
  `
})

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding: 1.25rem;
  box-sizing: border-box;
`

const InfoWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin-bottom: -1.25rem;
    padding: 1.25rem 1.25rem 0 1.25rem;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > svg {
      margin-right: 1rem;
    }
  `
})

export interface ITileProps {
  tile: ITile
  sectionTile: ISectionTile
  userId: string,
  isPdf?: boolean,
  staticImage?: string
  locationPDF?: {
    address: string,
    lat: number,
    lng: number,
  }
}

export const Tile = ({
  tile,
  sectionTile,
  userId,
  isPdf,
  staticImage,
  locationPDF,
}: ITileProps): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    values, creditsAmount, showHints, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)

  const {
    component: Component, canChooseRange, haveToAccept, maxRanges,
  } = tile

  // pdf
  const dataPdfToJSON = JSON.parse(localStorage?.getItem('pdfData') || '[]') as IPdfData[]

  // states
  const [isModalOpen, toggle] = useState(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')

  // functions
  const fetchChosenRange = (category: string, thisTile: string) => {
    if (!isPdf) {
      return (
        values?.find((value) =>
          value.id === category)?.tiles?.find((tile) => tile.id === thisTile)?.chosenRange
      )
    }
    return (
      dataPdfToJSON[0]?.data?.find((value) =>
        value.id === category)?.tiles?.find((tile) => tile.id === thisTile)?.chosenRange
    )
  }

  const [chosenRange, setChosenRange] = useState(
    fetchChosenRange(sectionTile.id, tile.id),
  )

  const handleModal = () => {
    toggle(!isModalOpen)
  }

  const handleDeleteRange = () => {
    setAccepted(false)
    dispatch(saveChosenRangeAction(sectionTile.id, tile.id, undefined))
    dispatch(saveTileData(sectionTile.id, tile.id, undefined))
    setChosenRange(undefined)
    toggle(!isModalOpen)
  }

  const handleDeleteTile = () => dispatch(deleteTileAction(sectionTile.id, tile.id))

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleSave = () => {
    if (creditsAmount) {
      setAccepted(true)
    }
  }

  const data = useMemo(() => {
    if (isPdf) {
      // pdf
      return dataPdfToJSON[0]?.data?.find(c => c.id === sectionTile.id)?.tiles?.find(t => t.id === tile.id)?.data
    }
    return values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.data
  },
  [JSON.stringify(values), dataPdfToJSON])

  const tileId = useMemo(() => {
    if (isPdf) {
      // pdf
      return dataPdfToJSON[0]?.data?.find(c => c.id === sectionTile.id)?.tiles?.find(t => t.id === tile.id)?.id
    }
    return values?.find(c => c.id === sectionTile.id)?.tiles.find(t => t.id === tile.id)?.id
  },
  [JSON.stringify(values), dataPdfToJSON])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (
      chosenRange
      && canBeSave
      && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
      && token.length
      && !data
      && sectionTile?.id !== bigBenCategoryId
    ) {
      dispatch(fetchTileDataAction(
        token,
        sectionTile.id,
        tile.id,
        currentSubscriptionData?.value?.subscriptionId || null,
      ))
    }
  }, [chosenRange, canBeSave, token, currentSubscriptionData?.value?.subscriptionId])

  useEffect(() => {
    setChosenRange(fetchChosenRange(sectionTile.id, tile.id))
  }, [values])

  // handle add catchment id for tiles without customer range choosing
  // you need to add tile id to tilesWithoutRangeChoosing
  // turn canChooseRange false in tilesData
  useEffect(() => {
    if (token && !tile?.chosenRange?.catchmentId && tilesWithoutRangeChoosing.includes(tile?.id.split('-')[0]) && (haveToAccept ? accepted : true)) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', sectionTile?.id, tile?.id, {
        id: `${tileId}-250-line`,
        value: 250,
        type: 'line',
      })) }
  }, [token, accepted])

  return (
    <Wrapper
      fullWidth={!showHints}
      isPdf={isPdf}
    >
      <PopupWithOverlay
        onClose={() => toggle(false)}
        open={isModalOpen}
      >
        <WarningModal
          agreementButtonText={t('generic.delete')}
          cancelButtonText={t('generic.cancel')}
          description={(
            <>
              {t('tile.delete.warning')}
              <br />
              {`${t('tile.delete.save_report')}.`}
            </>
          )}
          handleAgreement={handleDeleteRange}
          handleCancel={handleModal}
          heading={t('tile.delete.heading')}
          redWarning={t('tile.delete.red_warning')}
        />
      </PopupWithOverlay>
      <TileHeader
        dataLoaded={!!data?.value}
        handleMap={handleMapOpen}
        isPdf={isPdf}
        range={chosenRange}
        sectionTile={sectionTile}
        tile={tile}
        toggleChangeRangeModal={handleModal}
      />
      {!isPdf
      && (
        <TileNotes
          dataLoaded={!!data?.value}
          notes={tile.notes}
          sectionTile={sectionTile}
          tile={tile}
          token={token}
        />
      ) }

      {!chosenRange
        && canChooseRange
        && !data?.value
        && !tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0])
        && !isPdf
        && (
          <RangeSelection
            category={sectionTile.id}
            clientRanges={sectionTile?.id === bigBenCategoryId}
            isExtraPaid={tile.isExtraPaid}
            maxRanges={maxRanges}
            tile={tile.id}
            userId={userId}
          />
        )}

      {haveToAccept && !accepted && !isPdf && tile.id.split('-')[0] !== 'look_alike_big_ben' && !data?.value && (

        <>
          {tile.acceptDescription && (
            <InfoWrapper>
              <Info
                height={40}
                width={40}
              />
              <span>{t(tile.acceptDescription)}</span>
            </InfoWrapper>
          )}
          <ButtonsWrapper>
            <TextButton onClick={handleDeleteTile}>
              <p>{t('generic.cancel')}</p>
            </TextButton>
            <CoinsButton
              isExtraPaid={tile.isExtraPaid}
              isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
              margin='0 0 0 1.82rem'
              onClick={handleSave}
              text={tile.isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
              tileId={tile?.id ?? ''}
              value={1}
            />
          </ButtonsWrapper>
        </>
      )}

      {haveToAccept && !accepted && !isPdf && tile.id.split('-')[0] === 'look_alike_big_ben' && !data?.value && (
        <>
          { tile.acceptDescription && (
            <InfoWrapper>
              <Info
                height={40}
                width={40}
              />
              <span>{t(tile.acceptDescription)}</span>
            </InfoWrapper>
          )}
          <ButtonsWrapper>
            <TextButton onClick={handleDeleteTile}>
              <p>{t('generic.cancel')}</p>
            </TextButton>
            <CoinsButton
              isExtraPaid={tile.isExtraPaid}
              isUnlimited
              margin='0 0 0 1.82rem'
              onClick={handleSave}
              text={tile.isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
              tileId={tile?.id ?? ''}
              value={1}
            />
          </ButtonsWrapper>
        </>
      )}

      {isMapDisplayed && (
        <MapVisualizationModal
          isDisplayed={isMapDisplayed}
          layers={(chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates)
            ? [{
              id: chosenRange?.catchmentId || tile?.chosenRange?.catchmentId || '',
              layer: {
                data: {
                  coordinates: (chosenRange?.geoJSON?.coordinates || tile?.chosenRange?.geoJSON?.coordinates) as IGeojson['data']['coordinates'],
                  type: 'Polygon',
                  properties: {},
                },
                options:{
                  type: 'geojson',
                  id: 'some_overlay_id',
                  style: {
                    color: '#0000a2',
                    fillColor: '#0000a2',
                    weight: 0,
                    fillOpacity: 0.3,
                  },
                },
              },
            }]
            : undefined}
          location={value}
          mapId={`range-map-${chosenRange?.catchmentId || tile?.chosenRange?.catchmentId}`}
          setIsDisplay={setIsMapDisplayed}
        />
      )}

      {/* explanation of display conditions */}
      {/* 1. tiles in which we have to choose a range */}
      {/* 2. tiles where we cannot select a range but do not need to confirm (select something else) */}
      {/* 3. tiles where we have to confirm */}
      {((chosenRange || (!canChooseRange && !haveToAccept) || (haveToAccept && accepted)) || data?.value || tilesWithDoublePropertiesChoose.includes(tile.id.split('-')[0]) || isPdf)
        && (typeof Component === 'function'
          ? (
            <Component
              accepted={accepted}
              catchmentId={chosenRange?.catchmentId}
              data={data}
              isExtraPaid={tile.isExtraPaid}
              isPdf={isPdf}
              locationPDF={locationPDF}
              maxRanges={maxRanges}
              setAccepted={setAccepted}
              staticImage={staticImage}
              tileId={tileId}
              userId={userId}
            />
          )
          : (
            Component
          ))}
    </Wrapper>
  )
}
