/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import styled, { css } from 'styled-components'
import { Comparison, Tooltip } from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { ILocation } from '@dataplace.ai/types'
import { spendingTableData } from './data'
import { ISpendingRow, ISpendingTileData } from './@types/ISpendingTileData'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const CategorySpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_semibold.fontSize};
    font-weight: ${typography.small.pt_13_semibold.fontWeight};
    line-height: ${typography.small.pt_13_semibold.lineHeight};

  `
})

const RegularSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    text-align: right;
  `
})

const PercentageDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const SpendingTile: React.FC<{data: ISpendingTileData, isPdf?:boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const isMobileDevice = useCheckForMobileDevice()
  const { labels } = spendingTableData
  const { value } = useSelector((state: RootState) => state.location)
  const locationPDF = JSON.parse(localStorage?.getItem('location') || '{}') as ILocation

  const country = value?.country

  const tooltipContent = (value: number): JSX.Element => (
    <>
      <span>
        {t('placeme.spending_tile.tooltip.first')}
        {' '}
        {value < 0 ? t('placeme.spending_tile.tooltip.less') : t('placeme.spending_tile.tooltip.more')}
        {` ${value.toFixed(1)}% `}
        {(country === 'PL' || locationPDF.country === 'PL') ? t('placeme.spending_tile.tooltip.last') : t('placeme.spending_tile.tooltip.last.foreign')}
      </span>
    </>
  )
  const currency = data?.value?.currency

  const content = Array.isArray(data?.value?.spending)
    ? data?.value?.spending?.map((value: ISpendingRow) => (
      [
        <CategorySpan key={value.categoryName}>{t(`spending.category.${value.categoryName.split(' ').join('_')}`)}</CategorySpan>,
        <RegularSpan key={value.perCapita}>{formatNumber(value.perCapita)}</RegularSpan>,
        <RegularSpan key={value.catchmentSum}>{formatNumber(value.catchmentSum)}</RegularSpan>,
        <PercentageDiv key={value.percentForTheCity}>
          <Tooltip
            content={tooltipContent(value.percentForTheCity)}
            maxWidth='250px'
            position='left bottom'
          >
            <Comparison
              blackArrow
              state={value.percentForTheCity < 0 ? 'negative' : 'positive'}
              value={`${value.percentForTheCity.toFixed(1)}%`}
              withIcon={false}
            />
          </Tooltip>
        </PercentageDiv>,
      ]
    ))
    : []

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        {isPdf
          ? (
            <div>
              {`{{table_${tileId.replace(/-/g, '_')} | safe}}`}
            </div>
          )
          : (
            <Table
              content={content}
              gap={!isMobileDevice ? '1rem' : '0.5rem'}
              headerTemplate={!isMobileDevice ? '3fr 2fr 2fr 1fr' : '2fr 2fr 2fr 2fr'}
              labels={labels.map(label => (label === 'placeme.spending_tile.table_label_2' || label === 'placeme.spending_tile.table_label_3'
                ? <span key={label}>{`${t(label)}[${currency || 'PLN'}]`}</span>
                : <span key={label}>{t(label)}</span>))}
              rowTemplate={!isMobileDevice ? '3fr 2fr 2fr 1fr' : '2fr 2fr 2fr 2fr'}
            />
          )}
      </Wrapper>
    )
}
