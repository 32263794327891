/* eslint-disable react/require-default-props */
import React from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 0.375rem;
`
interface IStyle {
  color?: string
}
const NamePart = styled.h3<IStyle>(({
  theme, color,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${color || palette.dark.main};
    font-size: ${typography.big.pt_22_semibold.fontSize};
    font-weight: ${typography.big.pt_22_semibold.fontWeight};
    line-height: ${typography.big.pt_22_semibold.lineHeight};
  `
})

const DomainPart = styled.h3<IStyle>(({
  theme, color,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${color || palette.dark.main};
    font-size: ${typography.big.pt_22_semibold.fontSize};
    font-weight: 400;
    line-height: ${typography.big.pt_22_semibold.lineHeight};
  `
})

export const TextLogo = ({ color }:{color?: string}) : JSX.Element => {
  const history = useHistory()

  return (
    <Wrapper
      onClick={() => history.push('/')}
    >
      <NamePart color={color}>dataplace</NamePart>
      <DomainPart color={color}>.ai</DomainPart>
    </Wrapper>

  )
}
