
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  DoughnutChart,
  TileFormRow,
  TileFormSpan,
  LinkWithIconCheckOnMap,
  TileFormSectionTitle,
  TileFormColumn,
  TileFormSection,
  TitleFormSectionSubTitle,
  TileFormWrapper,
  TileFormRowWithData,
  MapTile,
  GaugeChart,
} from '@dataplace.ai/ui-components/atoms'
import { AddMapView, MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@dataplace.ai/functions/utils'

import { useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { ILocation } from '@dataplace.ai/types'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { IPeopleTileData } from './@types/IPeopleTileData'

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

export const PeopleTile: React.FC<{
  data: IPeopleTileData,
  isPdf?:boolean, tileId: string}> = ({
  data,
  isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isMobileDevice = useCheckForMobileDevice()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const { canBeSave } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  const locationPDF = JSON.parse(localStorage?.getItem('location') || '{}') as ILocation

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const handleAddMap = () => {
    setMapVisible(true)
  }

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        { (value?.country === 'PL' || locationPDF.country === 'PL') && (
          <>
            {' '}
            <TileFormSection>
              <TileFormSectionTitle>{t('placeme.people_tile.section_title_1')}</TileFormSectionTitle>
              <TileFormRowWithData>
                <span>{t('placeme.people_tile.row_with_data_1_span_1')}</span>
                <span>{`${formatNumber(data?.value?.dailyAverage)}`}</span>
                {/* <Comparison */}
                {/*  state='negative' */}
                {/*  value='-20%' */}
                {/*  withIcon */}
                {/* /> */}
              </TileFormRowWithData>
              <TileFormSpan>
                {t('placeme.people_tile.form_span_1')}
              </TileFormSpan>
              <TitleFormSectionSubTitle>{t('placeme.people_tile.section_sub_title_1')}</TitleFormSectionSubTitle>
              <TileFormRow
                width='100%'
                wrap={!isMobileDevice ? 'nowrap' : 'wrap'}
              >
                <TileFormColumn
                  align='start'
                  width={!isMobileDevice ? '60%' : '100%'}
                >
                  <TileFormRowWithData legend='#362FB7'>
                    <span>{t('placeme.people_tile.row_with_data_2_span_1')}</span>
                    <span>{`${parseFloat((data?.value?.structure?.visitors * 100).toFixed(1))}%`}</span>
                    {/* <Comparison */}
                    {/*  state='positive' */}
                    {/*  value='+10%' */}
                    {/*  withIcon */}
                    {/* /> */}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.people_tile.form_span_2')}
                  </TileFormSpan>
                  <TileFormRowWithData legend='#7E7AD2'>
                    <span>{t('placeme.people_tile.row_with_data_3_span_1')}</span>
                    <span>{`${parseFloat((data?.value?.structure?.inhabitants * 100).toFixed(1))}%`}</span>
                    {/* <Comparison */}
                    {/*  state='negative' */}
                    {/*  value='-20%' */}
                    {/*  withIcon */}
                    {/* /> */}
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.people_tile.form_span_3')}
                  </TileFormSpan>
                </TileFormColumn>
                <TileFormColumn width={!isMobileDevice ? '40%' : '100%'}>
                  {isPdf
                    ? (
                      <img
                        alt='chart'
                        src={`data:image/svg+xml;base64,{{ ${tileId.replace(/-/g, '_')
                        } }}`}
                      />
                    )
                    : (
                      <DoughnutChart
                        data={[data?.value?.structure?.visitors * 100, data?.value?.structure?.inhabitants * 100]
                          .map(number => parseFloat(number.toFixed(1)))}
                        innerLabels
                        labels={[t('placeme.people_tile.chart_label_1'), t('placeme.people_tile.chart_label_2')]}
                        valueType='percentages'
                        width={230}
                      />
                    )}
                </TileFormColumn>
              </TileFormRow>
            </TileFormSection>
          </>
        )}
        <TileFormSection>
          <TileFormSectionTitle>{t('placeme.people_tile.section_title_2')}</TileFormSectionTitle>
          <TileFormRowWithData>
            <span>{(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile.row_with_data_4_span_1') : t('placeme.people_tile.row_with_data_4_span_1.foreign')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.registeredResidentsValue)}`}</span>
          </TileFormRowWithData>
          <TileFormSpan>
            {t('placeme.people_tile.form_span_4')}
          </TileFormSpan>
          {/* <TileFormRowWithData>
            <span>{t('placeme.people_tile.row_with_data_8_span_1')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.residentsValue)}`}</span>
          </TileFormRowWithData>
          <TileFormSpan>
            {t('placeme.people_tile.form_span_8')}
          </TileFormSpan> */}
          <TileFormRowWithData>
            <span>{(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile.row_with_data_5_span_1') : t('placeme.people_tile.row_with_data_5_span_1.foreign')}</span>
            <span>{`${formatNumber(data?.value?.inhabitants?.householdValue)}`}</span>
            {/* <Comparison */}
            {/*  state='negative' */}
            {/*  value='-20%' */}
            {/*  withIcon */}
            {/* /> */}
          </TileFormRowWithData>
          <TileFormRowWithData>
            <span>{(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile.row_with_data_6_span_1') : t('placeme.people_tile.row_with_data_6_span_1.foreign')}</span>
            <span>{data?.value?.inhabitants?.averageHousehold}</span>
          </TileFormRowWithData>
        </TileFormSection>

        {!isPdf
          ? (
            <TileFormSection>
              <TileFormSectionTitle>
                <span>{t('placeme.people_tile.section_title_3_span_1')}</span>
              </TileFormSectionTitle>
              <TileFormRowWithData>
                <span>{t('placeme.people_tile.row_with_data_7_span_1')}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                <strong>{`${parseFloat((data?.value?.inhabitants?.densityValue * 100).toFixed(1))} % `}</strong>
                {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile_percentages.form_span_3_normal_1') : t('placeme.people_tile_percentages.form_span_3_normal_1.foreign')}
              </TileFormSpan>
              <GaugeChart
                // comparison='+10%'
                height={150}
                unit='%'
                value={data?.value?.inhabitants?.densityValue * 100}
              />
              <TileFormRow width='100%'>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='center'
                  width='50%'
                >
                  <TileFormSpan>
                    {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile.form_span_5') : t('placeme.people_tile.form_span_5.foreign')}
                  </TileFormSpan>
                </TileFormColumn>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='center'
                  width='50%'
                >
                  <TileFormSpan>
                    {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile.form_span_6') : t('placeme.people_tile.form_span_6.foreign')}
                  </TileFormSpan>
                </TileFormColumn>
              </TileFormRow>
              {
                (mapVisible || (!canBeSave && data?.value?.populationHeatmap)) && (
                  <>
                    <TitleFormSectionSubTitle>
                      <span>{t('placeme.people_tile.section_sub_title_2_span_1')}</span>
                      <LinkWithIconCheckOnMap onClick={handleMapOpen} />
                    </TitleFormSectionSubTitle>
                    <MapWrapper>
                      <MapTile
                        height='100%'
                        layers={data?.value?.populationHeatmap
                          ? [{
                            id: data?.value?.populationHeatmap?.id,
                            layer: {
                              ...data?.value?.populationHeatmap,
                              options: {
                                type: data?.value?.populationHeatmap?.type,
                                id: data?.value?.populationHeatmap?.id,
                                style: {
                                  ...data?.value?.populationHeatmap?.style,
                                  blur: 5,
                                  radius: 90,
                                  minOpacity: 0.07,
                                  gradient: {
                                    1:'#FF0000',
                                    '0.0':'#0099FF',
                                    0.3:'#FFFF99',
                                    0.65:'#FF9933',
                                  },
                                },

                              },
                            },
                          }]
                          : undefined}
                        location={value}
                        mapId='example-map-data-tile'
                        pinDisplayed
                        popupHeading={t('generic.chosen_location')}
                        popupParagraph={value?.address}
                        width='100%'
                        zoom={15}
                        zoomControl
                      />
                    </MapWrapper>
                  </>
                )
              }
              {isMapDisplayed && (
                <MapVisualizationModal
                  isDisplayed={isMapDisplayed}
                  layers={data?.value?.populationHeatmap
                    ? [{
                      id: data?.value?.populationHeatmap?.id,
                      layer: {
                        ...data?.value?.populationHeatmap,
                        options: {
                          type: data?.value?.populationHeatmap?.type,
                          id: data?.value?.populationHeatmap?.id,
                          style: {
                            ...data?.value?.populationHeatmap?.style,
                            blur: 5,
                            radius: 90,
                            minOpacity: 0.07,
                            gradient: {
                              1:'#FF0000',
                              '0.0':'#0099FF',
                              0.3:'#FFFF99',
                              0.65:'#FF9933',
                            },
                          },

                        },
                      },
                    }]
                    : undefined}
                  location={value}
                  mapId={`people-tile-${values?.find(c => c.id === 'population')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}`}
                  setIsDisplay={setIsMapDisplayed}
                  zoom={15}
                />
              )}
              {(!mapVisible && canBeSave && (value?.country === 'PL' || locationPDF.country === 'PL')) && (
                <AddMapView
                  buttonValue={1}
                  description='placeme.add_people_density.description'
                  onChange={handleAddMap}
                  title='placeme.add_people_density.title'
                />
              )}
            </TileFormSection>
          )
          : (
            <TileFormSection>
              <TileFormSectionTitle>
                <span>{t('placeme.people_tile.section_title_3_span_1')}</span>
              </TileFormSectionTitle>
              <TileFormRowWithData>
                <span>{t('placeme.people_tile.row_with_data_7_span_1')}</span>
              </TileFormRowWithData>
              <TileFormSpan>
                <strong>{`${parseFloat((data?.value?.inhabitants?.densityValue * 100).toFixed(1))} % `}</strong>
                {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.people_tile_percentages.form_span_3_normal_1') : t('placeme.people_tile_percentages.form_span_3_normal_1.foreign')}
              </TileFormSpan>
            </TileFormSection>
          )}
      </TileFormWrapper>
    )
}
