import React, { FormEvent, FormEventHandler, useState } from 'react'
import { ReactComponent as Arrow } from 'libs/shared/assets/src/lib/icons/arrows/curvedArrow.svg'
import { ReactComponent as Refresh } from 'libs/shared/assets/src/lib/icons/refresh.svg'
import { ReactComponent as Add } from 'libs/shared/assets/src/lib/icons/rangeIcons/Add.svg'
import { ReactComponent as Remove } from 'libs/shared/assets/src/lib/icons/rangeIcons/Remove.svg'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { mediaMax as media } from '@dataplace.ai/ui-components/themes'
import { Button } from '@dataplace.ai/ui-components/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { DataType } from '../../organisms/Tiles/Potential/SalesPotential/@types/ISalesPotentialTileData'
import { ShopType } from '../TypeRangeSelector'

const FormWrapper = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    border: 1.5px dashed ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    padding: 0 1rem 1rem 1rem;
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
    margin-top: 0 !important;
    background-color: ${palette.light.main};
    color: ${palette.black};

    h4 {
      font-weight: 500;
      font-size: ${typography.main.pt_15_semibold.fontSize};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
      color: ${palette.black};
      margin: 15px 0;
    }

    p {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      color: ${palette.dark.normal};

      ${media.mobile`
        font-size: ${typography.tiny.pt_12_semibold.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold.lineHeight};
      `}
    }

    form {
      > button {
        float: right;
        margin-top: 0.75rem;
      }
    }
  `
})

const Box = styled.div<{isPdf?: boolean}>(({
  theme, isPdf,
}) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${corners.default.borderRadius};
    padding: 0.75rem 10px;
    background-color: ${palette.product.location.main};
    color: ${palette.light.white};
    margin-right: ${isPdf ? '0' : '15px'};
    height: fit-content;
    width: 65%;

    h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      letter-spacing: ${typography.main.pt_15_semibold.letterSpacing};
      margin-bottom: 0;

      ${media.mobile`
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
      `}
    }

    span {
      font-size: ${typography.tiny.pt_12_regular.fontSize};
      font-weight: ${typography.tiny.pt_12_regular.fontWeight};
      line-height: ${typography.tiny.pt_12_regular.lineHeight};
    }

    .amount {
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
      margin-left: 15px;
      white-space: nowrap;

      ${media.mobile`
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
      `}

      > span {
        font-size: ${typography.small.pt_13_semibold.fontSize};
        font-weight: ${typography.small.pt_13_semibold.fontWeight};
        line-height: ${typography.small.pt_13_semibold.lineHeight};
      }
    }
  `
})

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  overflow: visible;

  > p {
    width: 35%;
  }

  svg {
    margin-bottom: -13px;
    margin-left: 5px;
  }
`

const Row = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #edf0f5;

  &:last-of-type {
    border-bottom: none;
  }

  > span {
    display: flex;
    align-items: center;
    ${media.mobile`
      font-size: 10px;
    `}

    > svg {
      cursor: pointer;
      width: 25px;
      min-width: 25px;

      &:first-of-type {
        padding-left: 10px;
      }

      &:last-of-type {
        margin-left: -12.5px;
      }

      ${media.mobile`
        width: 20px;
        min-width: 20px;
      `}
    }

    > input {
      width: 90px;
      background: none;
      border: none;
      outline: none;
      cursor: default;
      text-align: center;
      -moz-appearance: textfield;
    }

    > span {
      margin-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .slider {
      -webkit-appearance: none;
      appearance: none;
      border-radius: 10px;
      width: 100%;
      height: 10px;
      background: ${palette.light.darkest};
      outline: none;
    }

    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 15px;
      height: 15px;
      background: ${palette.blue};
      cursor: pointer;
      border-radius: 10px;
    }

    .slider::-moz-range-thumb {
      background: ${palette.blue};
    }
  }

  > span:last-of-type {
    width: 35%;

    ${media.mobile`
      width: 50%;
    `}

    > input[type='range'] {
      width: 100%;
    }
  }
` })

const SmallButton = styled.div<{background: 'light' | 'green'}>(({
  theme, background,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: ${background === 'light' ? palette.light.white : palette.results.green.dark};
    color: ${background === 'light' ? palette.dark.normal : palette.light.white};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    display: inline-flex;
    padding: 5px 10px;
    cursor: pointer;
    outline: none;
    border: 1px solid #E1E2E3;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    white-space: nowrap;
` })

const StyledButton = styled(Button)(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    background-color: transparent;
    color: ${palette.blue};
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    outline: none;
    border: none;
    box-shadow: none;
    width: 33%;
    min-width: 33%;
    &:hover, &:focus, &:disabled {
      background: transparent;
      color: ${palette.blue};
      border: none;
      box-shadow: none;
    }

    > svg {
      min-width: 16px;
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
` })

export interface ManagePotentialModelData {
  key: DataType
  label: string
  value: number
  unit: string
  step: number
  min?: number
  max?: number
}

export interface IManagePotentialModelParamsProps {
  boxValue: number
  data: ManagePotentialModelData[]
  onValueChange: (value: DataType, sign: boolean | number, step?: number, min?: number, max?: number) => void
  onSubmit: FormEventHandler<HTMLFormElement>
  boxTitle: string
  unit?: string
  canBeReset: boolean
  reset: () => void
  isPdf?: boolean
  tileId?: string
  buttonLoading: boolean
}

export const ManagePotentialModelParams = ({
  boxValue,
  data,
  onValueChange,
  onSubmit,
  boxTitle,
  unit,
  canBeReset,
  reset,
  isPdf,
  tileId,
  buttonLoading,
}: IManagePotentialModelParamsProps): JSX.Element => {
  const { t } = useTranslation()
  const [type, setType] = useState<ShopType>('0')
  const { canBeSave } = useSelector((state: RootState) => state.analysis)
  const onButtonClick = (e: FormEvent<HTMLFormElement>) => {
    onSubmit(e)
  }

  return (
    <FormWrapper>
      <h4>{t('placeme.sales_potetial.params.title')}</h4>
      <p>{t('placeme.sales_potetial.params.description')}</p>

      <FlexWrapper>
        <Box isPdf={isPdf}>
          <div>
            <h5>{t(boxTitle)}</h5>
            <span>{t('placeme.sales_potetial.params.box.description')}</span>
          </div>
          <span className='amount'>
            {boxValue}
            {unit && (
              <span>
                {' '}
                {unit}
              </span>
            )}
          </span>
        </Box>
        {canBeSave && !canBeReset && !isPdf && (
          <p>
            {t('placeme.sales_potetial.params.tip')}
            <Arrow />
          </p>
        )}
        {canBeSave && canBeReset && !isPdf && (
          <StyledButton
            loading={buttonLoading}
            onClick={() => {
              reset()
            }}
            type='button'
          >
            <Refresh />
            {t('placeme.sales_potetial.params.reset')}
          </StyledButton>
        )}
      </FlexWrapper>

      {isPdf
        ? (
          <div>
            {`{{table_${tileId?.replace(/-/g, '_')} | safe}}`}
          </div>
        )
        : (
          <form onSubmit={onButtonClick}>
            {data?.map((item) => (
              <Row key={item?.key}>
                <span>{t(`${item?.label}`)}</span>
                {canBeSave && (item?.key !== 'preferences' && item?.key !== 'storeType') && (
                  <span>
                    <Remove onClick={() => onValueChange(item?.key, false, item?.step, item?.min, item?.max)} />
                    <input
                      readOnly
                      value={formatNumber(Math.round(item?.value))}
                    />
                    <Add onClick={() => onValueChange(item?.key, true, item?.step, item?.min, item?.max)} />
                    {item?.unit && <span>{t(`${item?.unit}`)}</span>}
                  </span>
                )}
                {canBeSave && item?.key === 'preferences' && (
                  <span>
                    <div className='slider-container'>
                      <input
                        className='slider'
                        onChange={(e) => onValueChange(item?.key, parseFloat(e?.target?.value))}
                        type='range'
                        value={item?.value}
                      />
                    </div>
                  </span>
                )}
                {canBeSave && item?.key === 'storeType' && (
                  <span>
                    <SmallButton
                      background={type === '0' ? 'green' : 'light'}
                      onClick={() => {
                        setType('0')
                        onValueChange(item?.key, 0)
                      }}
                    >
                      {t('placeme.type_range_selector.button.grocery')}
                    </SmallButton>
                    <SmallButton
                      background={type === '1' ? 'green' : 'light'}
                      onClick={() => {
                        setType('1')
                        onValueChange(item?.key, 1)
                      }}
                    >
                      {t('placeme.type_range_selector.button.other')}
                    </SmallButton>
                  </span>
                )}
                {!canBeSave && (
                  <span>
                    {`${item?.value} ${t(`${item?.unit}`)}`}
                  </span>
                )}
              </Row>
            ))}

            {canBeSave && (
              <Button
                disabled={!canBeReset}
                loading={buttonLoading}
                type='submit'
              >
                {t('placeme.sales_potetial.params.button')}
              </Button>
            )}
          </form>
        ) }
    </FormWrapper>
  )
}
