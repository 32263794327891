export const dummy = {
  employeesInRange: [
    {
      economicSector: 'placeme.employees_in_range_tile.sector.industry',
      employees: 1234,
    },
    {
      economicSector: 'placeme.employees_in_range_tile.sector.public',
      employees: 1234,
    },
    {
      economicSector: 'placeme.employees_in_range_tile.sector.services',
      employees: 1234,
    },
    {
      economicSector: 'placeme.employees_in_range_tile.sector.office_workers',
      employees: 1234,
    },
  ],
}
