import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: ${palette.blue};
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};
  `
})

interface IWrapperProps {
  isOpen?: boolean
}

const Wrapper = styled.div<IWrapperProps>(
  ({ isOpen }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;

    > img {
      width: 1rem;
      height: 1rem;
      margin: 0 0.625rem 0 0.125rem;
    }

    ${!isOpen
    && css`
      > img {
        margin: 0 1.375rem;
      }
    `}
  `,
)

interface ISaveTemplateProps {
  isOpen?: boolean
}

export const SaveTemplate = ({ isOpen }: ISaveTemplateProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Wrapper isOpen={isOpen}>
      <img
        alt='save'
        src='assets/icons/dataplace/save.svg'
      />
      {isOpen && <Title>{t('placeme.sidebar.save_as_template')}</Title>}
    </Wrapper>
  )
}

SaveTemplate.defaultProps = {
  isOpen: true,
}

