/* eslint-disable max-lines */
import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { AuthContext } from '@dataplace.ai/features'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { BarChart, DoughnutChart, GaugeChart, RangeChart, Result, Results, TileFormColumn, TileFormRowWithData, TileFormSpan } from '@dataplace.ai/ui-components/atoms'
import { config } from 'apps/placeme/src/config'
import { getAxios, getRangeTranslationFromRangeId } from '@dataplace.ai/functions/utils'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import NumberFormat from 'react-number-format'
import { bigBenCategoryId, bigBenChainRankingParameters } from '@dataplace.ai/constants'
import { IRange } from '@dataplace.ai/types'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import AddMoreData from '../../../../molecules/AddMoreData/AddMoreData'
import { useAppDispatch } from '../../../../../../../redux/hooks'
import { fetchWorkspaceUsageValue, saveTileData } from '../../../../../slice/analysisSlice'
import { IPopulationTileData } from './@types/IPopulationAndSpendingTileData'
import { RankingCustomCatchmentSpan } from '../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Section = styled.div(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: ${palette.light.white};
    border: 1px solid ${palette.light.darkest};
    border-radius: ${corners.default.borderRadius};
    color: ${palette.black};
    margin-bottom: 30px;

    > h4 {
      font-size: ${typography.main.pt_15_medium_upper.fontSize};
      font-weight: ${typography.main.pt_15_medium_upper.fontWeight};
      line-height: ${typography.main.pt_15_medium_upper.lineHeight};
      letter-spacing: ${typography.main.pt_15_medium_upper.letterSpacing};
      text-transform: ${typography.main.pt_15_medium_upper.textTransform};
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    > h5 {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }

    > p {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-bottom: 15px;

      > span {
        font-size: ${typography.big.pt_28_semibold.fontSize};
        font-weight: ${typography.big.pt_28_semibold.fontWeight};
        line-height: ${typography.big.pt_28_semibold.lineHeight};
        font-family: 'Inter',sans-serif;
        display: inline;
        margin-left: 0.25rem;
      }
    }

    > span {
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      margin-bottom: 10px;

      > strong {
        font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
        font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
        line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
        letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
        text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
        display: block;
        margin-top: 10px;
      }
      
      > p {
        font-size: ${typography.small.pt_14_semibold.fontSize};
        font-weight: ${typography.small.pt_14_semibold.fontWeight};
        line-height: ${typography.small.pt_14_semibold.lineHeight};
        display: inline;
        margin-top: 10px;
      }
    }
  `
})

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Legend = styled.span<{color: string}>(({
  theme, color,
}) => {
  const { typography } = theme
  return css`
    display: inline-block;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    border-left: 10px solid ${color};
    margin: 0.5rem 1rem 0.5rem 0;
    padding: 0.25rem 0.5rem;
  ` })

const Divider = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    width: 100%;
    height: 1px;
    border-top: 1px solid ${palette.light.darkest};
    margin-bottom: 1rem;
` })

export const PopulationAndSpendingTile: React.FC<{ data: IPopulationTileData, tileId: string, isPdf: boolean }> = ({
  data, tileId, isPdf,
}) => {
  // variables
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const authContext = useContext(AuthContext)
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const rangeTranslation = useMemo(() =>
    getRangeTranslationFromRangeId(data?.value?.catchmentMode),
  [JSON.stringify(data?.value?.catchmentMode)])

  // states
  const [token, setToken] = useState('')
  const [initialData, setInitialData] = useState<unknown>({})
  const [loading, setLoading] = useState(false)
  const [catchmentId, setCatchmentId] = useState<undefined | string>(undefined)
  const [chosenRange, setChosenRange] = useState<undefined | IRange>(undefined)

  // functions
  const calculateResultBean = (whichSpot: number, all: number): {state: Result, label: string} => {
    if ((whichSpot / all) * 100 >= 60) { return {
      state: 'positive',
      label: t('generic.high'),
    } }
    if ((whichSpot / all) * 100 <= 40) { return {
      state: 'negative',
      label: t('generic.low'),
    } }
    return {
      state: 'average',
      label: t('generic.average'),
    }
  }

  const getCatchmentId = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange?.catchmentId

  const getChosenRange = () => values?.find(c => c.id === bigBenCategoryId)?.tiles?.find(t =>
    t.id === tileId)?.chosenRange

  const fetchData = useCallback(async (addData?) => {
    if (token.length) {
      let body: {
        [key: string]: unknown
      } = {
        catchmentId,
      }
      if (addData) {
        body = {
          ...body,
          extra: true,
        }
      } else {
        body = {
          ...body,
          parameters: ['people', 'purchasing_power', 'alcohol_spendings', 'total_number_of_shops'],
          rankingParameter: [
            bigBenChainRankingParameters.population,
          ],
        }
      }

      const getTileType = (id: string) => id.split('-')[0]
      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        saveData = {
          loading: false,
          error: e.message,
          value: null,
        }
      }
      finally {
        if (!addData) {
          dispatch(saveTileData(bigBenCategoryId, tileId, saveData))
          setInitialData(saveData?.value)
        } else {
          dispatch(saveTileData(bigBenCategoryId, tileId, {
            ...data,
            value: {
              ...addData,
              additionalData: saveData?.value?.additionalData,
            },
          }))
        }
      }
    }
  }, [token])

  // hooks
  useEffect(() => {
    setCatchmentId(getCatchmentId())
    setChosenRange(getChosenRange())
  }, [values])

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (!data?.value) fetchData()
  }, [fetchData, !data?.value])

  return !data?.value || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_1')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.people.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.people?.residentsValue}
            />
          </p>
          <span>{t('placeme.population_and_finances_tile.people.line_2')}</span>

          {!isPdf
            ? (
              <>
                <span><strong>{t('placeme.network_ranking.title')}</strong></span>
                <span>
                  {t('placeme.population_and_finances_tile.people.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <RangeChart
                  label='generic.place'
                  max={data?.value?.totalNumberOfShops}
                  modal={{
                    catchmentId,
                    parameter: bigBenChainRankingParameters.population,
                    rangeTranslation,
                  }}
                  tooltip1='placeme.population_and_spending_tile.population.tooltip1'
                  tooltip1Value={data?.value?.people?.highestResidentsValue}
                  tooltip2='placeme.population_and_spending_tile.population.tooltip2'
                  tooltip2Value={data?.value?.people?.lowestResidentsValue}
                  value={data?.value?.people?.residentsValueRank}
                />
              </>
            )
            : (
              <>
                <p>
                  {t('placeme.network_ranking.title')}
                  {' '}
                  <NumberFormat
                    displayType='text'
                    thousandSeparator=' '
                    value={data?.value?.people?.residentsValueRank}
                  />
                </p>
                <span>
                  {t('placeme.population_and_finances_tile.people.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.population.tooltip1')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.people?.highestResidentsValue}
                    />
                  </p>
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.population.tooltip2')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.people?.lowestResidentsValue}
                    />
                  </p>
                </span>
              </>
            )}

        </Section>

        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_2')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.purchasing.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.purchasingPower?.averageSalary?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          <p>
            {t('placeme.population_and_finances_tile.purchasing.line_2')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.purchasingPower?.totalEarnings?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          {!isPdf
            ? (
              <>
                <span><strong>{t('placeme.network_ranking.title')}</strong></span>
                <span>
                  {t('placeme.population_and_finances_tile.purchasing.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <RangeChart
                  label='generic.place'
                  max={data?.value?.totalNumberOfShops}
                  modal={{
                    catchmentId,
                    parameter: bigBenChainRankingParameters.purchasing_power,
                    rangeTranslation,
                  }}
                  tooltip1='placeme.population_and_spending_tile.purchasing.tooltip1'
                  tooltip1Value={data?.value?.purchasingPower?.highestAverageSalary}
                  tooltip2='placeme.population_and_spending_tile.purchasing.tooltip2'
                  tooltip2Value={data?.value?.purchasingPower?.lowestAverageSalary}
                  value={data?.value?.purchasingPower?.averageSalaryRank}
                />
              </>
            )
            : (
              <>
                <p>
                  {t('placeme.network_ranking.title')}
                  {' '}
                  <NumberFormat
                    displayType='text'
                    thousandSeparator=' '
                    value={data?.value?.purchasingPower?.averageSalaryRank}
                  />
                </p>
                <span>
                  {t('placeme.population_and_finances_tile.purchasing.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.purchasing.tooltip1')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.purchasingPower?.highestAverageSalary}
                    />
                  </p>
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.purchasing.tooltip2')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.purchasingPower?.lowestAverageSalary}
                    />
                  </p>
                </span>
              </>
            )}
        </Section>

        <Section>
          <h4>{t('placeme.population_and_finances_tile.section_title_3')}</h4>
          <p>
            {t('placeme.population_and_finances_tile.alcohol.line_1')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.alcoholSpendings?.perCapita?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          <p>
            {t('placeme.population_and_finances_tile.alcohol.line_2')}
            {' '}
            <NumberFormat
              displayType='text'
              thousandSeparator=' '
              value={data?.value?.alcoholSpendings?.catchmentSum?.toFixed(0)}
            />
            {` PLN ${t('generic.net')}`}
          </p>
          {!isPdf
            ? (
              <>
                <span><strong>{t('placeme.network_ranking.title')}</strong></span>
                <span>
                  {t('placeme.population_and_finances_tile.alcohol.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <RangeChart
                  label='generic.place'
                  max={data?.value?.totalNumberOfShops}
                  modal={{
                    catchmentId,
                    parameter: bigBenChainRankingParameters.spending,
                    rangeTranslation,
                  }}
                  tooltip1='placeme.population_and_spending_tile.alcohol.tooltip1'
                  tooltip1Value={data?.value?.alcoholSpendings?.highestPerCapita}
                  tooltip2='placeme.population_and_spending_tile.alcohol.tooltip2'
                  tooltip2Value={data?.value?.alcoholSpendings?.lowestPerCapita}
                  value={data?.value?.alcoholSpendings?.perCapitaRank}
                />
              </>
            )
            : (
              <>
                <p>
                  {t('placeme.network_ranking.title')}
                  {' '}
                  <NumberFormat
                    displayType='text'
                    thousandSeparator=' '
                    value={data?.value?.alcoholSpendings?.perCapita?.toFixed(0)}
                  />
                </p>
                <span>
                  {t('placeme.population_and_finances_tile.alcohol.line_3')}
                  <RankingCustomCatchmentSpan
                    chosenRange={chosenRange}
                    rangeTranslation={rangeTranslation}
                    tileId={tileId}
                  />
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.alcohol.tooltip1')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.alcoholSpendings?.highestPerCapita}
                    />
                  </p>
                </span>
                <span>
                  {t('placeme.population_and_spending_tile.alcohol.tooltip2')}
                  {' '}
                  <p>
                    <NumberFormat
                      displayType='text'
                      thousandSeparator=' '
                      value={data?.value?.alcoholSpendings?.lowestPerCapita}
                    />
                  </p>
                </span>
              </>
            )}

        </Section>

        {!data?.value?.additionalData
               && canBeSave && !isPdf
        && (
          <AddMoreData
            loading={loading}
            onClick={() => {
              setLoading(true)
              fetchData(initialData)
            }}
            tiles={['placeme.population_and_finances_tile.section_title_4', 'placeme.population_and_finances_tile.section_title_5', 'placeme.population_and_finances_tile.section_title_6', 'placeme.population_and_finances_tile.section_title_7']}
          />
        )}

        {data?.value?.additionalData && (
          <>
            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_4')}</h4>
              <FlexWrapper>
                <TileFormColumn
                  align='start'
                  width='65%'
                >
                  <TileFormRowWithData legend='#362FB7'>
                    <span>{t('placeme.population_and_finances_tile.demography.women')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.sexStructure?.f * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.population_and_finances_tile.demography.women_ratio')}
                  </TileFormSpan>
                  <TileFormRowWithData legend='#7E7AD2'>
                    <span>{t('placeme.population_and_finances_tile.demography.men')}</span>
                    <span>{`${parseFloat((data?.value?.additionalData?.sexStructure?.m * 100).toFixed(1))}%`}</span>
                  </TileFormRowWithData>
                  <TileFormSpan>
                    {t('placeme.population_and_finances_tile.demography.men_ratio')}
                  </TileFormSpan>
                </TileFormColumn>

                <TileFormColumn width='34%'>
                  {isPdf
                    ? (
                      <img
                        alt='chart'
                        src={`data:image/svg+xml;base64,{{ donutchart_${tileId.replace(/-/g, '_')
                        } }}`}
                      />
                    )
                    : (
                      <DoughnutChart
                        data={[
                          data?.value?.additionalData?.sexStructure?.f * 100,
                          data?.value?.additionalData?.sexStructure?.m * 100]
                          .map(number => parseFloat(number.toFixed(1)))}
                        innerLabels
                        labels={[t('placeme.population_and_finances_tile.demography.women'), t('placeme.population_and_finances_tile.demography.men')]}
                        valueType='percentages'
                        width={150}
                      />
                    )}
                </TileFormColumn>
              </FlexWrapper>
            </Section>

            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_5')}</h4>
              <p>
                {t('placeme.population_and_finances_tile.age.line_1')}
                {' '}
                <span>
                  {data?.value?.additionalData?.averageAge}
                  {' '}
                  {t('generic.years')}
                </span>
              </p>
              <span>{t('placeme.population_and_finances_tile.age.line_2')}</span>

              {!isPdf
                ? (
                  <>
                    <span><strong>{t('placeme.network_ranking.title')}</strong></span>
                    <span>
                      {t('placeme.population_and_finances_tile.age.line_3')}
                      <RankingCustomCatchmentSpan
                        chosenRange={chosenRange}
                        rangeTranslation={rangeTranslation}
                        tileId={tileId}
                      />
                    </span>
                    <RangeChart
                      label='generic.place'
                      max={data?.value?.totalNumberOfShops}
                      modal={{
                        catchmentId,
                        parameter: bigBenChainRankingParameters.average_age,
                        rangeTranslation,
                      }}
                      tooltip1='placeme.population_and_spending_tile.age.tooltip1'
                      tooltip1Value={data?.value?.additionalData?.highestAverageAge}
                      tooltip2='placeme.population_and_spending_tile.age.tooltip2'
                      tooltip2Value={data?.value?.additionalData?.lowestAverageAge}
                      value={data?.value?.additionalData?.averageAgeRank}
                    />
                  </>
                )
                : (
                  <>
                    <p>
                      {t('placeme.network_ranking.title')}
                      {' '}
                      <span>
                        {data?.value?.additionalData?.averageAgeRank}
                      </span>
                    </p>
                    <span>
                      {t('placeme.population_and_finances_tile.age.line_3')}
                      <RankingCustomCatchmentSpan
                        chosenRange={chosenRange}
                        rangeTranslation={rangeTranslation}
                        tileId={tileId}
                      />
                    </span>
                    <span>
                      {t('placeme.population_and_spending_tile.age.tooltip1')}
                      {' '}
                      <p>{data?.value?.additionalData?.highestAverageAge}</p>
                    </span>
                    <span>
                      {t('placeme.population_and_spending_tile.age.tooltip2')}
                      {' '}
                      <p>{data?.value?.additionalData?.lowestAverageAge}</p>
                    </span>
                  </>
                )}

            </Section>

            <Section>
              <h4>{t('placeme.population_and_finances_tile.section_title_6')}</h4>
              <div>
                <Legend color='#362FB7'>{t('generic.women')}</Legend>
                <Legend color='#7E7AD2'>{t('generic.men')}</Legend>
              </div>
              <span><strong>{t('placeme.population_and_spending.bar_title')}</strong></span>
              {isPdf
                ? (
                  <img
                    alt='chart'
                    src={`data:image/svg+xml;base64,{{ barchart_${tileId.replace(/-/g, '_')
                    } }}`}
                  />
                )
                : (
                  <>
                    <BarChart
                      dataset={[
                        {
                          label: t('placeme.demography_tile.bar_chart_dataset_label_1'),
                          data: data?.value?.additionalData?.ageRange?.map(ar => (ar.f)),
                        },
                        {
                          label: t('placeme.demography_tile.bar_chart_dataset_label_2'),
                          data: data?.value?.additionalData?.ageRange?.map(ar => (ar.m)),
                        },
                      ]}
                      labels={data?.value?.additionalData?.ageRange?.map(ar => (ar.label))}
                    />
                    <span
                      style={{
                        textAlign: 'center',
                        marginTop: 0,
                      }}
                    >
                      <strong>{t('placeme.population_and_spending.bar_label')}</strong>
                    </span>
                  </>
                )}
            </Section>
            <Section>
              <h4>
                {t('placeme.population_and_finances_tile.section_title_7')}
                <Results
                  state={calculateResultBean(12, 123).state}
                  value={calculateResultBean(12, 123).label}
                />
              </h4>

              <h5>{t('placeme.population_and_finances_tile.density')}</h5>

              { !isPdf
                ? (
                  <>
                    <GaugeChart
                      height={150}
                      legend={
                        ['placeme.population_and_finances_tile.gauge.lowest', 'placeme.population_and_finances_tile.gauge.highest']
                      }
                      unit='%'
                      value={data?.value?.additionalData?.densityValue * 100}
                    />

                    <Divider />
                  </>
                )
                : (
                  <>
                    <span>
                      <p>{`${data?.value?.additionalData?.densityValue * 100}%`}</p>
                      {' '}
                      {t('placeme.population_and_finances_tile.density.line_2')}
                    </span>
                  </>
                )}

              {!isPdf
                ? (
                  <>
                    <span><strong>{t('placeme.network_ranking.title')}</strong></span>
                    <span>
                      {t('placeme.population_and_finances_tile.density.line_3')}
                      <RankingCustomCatchmentSpan
                        chosenRange={chosenRange}
                        rangeTranslation={rangeTranslation}
                        tileId={tileId}
                      />
                    </span>
                    <RangeChart
                      label='generic.place'
                      max={data?.value?.totalNumberOfShops}
                      modal={{
                        catchmentId,
                        parameter: bigBenChainRankingParameters.density_value,
                        rangeTranslation,
                      }}
                      tooltip1='placeme.population_and_spending_tile.density.tooltip2'
                      tooltip1Value={data?.value?.additionalData?.highestDensityValue}
                      tooltip2='placeme.population_and_spending_tile.density.tooltip1'
                      tooltip2Value={data?.value?.additionalData?.lowestDensityValue}
                      value={data?.value?.additionalData?.densityValueRank}
                    />
                  </>
                )
                : (
                  <>
                    <p>
                      {t('placeme.network_ranking.title')}
                      {' '}
                      <span>
                        {data?.value?.additionalData?.densityValueRank}
                      </span>
                    </p>
                    <span>
                      {t('placeme.population_and_finances_tile.density.line_3')}
                      <RankingCustomCatchmentSpan
                        chosenRange={chosenRange}
                        rangeTranslation={rangeTranslation}
                        tileId={tileId}
                      />
                    </span>
                    <span>
                      {t('placeme.population_and_spending_tile.density.tooltip1')}
                      {' '}
                      <p>{`${data?.value?.additionalData?.highestDensityValue * 100}%`}</p>
                    </span>
                    <span>
                      {t('placeme.population_and_spending_tile.density.tooltip2')}
                      {' '}
                      <p>{`${data?.value?.additionalData?.lowestDensityValue * 100}%`}</p>
                    </span>
                  </>
                )}
            </Section>
          </>
        )}
      </Wrapper>
    )
}

export default PopulationAndSpendingTile
