/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Button, PopupWithOverlay } from '@dataplace.ai/ui-components/atoms'
import { ReactComponent as CloseIcon } from '../../../../../../../libs/shared/assets/src/lib/icons/dataplace/add.svg'

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 2.5rem 2.5rem 0.625rem 2.5rem;
  `
})

const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
`
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
`

const IconWrapper = styled.div(() => css`
    margin: 0 0 1.5rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    > svg {
      margin: 1.25rem auto;
    }
`)

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Heading = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    font-size: ${typography.big.pt_28_semibold.fontSize};
    font-weight: ${typography.big.pt_28_semibold.fontWeight};
    line-height: ${typography.big.pt_28_semibold.lineHeight};
    `
})
const Description = styled.h2(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
      max-width: 36rem;
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
      margin-bottom: .5rem;
      `
})

interface IFullscreenModal {
  trigger: boolean
  cancelButtonClick?: () => void
  agreeButtonClick?: ()=> void
  agreeButtonText?: string
  cancelButtonText?: string
  header: string
  description: string
  image: JSX.Element
}

export const FullscreenModal = ({
  trigger, cancelButtonClick, agreeButtonClick, agreeButtonText, cancelButtonText,
  image, header, description,
}: IFullscreenModal):JSX.Element => {
  const [open, setOpen] = useState(false)
  const closeModal = () => {
    setOpen(false)
    if (cancelButtonClick) cancelButtonClick()
  }
  const agree = () => {
    setOpen(false)
    if (agreeButtonClick) agreeButtonClick()
  }

  useEffect(() => {
    setOpen(trigger)
  }, [trigger])

  return (
    <>
      <PopupWithOverlay
        closeOnDocumentClick={false}
        modal
        onClose={() => setOpen(false)}
        open={open}
      >
        <Wrapper>
          <CloseIcon
            height='18'
            onClick={closeModal}
            style={{
              transform:'rotate(45deg)',
              cursor: 'pointer',
              position: 'relative',
              bottom: '1rem',
              left: '1rem',
            }}
            width='18'
          />
          <TopWrapper>
            <HeaderWrapper>
              <Heading>{header}</Heading>
            </HeaderWrapper>
            <Description>{description}</Description>
          </TopWrapper>
          <IconWrapper>
            {image}
          </IconWrapper>
          <BottomWrapper>
            {cancelButtonText && (
              <Button
                margin='0 1rem 1.25rem 0'
                onClick={cancelButtonClick}
                style={{
                  backgroundColor: '#F08F7F',
                  borderColor: '#d16b5b',
                }}
              >
                {cancelButtonText}
              </Button>
            )}
            {agreeButtonText && (
              <Button
                margin='0 1rem 1.25rem 0'
                onClick={agree}
              >
                {agreeButtonText}
              </Button>
            )}
          </BottomWrapper>
        </Wrapper>
      </PopupWithOverlay>
    </>
  )
}
