import React from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { useTranslation } from 'react-i18next'
import { Divider, Toggler } from '@dataplace.ai/ui-components/atoms'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { ReactComponent as Help } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/help-circle.svg'
import { ReactComponent as Chat } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/chat.svg'
import { AnalyseInfoModal } from '../../organisms/AnalyseInfoModal'
import { toggleHintsAction } from '../../../slice/analysisSlice'
import { RootState } from '../../../../../redux/store'

const Wrapper = styled.div(() => css`
    display: flex;
  position: absolute;
  right: 2.5rem;
  margin-top: 10px;
  `)

const TriggerWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${palette.blue};
    border-radius: 50%;
    cursor: pointer;

    > svg {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${palette.light.white};
      ;
      > path {
        fill: ${palette.blue};
      }
    }
  `
})

const Menu = styled.div(({ theme }) => {
  const { corners } = theme
  return css`
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(21,24,31,.9);
    border-radius: ${corners.default.borderRadius};
  `
})

const Row = styled.div(({ theme }) => {
  const {
    palette, typography, corners,
  } = theme
  return css`
    padding: 0.25rem 0.75rem;
    color: ${palette.light.white};
    display: flex;
    align-items: center;
    font-size: ${typography.tiny.pt_12_medium.fontSize};
    font-weight: ${typography.tiny.pt_12_medium.fontWeight};
    line-height: ${typography.tiny.pt_12_medium.lineHeight};
    border-radius: ${corners.default.borderRadius};
    cursor: pointer;

    :hover {
      background-color: rgba(0,0,0, 0.2);
      color: rgba(255,255,255, 0.9);
    }

    > * {
      margin-right: 0.25rem;
    }
  `
})

export const AskCircle = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { showHints } = useSelector((state: RootState) => state.analysis)

  const toggleHints = () => {
    dispatch(toggleHintsAction())
  }

  return (
    <Wrapper>
      <Popup
        closeOnDocumentClick
        nested
        on='click'
        position='left center'
        trigger={(
          <TriggerWrapper>
            <Help viewBox='1 1 14 14' />
          </TriggerWrapper>
        )}
      >
        {(close: () => void) => (
          <Menu>
            <Popup
              closeOnDocumentClick
              lockScroll
              modal
              on='click'
              onClose={close}
              onOpen={handleMenuModalClose}
              overlayStyle={defaultTheme.overlay}
              trigger={<Row>{t('placeme.ask_circle_menu.looking_for')}</Row>}
            >
              {(close: () => void) => (<AnalyseInfoModal close={close} />) }
            </Popup>
            <Row>{t('placeme.ask_circle_menu.knowledge_base')}</Row>
            <Row>{t('generic.faq')}</Row>
            <Row>
              <Chat />
              {t('generic.start_chat')}
            </Row>
            <Divider />
            <Row>
              <Toggler
                onChange={toggleHints}
                value={showHints}
              />
              {t('generic.hints')}
            </Row>
          </Menu>
        )}
      </Popup>
    </Wrapper>
  )
}
