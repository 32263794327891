import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Download } from '../../../../../assets/src/lib/icons/dataplace/download.svg'
import { ILinkWithIconInterface } from '../@types'

const Button = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    cursor: pointer;
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

    :focus {
      color: ${palette.light.white};
      background-color: ${palette.product.location.main};
    }

    > span {
      margin-left: 0.25rem;
    }
  `
})

export const LinkWithIconDownload = ({ label }: ILinkWithIconInterface): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button>
      <Download />
      {label ? <span>{label}</span> : <span>{t('generic.download')}</span>}
    </Button>
  ) }
