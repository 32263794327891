import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { mediaMax as media } from '@dataplace.ai/ui-components/themes'
import { useTranslation } from 'react-i18next'
import { ISelectProps } from './@types/ISelect.props'
import { SelectCaret } from '../../atoms/Icons/SelectCaret'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div<{width?: string}>(({ width }) => css`
    margin: 0.5rem 0;
    width: ${width || '100%'};
  `)

const InputHeader = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: ${palette.gray.dark};
    margin-bottom: 0.25rem;
    font-weight: 400;

    > label {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_semibold.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    }
  `
})

const StyledDropDown = styled.div<{ isOpen: boolean }>(({
  theme, isOpen,
}) => {
  const {
    palette, typography,
  } = theme
  return css`
    position: relative;
    color: ${palette.black};
    border: 1px solid ${palette.dark.lightest};
    border-radius: 0.25rem;
    height: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 3rem;
    font-weight: 400;
    font-size: 1rem;
    background: #ffffff;
    display: flex;
    align-items: center;
    margin: 1px;
    width:100%;

    > :first-child {
      position: absolute;
      right: 0;
      margin: 0 1.25rem;
    }

    :hover {
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
      border-color: ${palette.blue};
    }

    :focus {
      margin: 0;
      box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
      border: 2px solid ${palette.blue};
      outline: none;
    }

    ${isOpen && css`
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

    .placeholder{
      color: ${palette.dark.lightest};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }

    ${media.mobile`
      width: fit-content;
      padding-left: .5rem;
      padding-right: 2rem;

      > :first-child {
        margin: 0 .25rem;
      }
    `}
  `
})

const DropDownListContainer = styled.ul<{ isOpen: boolean }>(({
  theme, isOpen,
}) => {
  const { palette } = theme
  return css`
    background-color: white;
    border: 2px solid ${palette.blue};
    box-shadow: 0 4px 8px rgba(0, 29, 98, 0.14);
    overflow: hidden;
    border-radius: 0.25rem;
    position: absolute;
    left: -2px;
    top: 2.5rem;
    z-index: 1;
    margin-bottom: 0.5rem;
    width:100%;

    ${isOpen && css`
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}
  `
})
const SelectWithIconWrapper = styled.div`
  display: flex !important;
  cursor: pointer;
  align-items: center;
  width: 100%;
  svg{
    margin-right: 10px;
  }

`

const ListItem = styled.li(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    padding: 10px;
    list-style: none;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_regular.fontSize};
    font-weight: ${typography.main.pt_15_regular.fontWeight};
    line-height: ${typography.main.pt_15_regular.lineHeight};

    :hover {
      background-color: ${palette.results.green.opaque};
    }
  `
})
const InputError = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.results.red.dark};
`
})
export const Select: React.FC<ISelectProps> = ({
  options, selected, onChange, width, label, additionalInfo, name, icon, placeholder, error,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = (value: string | {id: string, name: string }) => () => {
    setIsOpen(false)
    onChange(name, value)
  }

  return (
    <Wrapper width={width}>
      <InputHeader>
        <label htmlFor={name}>{label}</label>
        <span>{additionalInfo}</span>
      </InputHeader>
      <SelectWithIconWrapper>
        {icon}
        <StyledDropDown
          isOpen={isOpen}
          onBlur={() => {
            setIsOpen(false)
          }}
          onClick={toggling}
          tabIndex={0}
        >
          <SelectCaret active={isOpen} />
          {selected || <p className='placeholder'>{placeholder}</p>}
          {isOpen && (
            <DropDownListContainer isOpen={isOpen}>
              {options?.map((option) => (
                <ListItem
                  key={uuidv4()}
                  onClick={onOptionClicked(option)}
                >
                  {typeof option === 'string' ? t(`${option}`) : option.name}
                </ListItem>
              ))}
            </DropDownListContainer>
          )}
        </StyledDropDown>
      </SelectWithIconWrapper>
      <InputError>{error}</InputError>
    </Wrapper>

  )
}
