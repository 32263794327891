import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
// import { SearchButton } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Popup } from 'reactjs-popup'
import { RootState } from 'apps/placeme/src/redux/store'
import { AnalyticsContext } from '@dataplace.ai/features'
import { saveTiles } from '../../../slice/analysisSlice'
import { ReactComponent as Menu } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/menu.svg'
import { DeleteTilePopup } from '../../molecules'
import { ICombinedSectionTile } from '../../../slice/@types/ISectionTile'

const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    color: ${palette.black};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};

    @media (max-width: 1200px){
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
    }
  `
})

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1.25rem 0 1rem;
`

const TextButton = styled.button(({ theme }) => {
  const { typography } = theme
  return css`
    background-color: transparent;
    color: white;
    font-size: ${typography.tiny.pt_12_semibold.fontSize};
    font-weight: ${typography.tiny.pt_12_semibold.fontWeight};
    line-height: ${typography.tiny.pt_12_semibold.lineHeight};
    border: none;
    white-space: nowrap;
    text-align: start;
    margin-bottom: 10px;

    :hover {
      cursor: pointer;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  `
})
const MenuWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 1rem;
    position: absolute;
    z-index: 2;
    background: ${palette.black};
    box-shadow: ${shadows.hover.boxShadow};
    top: 2rem;
    border-radius: ${corners.default.borderRadius};
    right: 0;
    color: white;
    display: flex;
    flex-direction: column;
  `
})
const Button = styled.button(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.625rem;
    height: 1.625rem;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    position: relative;
    margin-right: 15px;

    > svg {
      width: 0.875rem;
    }
  `,
)
const Line = styled.div`
  width: 100%;
  height: 2px;
  border-bottom: 1px solid #484e5e;
  margin: 5px 0 15px 0;
`
interface ISearchBar {
  handleAllCategories: (shouldBeOpen: boolean) => void
  handleShowOnlyChosen: (dataSet: ICombinedSectionTile[]) => void
  dataFiltered: ICombinedSectionTile[]
}
export const SearchBar = ({
  handleAllCategories,
  handleShowOnlyChosen,
  dataFiltered,
}: ISearchBar): JSX.Element => {
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const {
    canBeSave, values,
  } = useSelector(
    (state: RootState) => state.analysis,
  )
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(saveTiles([]))
    handleAllCategories(false)
  }

  return (
    <Wrapper>
      <Title>{t('placeme.sidebar.title')}</Title>
      {/* <SearchButton /> */}
      <Popup
        closeOnDocumentClick
        nested
        on='click'
        position='top right'
        trigger={(
          <Button>
            <Menu />
          </Button>
        )}
      >
        {(close: () => void) => (
          <MenuWrapper>
            <TextButton
              onClick={() => {
                close()
                handleShowOnlyChosen(dataFiltered)
              }}
            >
              {t('placeme.sidebar.show_only_chosen')}
            </TextButton>
            <TextButton
              onClick={() => {
                close()
                handleAllCategories(true)
                analytics?.track('All Analysis Page Sidebar Categories Expanded')
              }}
            >
              {t('placeme.sidebar.expand_all')}
            </TextButton>
            <TextButton
              onClick={() => {
                close()
                handleAllCategories(false)
              }}
            >
              {t('placeme.sidebar.collapse_all')}
            </TextButton>
            {canBeSave && values.length
              ? (
                <>
                  <Line />
                  <DeleteTilePopup
                    close={close}
                    handleDelete={() => {
                      handleDelete()
                      close()
                    }}
                    trigger={(
                      <TextButton onClick={() => {}}>
                        {t('placeme.sidebar.clear_the_content')}
                      </TextButton>
                    )}
                  />
                </>
              )
              : null}
          </MenuWrapper>
        )}
      </Popup>
    </Wrapper>
  )
}
