/* eslint-disable max-lines,import/no-cycle */
import React from 'react'
import styled, { css } from 'styled-components'
import 'rc-slider/assets/index.css'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import {
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { BasicLayers, GeojsonLayers, WMSLayers } from '@dataplace.ai/ui-components/atoms/MapTile/@types/LayersTypes'
import moment from 'moment'
import { ReactComponent as MapIcon } from '../../../../assets/src/lib/icons/rangeIcons/map.svg'
import { IMapVisualizationModal } from './@types/IMapVisualizationModal'
import { TextButton } from '../../atoms/TextButton'

const { saveAs } = require('file-saver')
const domToImage = require('dom-to-image')

const Wrapper = styled.div(({ theme }) => {
  const { shadows } = theme
  return css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: ${shadows.hover.boxShadow};
    padding: 2rem;
    width: 85vw;
    height: 80vh;
  `
})
const TopWrapper = styled.div(({ theme }) => {
  const { palette } = theme

  return css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${palette.black};

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  `
})
const ChosenRangeWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.black};
    margin-left: 1.25rem;

    .value {
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};
      margin: 0 0 0 8px;

      span {
        margin-left: 0.25rem;
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        line-height: ${typography.main.pt_15_semibold.lineHeight};
      }
    }

    .location {
      font-size: ${typography.main.pt_15_semibold.fontSize};
      font-weight: ${typography.main.pt_15_semibold.fontWeight};
      line-height: ${typography.main.pt_15_semibold.lineHeight};
      margin-top: 6px;
      margin-left: 5px;
    }
  `
})
const MiddleWrapper = styled.div(
  () => css`
    width: 100%;
    margin-top: 23.5px;
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    border-radius: 0 0 12px 12px;
  `,
)

const StyledTextButton = styled(TextButton)(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    align-items: center;
    margin-right: 16px;

    svg {
      margin-right: 5px;
      path {
        fill: ${palette.blue};
      }
    }

    p {
      color: ${palette.blue};
      font-size: ${typography.small.pt_13_medium.fontSize};
      font-weight: ${typography.small.pt_13_medium.fontWeight};
      line-height: ${typography.small.pt_13_medium.lineHeight};
    }
  `
})
export const MapVisualizationModal = ({
  trigger, location, layers, isDisplayed, setIsDisplay, mapId, zoom, styleLayer, legend, style,
}: IMapVisualizationModal) :JSX.Element => {
  const { t } = useTranslation()
  const closeModal = () => setIsDisplay(false)
  const openModal = () => setIsDisplay(true)

  const downloadMap = async (mapId: string) => {
    const mapContainer = document.getElementById(mapId)
    await domToImage
      .toBlob(mapContainer)
      .then((blob: { size: number; type: string }) => {
        saveAs(blob, `placeme-map--${moment().format('DD-MM-YYYY--HH-mm-ss')}.png`)
      })
  }

  return (
    <Popup
      closeOnDocumentClick
      modal
      onClose={closeModal}
      onOpen={openModal}
      open={isDisplayed}
      overlayStyle={defaultTheme.overlay}
      trigger={trigger}
    >
      <Wrapper>
        <TopWrapper>
          <div className='left'>
            <ChosenRangeWrapper>
              <p className='location'>
                {location?.address}
              </p>
            </ChosenRangeWrapper>
          </div>
          <div className='right'>
            <StyledTextButton onClick={() => downloadMap(mapId)}>
              <MapIcon
                className='mark-on-the-map-icon'
              />
              <p className='mark-on-the-map'>{t('generic.download_map_png')}</p>
            </StyledTextButton>
            <TextButton
              onClick={closeModal}
              style={{
                marginRight: '1.82rem',
              }}
            >
              <p>{t('generic.cancel')}</p>
            </TextButton>
          </div>
        </TopWrapper>
        <MiddleWrapper>
          {legend}
          <MapTile
            dragging
            height='100%'
            layers={layers as (BasicLayers | GeojsonLayers | WMSLayers)[]}
            location={location}
            mapId={mapId}
            mapTypeDropdown={!styleLayer}
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            popupParagraph={location?.address}
            scroll
            style={style}
            styleLayer={styleLayer}
            width='100%'
            zoom={zoom}
            zoomControl
          />
        </MiddleWrapper>
      </Wrapper>
    </Popup>
  )
}
