import React from 'react'
import styled, { css } from 'styled-components'
import { IRange } from '@dataplace.ai/types'
import { useTranslation } from 'react-i18next'
import { polishPeriod } from '@dataplace.ai/functions/utils'
import { ReactComponent as OnFoot } from '../../../../assets/src/lib/icons/rangeIcons/onFoot.svg'
import { ReactComponent as Car } from '../../../../assets/src/lib/icons/rangeIcons/car.svg'
import { ReactComponent as RangeIcon } from '../../../../assets/src/lib/icons/rangeIcons/range.svg'
import { ReactComponent as Bike } from '../../../../assets/src/lib/icons/rangeIcons/bike.svg'

const Wrapper = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    align-items: center;
    color: ${palette.black};
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    > span {
      margin-left: 0.5rem;
    }
  `
})

export interface IRangeProps {
  range: IRange
}

export const Range = ({ range }: IRangeProps): JSX.Element => {
  const { t } = useTranslation()
  if (!range.id.includes('custom')) {
    switch (range.type) {
      case 'walk': {
        return (
          <Wrapper>
            <OnFoot
              height='23'
              width='23'
            />
            <span>{`${range.value} ${t(`placeme.range.minutes_by_foot${polishPeriod(range.value)}`)}`}</span>
          </Wrapper>
        )
      }
      case 'car': {
        return (
          <Wrapper>
            <Car
              height='23'
              width='23'
            />
            <span>{`${range.value} ${t(`placeme.range.minutesbycar${polishPeriod(range.value)}`)}`}</span>
          </Wrapper>
        )
      }
      case 'bike': {
        return (
          <Wrapper>
            <Bike
              height='23'
              width='23'
            />
            <span>{`${range.value} ${t(`placeme.range.minutesbybike${polishPeriod(range.value)}`)}`}</span>
          </Wrapper>
        )
      }
      case 'line': {
        return (
          <Wrapper>
            <RangeIcon
              height='23'
              width='23'
            />
            <span>
              {range.value && (range.value > 999
                ? `${range.value / 1000} km`
                : `${range.value} m`)}
            </span>
          </Wrapper>
        )
      }
      default: {
        return <Wrapper />
      }
    }
  } else {
    return (
      <Wrapper>
        <span>
          {t('placeme.range.custom_draw_range')}
        </span>
      </Wrapper>
    )
  }
}
