import React from 'react'
import styled, { css } from 'styled-components'
import { IGaugeChartProps } from '@dataplace.ai/ui-components/atoms/Charts/GaugeChart/@types/IGaugeChart.props'
import { Comparison } from '@dataplace.ai/ui-components/atoms'
import { mediaMax as media } from '@dataplace.ai/ui-components/themes'
import { ReactComponent as GaugeChartImage } from 'libs/shared/assets/src/lib/images/gaugeChart.svg'
import { ReactComponent as GaugeChartNoUnit } from 'libs/shared/assets/src/lib/images/half-doughnut.svg'
import { useTranslation } from 'react-i18next'

type DoughnutWrapperProps = {
  height: number
}
const DoughnutWrapper = styled.div<DoughnutWrapperProps>(({ height }) => css`
  height: ${height}px;
  margin: auto;
  margin-top: ${height / 2.8}px;

  ${media.mobile`
    transform: scale(0.75);
  `}

  > svg {
    margin: 0 auto;
    width: 100%;
  }
`)

const IndicatorHandle = styled.div(() => css`
  position: relative;
  width: 0;
  margin: 0 auto;
  bottom: 1rem;
`)

const Value = styled.div<{ value: number, height: number }>(({
  theme, value, height,
}) => {
  const {
    palette, typography,
  } = theme

  const rotationValue = value * 1.8
  const heightValue = height * 1.1

  const bottomValue = Math.sin(rotationValue * (Math.PI / 180)) * heightValue * 1.05
  const sideValue = Math.cos(rotationValue * (Math.PI / 180)) * heightValue * 1.05

  return css`
    display: flex;
    position: absolute;
    flex-direction: row;
    flex-wrap: nowrap;
    bottom: ${`${bottomValue}px`};
    animation: appear 2s;

    > span:first-child {
      color: ${palette.black};
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};
      margin-right: 0.5rem;
    }

    ${value < 50
    ? css`
      right: ${`${sideValue}px`};
    `
    : css`
      left: ${`${sideValue * -1}px`};
    `}

    @keyframes appear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

  `
})

const Indicator = styled.span<{ value: number, height: number }>(({
  value, height, theme,
}) => {
  const rotationValue = (value * 1.8) - 90
  const heightValue = `${height * 1.1}px`
  const bottom = `-${height * 0.55}px`

  const { palette } = theme
  return css`
    position: absolute;
    display: flex;
    width: 7px;
    height: ${heightValue};
    background-color: ${palette.light.white};
    bottom: ${bottom};
    left: 0;
    transform: ${`rotate(${rotationValue}deg) translateY(-50%)`};
    animation-name: rotation;
    animation-duration: 1s;

    > span:first-child {
      min-width: 3px;
      background-color: black;
      margin: 0 2px;
      position: relative;

      :after {
        content: '';
        min-width: 16px;
        height: 16px;
        background-color: ${palette.black};
        border-radius: 50%;
        transform: translateX(calc(-50% + 1px)) translateY(calc(-50%));
        display: flex;
      }

      :before {
        content: '';
        min-width: 16px;
        height: 16px;
        background-color: ${palette.black};
        border-radius: 50%;
        transform: translateX(calc(-50% + 1px)) translateY(calc(50%));
        display: flex;
        position: absolute;
        bottom: 0;
      }
    }

    > div:nth-child(2) {
        transform: ${`rotate(${rotationValue * -1}deg)`};
      top: -45px;
    }

    @keyframes rotation {
      from {
        transform: rotate(-90deg) translateY(-50%);
      }
      to {
        transform: ${`rotate(${rotationValue}) translateY(-50%)`};
      }
    }
  `
})

const FlexWrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: ${palette.black};

    > span:first-of-type {
      padding-left: 1rem;
    }

    > span:last-of-type {
      padding-right: 1rem;
    }
` })

const Legend = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    margin: 1rem auto;
    width: 75%;
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    color: ${palette.black};

    > span {
      max-width: 33%;
    }
` })

export function GaugeChart({
  value,
  height,
  comparison,
  unit,
  labels,
  legend,
  displayValue,
}: IGaugeChartProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <FlexWrapper>
        {labels && (
          <span>
            {labels[0].value}
            {' '}
            {t(`${labels[0].unit}`)}
          </span>
        )}
        <DoughnutWrapper height={height}>
          {unit ? <GaugeChartImage /> : <GaugeChartNoUnit />}
          <IndicatorHandle>
            <Value
              height={height}
              value={value || 0}
            >
              {displayValue
                ? (
                  <span>
                    {displayValue}
                  </span>
                )
                : (
                  <span>
                    {`${value ? parseFloat(value.toFixed(1)) : 0}${unit}`}
                  </span>
                ) }
              {comparison && (
                <Comparison
                  state={comparison.includes('-') ? 'negative' : 'positive'}
                  value={comparison}
                  withIcon
                />
              )}
            </Value>
            <Indicator
              height={height}
              value={value || 0}
            >
              {/* do not remove this span */}
              <span />
            </Indicator>
          </IndicatorHandle>
        </DoughnutWrapper>
        {labels && (
          <span>
            {labels[1].value}
            {' '}
            {t(`${labels[1].unit}`)}
          </span>
        )}
      </FlexWrapper>
      {legend && (
        <Legend>
          <span>
            {t(`${legend[0]}`)}
          </span>
          <span>
            {t(`${legend[1]}`)}
          </span>
        </Legend>
      )}
    </>
  )
}
