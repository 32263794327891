/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react'
import { BarChart, TileFormSection, TileFormSectionTitle, TileFormWrapper } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IVisitingHoursTileData } from './@types/IVisitingHoursTileData'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { useAppDispatch } from '../../../../../../../redux/hooks'

const HoursWrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border: 1px solid ${palette.light.darker};
    border-radius: ${corners.default.borderRadius};
    margin-bottom: 1rem;
  `
})

const Hours = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    color: ${palette.black};
    width: 40%;
    margin-right: 10%;

    > span:nth-child(1) {
      margin-bottom: 1rem;
      font-size: ${typography.tiny.pt_12_semibold_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_semibold_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_semibold_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_semibold_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_semibold_upper.textTransform};
    }

    > span:nth-child(2) {
      font-size: ${typography.big.pt_24_semibold.fontSize};
      font-weight: ${typography.big.pt_24_semibold.fontWeight};
      line-height: ${typography.big.pt_24_semibold.lineHeight};
    }
  `
})

export const VisitingHoursTile: React.FC<{data: IVisitingHoursTileData, isPdf?:boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        <TileFormSection>
          <HoursWrapper>
            <Hours>
              <span>{t('placeme.visiting_hours_tile.hours_1')}</span>
              <span>{`${data?.value?.visitingHours?.busyMorningHours?.start}:00 - ${data?.value?.visitingHours?.busyMorningHours?.end}:00`}</span>
            </Hours>
            <Hours>
              <span>{t('placeme.visiting_hours_tile.hours_2')}</span>
              <span>{`${data?.value?.visitingHours?.busyNightHours?.start}:00 - ${data?.value?.visitingHours?.busyNightHours?.end}:00`}</span>
            </Hours>
          </HoursWrapper>
          <TileFormSectionTitle>{t('placeme.visiting_hours_tile.section_title_1')}</TileFormSectionTitle>
          {/* <BarChartScrollContainer> */}
          {isPdf
            ? (
              <img
                alt='chart'
                src={`data:image/svg+xml;base64,{{ barchart_${tileId.replace(/-/g, '_')
                } }}`}
              />
            )
            : (
              <BarChart
                axisLabels={{
                  xAxis: t('placeme.visiting_hours.x_axis'),
                  yAxis: t('placeme.visiting_hours.y_axis'),
                }}
                beginsAtZero
                dataset={[
                  {
                    label: '%',
                    data: (data?.value?.visitingHours?.visitingHours || []).map(vh => Math.round(vh?.percent * 100)),
                  },
                ]}
                labels={(data?.value?.visitingHours?.visitingHours || []).map(vh =>
                  (vh?.hour === 0 ? '24:00' : `${vh?.hour.toString()}:00`))}
                valueType='percentages'
              />
            )}
          {/* </BarChartScrollContainer> */}
        </TileFormSection>
      </TileFormWrapper>
    )
}

export default VisitingHoursTile
