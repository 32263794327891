
import React, { useState } from 'react'
import styled from 'styled-components'
import { LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { RootState } from '../../../../../../../redux/store'
import { ILandUsePlan } from './@types/ILandUsePlan'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

export const LandUsePlanTile: React.FC<{data:ILandUsePlan}> = ({ data }) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  return !data || data?.loading
    ? (<Loader />)
    : (data?.value
      ? (
        <TileFormWrapper>
          <StyledTitleFormSectionSubTitle>
            <LinkWithIconCheckOnMap onClick={handleMapOpen} />
          </StyledTitleFormSectionSubTitle>
          <MapTile
            dragging
            height='500px'
            layers={[{
              id: data?.value?.options?.id,
              layer: data?.value,
            }]}
            location={value}
            mapId='example-map-data-tile'
            pinDisplayed
            popupHeading={`${t('generic.chosen_location')}:`}
            popupParagraph={value?.address}
            width='100%'
            zoom={11}
            zoomControl
          />
          {isMapDisplayed && (
            <MapVisualizationModal
              isDisplayed={isMapDisplayed}
              layers={[{
                id: data?.value?.options?.id,
                layer: data?.value,
              }]}
              location={value}
              mapId={`visualization-tile-${values?.find(c => c.id === 'visualization')?.tiles?.find(t => t.id === 'satellite')?.chosenRange?.catchmentId}`}
              setIsDisplay={setIsMapDisplayed}
              zoom={11}
            />
          )}
        </TileFormWrapper>
      )
      : (
        <TileFormWrapper
          style={{
            alignItems: 'center',
          }}
        >
          {t('status.info.generic.something_went_wrong')}
        </TileFormWrapper>
      )
    )
}

export default LandUsePlanTile
