// set cookie for getCookie method
export const setCookie = (key: unknown, value: unknown): void => {
  const domain = window.location.origin.includes('localhost') ? '' : ';domain=.dataplace.ai;SameSite=Lax;path=/;'
  document.cookie = `${key}=${JSON.stringify(value)}${domain}`
}

// setCookie for workspaceId
export const setCookieWithoutJSON = (key: unknown, value: unknown): void => {
  const domain = window.location.origin.includes('localhost') ? ';path=/;' : ';domain=.dataplace.ai;SameSite=Lax;path=/;'
  document.cookie = `${key}=${value}${domain}`
}

export const getCookie = (key: string): string[] | null => {
  const data = document?.cookie?.split('; ')?.find(row => row.startsWith(`${key}=`))
    ?.split('=')[1]
  return data ? JSON.parse(data) : null
}

export const getCookieWithoutJSON = (key: string): string | null => {
  const data = document?.cookie?.split('; ')?.find(row => row.startsWith(`${key}=`))
    ?.split('=')[1]
  return data || null
}
