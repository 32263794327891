import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { AuthContext } from '@dataplace.ai/features'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { config } from 'apps/placeme/src/config'
import { IEmployeesInRangeTileRowData } from './@types/IEmployeesInRangeTile'
import { dummy } from './dummy'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: grid;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin: 0.5rem 0 1rem;
  `
})

const Label = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    width: 50%;
    text-align: right;
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      margin-right: 0;
    }
  `
})

const Row = styled.span(({ theme }) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-radius: ${corners.default.borderRadius};
    background: ${palette.light.main};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 5px;

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
      text-align: right;
      margin: 0 1.25rem;
      width: 50%;
    }

    > span:first-child {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: normal;
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-left: 0;
      text-align: left;
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      > span {
        width: max-content;
      }
    }
  `
})

const Description = styled.p(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
 
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
    margin-bottom: 10px;
    color: ${palette.black};
    margin-bottom: 2rem;
  
`
})

const labels = [
  'placeme.employees_in_range_tile.table_label_1',
  'placeme.employees_in_range_tile.table_label_2',
]

export const EmployeesInRangeTile: React.FC<{ data: IEmployeesInRangeTileRowData, tileId: string }> = ({
  data, tileId,
}) => {
  // variables
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const { values } = useSelector((state: RootState) => state.analysis)

  // states
  const [token, setToken] = useState('')

  // functions
  const sumEmployees = () => {
    let sum = 0
    if (data?.value?.employeesInRange?.length) {
      data?.value?.employeesInRange?.forEach(value => { sum += value?.employees })
    }
    return sum
  }

  const getTileType = (id: string) => id.split('-')[0]

  const fetchData = useCallback(async () => {
    if (token.length) {
      const catchmentId = values?.find(c => c.id === 'surroundings')?.tiles?.find(t =>
        t.id === tileId)?.chosenRange?.catchmentId

      const body: {[key: string]: unknown} = {
        catchmentId,
      }

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]
      let saveData

      try {
        const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        saveData = {
          loading: false,
          error: e.message,

          // TODO change to null when endpoint is ready
          value: dummy,
        }
      }
      finally {
        dispatch(saveTileData('surroundings', tileId, saveData))
      }
    }
  }, [token])

  // hooks
  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (!data?.value) fetchData()
  }, [fetchData, data?.value])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        <Description>{t('placeme.employees_in_range_tile.description')}</Description>
        <Header>
          {labels.map((label) => (
            <Label key={label}>{t(label)}</Label>
          ))}
        </Header>
        <Row>
          <span><b>{t('placeme.employees_in_range_tile.number_of_employees')}</b></span>
          <span><b>{formatNumber(sumEmployees())}</b></span>
        </Row>
        {data?.value?.employeesInRange?.map((value) => (
          <Row key={value?.economicSector}>
            <span>{t(value?.economicSector)}</span>
            <span>{formatNumber(value?.employees)}</span>
          </Row>
        ))}
      </Wrapper>
    )
}
