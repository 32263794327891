export const formatDate = (date: string):string => {
  // creates new date object
  const d = new Date(date)
  // the getMonth() method returns the month of a date as a number from 0 to 11
  // to get the correct month, you must add 1
  // if d.getMonth()+1 gives number = 1 we want to add 0 before it -> 2021-08-03 instead of 2021-8-03
  const formattedDate = `${d.getFullYear()}-${(d.getMonth() + 1).toString().length > 1
    ? d.getMonth() + 1
    : `0${d.getMonth() + 1}`}-${d.getDate()}`
  return formattedDate // returns date in format yyyy-mm-dd
}

const fractionSigns = ','

const pattern = /(-?\d+)(\d{3})/
const parse = (number: string | number) => {
  let numberString = number.toString()
  while (pattern.test(numberString)) numberString = numberString.replace(pattern, '$1 $2')
  return numberString
}

// Format number 123456789.12345 to 123 456 789,12
export const formatNumber = (number: number | string, fractionDigits = 0): string | number => {
  const num = +number
  return typeof num === 'number' && !Number.isNaN(num)
    ? parse(num.toFixed(fractionDigits).replace('.', fractionSigns))
    : number
}

// a_b, a-b, A_B, A-B to aB
export const snakeToCamel = (str: string) =>
  str.toLowerCase().replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''))
