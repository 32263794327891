import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../../../redux/store'
import { SectionTile } from '../../molecules'
// comment for clients version
// import { DataSetUpdateInfoPageLink } from '../../atoms'

const Wrapper = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    height: calc(100% - 128px);
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > :first-child > div {
      border-top: none;
    }
  `
})

const NoItems = styled.div(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > img {
      width: 40%;
    }

    > div {
      width: 20%;
      margin-left: 2rem;

      > span:nth-of-type(1) {
        margin-bottom: 0.5rem;
        display: block;
        font-size: ${typography.main.pt_15_regular.fontSize};
        font-weight: ${typography.main.pt_15_regular.fontWeight};
        line-height: ${typography.main.pt_15_regular.lineHeight};
      }
  
      > span:nth-of-type(2) {
        display: block;
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeight};
      }
    }
  `
})

export const AnalysePageBody = ({ userId }:{userId: string}): JSX.Element => {
  // constants
  const { t } = useTranslation()
  const { values } = useSelector((state: RootState) => state.analysis)
  return (
    <Wrapper>
      {/* comment for clients version */}
      {/* <AskCircle /> */}
      {!!values.length && values.map((value) => (
        <SectionTile
          key={value.id}
          sectionTile={value}
          userId={userId}
        />
      ))}
      {/* comment for clients version */}
      {/* {!!values && <DataSetUpdateInfoPageLink />} */}
      {!values.length && (
        <NoItems>
          <img
            alt='noItems'
            src='assets/images/noItems2.svg'
          />
          <div>
            <span>{t('placeme.analyse_page.no_items')}</span>
            <span>{t('placeme.analyse_page.no_items_description')}</span>
          </div>
        </NoItems>
      )}
    </Wrapper>
  )
}
