import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  Comparison,
  // LinkWithIconCheckOnMap,
  TileFormRowWithData,
  TileFormSpan,
  TileFormWrapper,
  TitleFormSectionSubTitle,
  Tooltip,
  // MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { formatNumber, getAxios } from '@dataplace.ai/functions/utils'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { AuthContext } from '@dataplace.ai/features'
import { config } from 'apps/placeme/src/config'
import { IMaxRanges } from '@dataplace.ai/types'
import { RangeAndCategoryTile } from '../../../../molecules'
import { gravityModelTableData } from './data'
// import { CustomRangeModal } from '../../..'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { saveTileData } from '../../../../../slice/analysisSlice'
import { IGravityModelTileData } from './@types/IGravityModelTileData'

const StyledTileFormWrapper = styled(TileFormWrapper)<{
  color: 'light' | 'dark'
}>(({
  theme, color,
}) => {
  const { palette } = theme
  return css`
    background-color: ${color === 'dark'
    ? palette.light.main
    : palette.light.white};
  `
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)

// const MapWrapper = styled.div(
//   () => css`
//     margin: 1rem 0;
//     width: 100%;
//     height: 300px;
//   `,
// )

const CompetitionSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    display: flex;
    flex-direction: column;
    > .competitor {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
    }
  `
})

const ComparisonSpan = styled.span(
  () => css`
    display: flex !important;
    align-items: center;
    justify-content: flex-end;

    > span {
      margin-left: 1rem;
      margin-bottom: 0;
    }
  `,
)

const DescriptionWrapper = styled.div`
  padding: 15px;
  background: #FFFFFF;
  border: 1px dashed #E1E2E3;
  box-sizing: border-box;
  border-radius: 6px;
  margin-bottom: 15px;
`

const DescriptionList = styled.ul(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
  color: ${palette.dark.normal};
  font-size: ${typography.small.pt_13_regular.fontSize};
  font-weight: ${typography.small.pt_13_regular.fontWeight};
  line-height: ${typography.small.pt_13_regular.lineHeight};
  margin: 15px 0;
  margin-left: 12px;
`
})

interface IGravityModelTileProps {
  data: IGravityModelTileData
  isExtraPaid?:boolean
  tileId: string
  catchmentId?: string
  userId: string
  maxRanges: IMaxRanges
  isPdf?: boolean
}

export const GravityModelTile: React.FC<IGravityModelTileProps> = ({
  data, isExtraPaid, tileId, catchmentId, userId, maxRanges, isPdf,
}) => {
  // VARIABLES /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const dispatch = useAppDispatch()
  const { labels } = gravityModelTableData
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  // STATE /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [token, setToken] = useState('')
  const [val, setValue] = useState<ResourceWithId>({
    id: '',
    content: null,
  })
  // const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [accepted, setAccepted] = useState<boolean>(false)

  // FUNCTIONS /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleChange = (value: ResourceWithId) => {
    setValue(value)
  }

  // const handleMapOpen = () => {
  //   setIsMapDisplayed(!isMapDisplayed)
  // }

  const fetchData = useCallback(async () => {
    if (!val?.id || !catchmentId) { return }
    const body = {
      categoryId: val?.id,
      catchmentId,
    }
    let saveData
    try {
      const response = await getAxios(config.API_URL, token).post<ITileData>(ENDPOINTS.GRAVITY_MODEL_TILE, body)
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      saveData = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    dispatch(saveTileData('potential', tileId, saveData))
  }, [accepted, catchmentId, val?.id])

  // USE_EFFECT ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // JSX ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const content = useMemo(() => data?.value?.competition?.map((val, index) => {
    if (!val) { return [] }
    let count = index
    const getKey = () => {
      count += 1
      return `${val.pointId}_${count}`
    }
    const fixedPercentage = +((val.percent * 100).toFixed(1))
    return [
      <CompetitionSpan key={getKey()}>
        <span className='competitor'>
          {`${val.name}, ${val.address}`}
        </span>
      </CompetitionSpan>,
      <ComparisonSpan key={getKey()}>{formatNumber(val.currentClients)}</ComparisonSpan>,
      <ComparisonSpan key={getKey()}>
        {formatNumber(val.clientsAfterNewSpot)}
        <Tooltip
          content={(
            <span>
              {`${t(`placeme.gravity_model_tile.comparison_tooltip.part_1.${fixedPercentage < 0
                ? 'negative'
                : 'positive'}`)} ${Math.abs(fixedPercentage)}% ${t('placeme.gravity_model_tile.comparison_tooltip.part_2')}`}
            </span>
          )}
          maxWidth='25rem'
          position='left bottom'
        >
          <Comparison
            blackArrow
            state={fixedPercentage < 0 ? 'negative' : 'positive'}
            value={`${Math.abs(fixedPercentage)}%`}
            withIcon={false}
          />
        </Tooltip>
      </ComparisonSpan>,
    ]
  }), [data])

  return (
    <>
      {/* {isMapDisplayed && (
        <CustomRangeModal
          handleClose={handleMapOpen}
          onlyMap
          tile={tileId}
        />
      )} */}
      <StyledTileFormWrapper color={data?.value ? 'light' : 'dark'}>
        {!accepted && !data?.value && !isPdf && (
          <>
            <RangeAndCategoryTile
              accepted={accepted}
              category='potential'
              categoryValue={val}
              handleCategoryChange={handleChange}
              isExtraPaid={isExtraPaid}
              maxRanges={maxRanges}
              setAccepted={setAccepted}
              tile={tileId}
              userId={userId}
            />
          </>
        )}
        {data?.value && (
          <>
            <DescriptionWrapper>
              <TileFormSpan>
                {t('placeme.gravity_model_tile.desc_1')}
              </TileFormSpan>
              <DescriptionList>
                <li>
                  {t('placeme.gravity_model_tile.li_1')}
                </li>
                <li>
                  {t('placeme.gravity_model_tile.li_2')}
                </li>
                <li>
                  {t('placeme.gravity_model_tile.li_3')}
                </li>
              </DescriptionList>
              <TileFormSpan>
                {t('placeme.gravity_model_tile.desc_2')}
              </TileFormSpan>
            </DescriptionWrapper>
            <TileFormRowWithData>
              <span>{t('placeme.gravity_model_tile.row_with_data_2_span_1')}</span>
              <span>{formatNumber(data?.value?.probability?.estimatedNumberOfClients)}</span>
            </TileFormRowWithData>
            <TileFormSpan>
              {t('placeme.gravity_model_tile.form_span_1')}
            </TileFormSpan>
            <TileFormRowWithData>
              <span>{t('placeme.gravity_model_tile.row_with_data_3_span_1')}</span>
              <span>{`${+((data?.value?.probability?.choiceProbability * 100).toFixed(1))}%`}</span>
            </TileFormRowWithData>
            <TileFormSpan>
              {t('placeme.gravity_model_tile.form_span_2')}
            </TileFormSpan>
            <StyledTitleFormSectionSubTitle>
              {t('placeme.gravity_model_tile.section_sub_title_1')}
            </StyledTitleFormSectionSubTitle>
            {!isPdf
              ? (
                <Table
                  content={content}
                  gap='1rem'
                  headerTemplate='3fr 1fr 1fr'
                  // headerTextAlign='left'
                  labels={labels.map((label) => (
                    <span key={label}>{t(label)}</span>
                  ))}
                  rowTemplate='3fr 1fr 1fr'
                />
              )
              : (
                <div>
                  {`{{table_${tileId.replace(/-/g, '_')} | safe}}`}
                </div>
              )}
            {/* <StyledTitleFormSectionSubTitle>
              <span>
                {t('placeme.gravity_model_tile.section_sub_title_1_span_1')}
              </span>
              <LinkWithIconCheckOnMap onClick={handleMapOpen} />
            </StyledTitleFormSectionSubTitle> */}
            {/* <MapWrapper>
              <MapTile
                dragging
                height='100%'
                layers={[{
                  id: data?.value?.gravityMap?.catchmentId,
                  layer: {
                    data: data?.value?.gravityMap?.geoJson,
                    options: {
                      type:'geojson',
                      id: data?.value?.gravityMap?.catchmentId,
                      style: {
                        color: '#0000a2',
                        fillColor: '#0000a2',
                        weight: 0,
                        fillOpacity: 0.3,
                      },
                    },
                  },
                }]}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                scroll
                width='100%'
              />
            </MapWrapper> */}
          </>
        )}
      </StyledTileFormWrapper>
    </>
  )
}
