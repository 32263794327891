/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button,
  IconButton,
  // LinkWithIconDelete,
  // LinkWithIconEdit,
  // LinkWithIconPreview,
  // Tag,
  CreditsCounter,
} from '@dataplace.ai/ui-components/atoms'
import { MiniMapTile } from '@dataplace.ai/ui-components/atoms/MiniMapTile/MiniMapTile'
import { useHistory } from 'react-router'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { resetLocationState } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { AuthContext } from '@dataplace.ai/features/components/AuthContext'
// import { getBoundingClientObj } from 'react-select/dist/declarations/src/utils'
import { LanguageSelector } from '@dataplace.ai/ui-components/molecules'
import { ReactComponent as Download } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/download.svg'
import { RootState } from '../../../../../redux/store'
import { ExitFromAnalysePopup, SaveAnalyseModal } from '../../molecules'
import {
  resetAnalysisState,
} from '../../../slice/analysisSlice'
import { PATHS } from '../../../../../constants/paths'
import { DownloadAnalyseModal } from '../../molecules/DownloadAnalyseModal/DownloadAnalyseModal'
import { tilesList } from '../../../utils/tilesPdfList'

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    height: 8rem;
    width: 100%;
    border-bottom: 1px solid ${palette.light.darker};
    padding: 1rem 2.5rem;
    box-sizing: border-box;
    background-color: white;
    z-index: 500;
    transition: all 1s;

    @media (max-width: 1200px) {
      padding: 1rem 1.5rem;
    }

    @media (max-width: 960px) {
      align-items: flex-start;
      overflow: hidden;
    }

    > div:first-child {
      @media (max-width: 960px) {
        margin-top: 0.25rem;
      }
    }

    > div:last-child {
      @media (max-width: 960px) {
        margin-top: 1.25rem;
      }
    }
  `
})

const LocationWrapper = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: column;
    margin: 1.25rem;
    flex-grow: 1;

    @media (max-width: 960px) {
      align-items: start;
      margin: 0 1.25rem;
      overflow-y: scroll;
      height: 100%;
    }

    > span {
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      margin: 0.25rem 0;
    }

    > div:nth-of-type(1) > span {
      width: fit-content;
      max-width: 80%;
      margin-right: 0.25rem;
      color: ${palette.black};
      font-size: ${typography.big.pt_22_semibold.fontSize};
      font-weight: ${typography.big.pt_22_semibold.fontWeight};
      line-height: ${typography.big.pt_22_semibold.lineHeight};

      @media (max-width: 1200px) {
        font-size: ${typography.big.pt_18_semibold.fontSize};
        font-weight: ${typography.big.pt_18_semibold.fontWeight};
        line-height: ${typography.big.pt_18_semibold.lineHeight};
      }

      @media (max-width: 960px) {
        font-size: ${typography.main.pt_15_semibold.fontSize};
        font-weight: ${typography.main.pt_15_semibold.fontWeight};
        max-width: 100%;
      }
    }
  `
})

const ActionsWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;

    > button {
      margin: 0 0.25rem;
    }
  `,
)

// const Compare = styled.div(({ theme }) => {
//   const {
//     palette, typography,
//   } = theme
//   return css`
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//
//     > span {
//       margin-right: 0.25rem;
//       white-space: nowrap;
//       color: ${palette.dark.normal};
//       font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
//       font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
//       line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
//       letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
//       text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
//     }
//
//     > button {
//       margin: 0 0.25rem;
//     }
//   `
// })

// const ComparedLocation = styled.span(({ theme }) => {
//   const { typography } = theme
//   return css`
//     font-size: ${typography.small.pt_13_regular.fontSize};
//     font-weight: ${typography.small.pt_13_regular.fontWeight};
//     line-height: ${typography.small.pt_13_regular.lineHeight};
//   `
// })

const CreditsCounterWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 2.8vw;
  z-index: 600;
  transition: all 1s;
`

const DownloadButton = styled.button(({ theme }) => {
  const {
    palette, corners, typography,
  } = theme
  return css`
    border: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: ${corners.default.borderRadius};
    outline: none;
    background-color: transparent;
    color: ${palette.blue};
    cursor: pointer;
    font-size: ${typography.small.pt_13_medium.fontSize};
    font-weight: ${typography.small.pt_13_medium.fontWeight};
    line-height: ${typography.small.pt_13_medium.lineHeight};

    :hover {
      background-color: ${palette.light.darker};
    }

    :disabled {
      opacity: 0.5;
      cursor: default;
    }

    > span {
      margin-left: 0.25rem;
    }
  `
})

export const AnalysePageHeader = (): JSX.Element => {
  // constants
  const {
    analysisDbInfo, creditsAmount, canBeSave, values, plans,
  } = useSelector((state: RootState) => state.analysis)
  const {
    value, currentSubscriptionData, analyseId,
  } = useSelector((state: RootState) => state.location)
  const address = value?.address || ''
  const navigationRef = useRef<HTMLDivElement>(null)
  const counterRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const authContext = useContext(AuthContext)

  // we need ref to get current state in event listener
  const [token, _setToken] = useState('')
  const tokenRef = React.useRef(token)
  const setToken = (newTokenRef:string) => {
    tokenRef.current = newTokenRef
    _setToken(newTokenRef)
  }
  // functions
  const checkIfAnyTileSave = () => {
    let isAnyTileSave = false
    if (values) {
      values.forEach(cat => {
        cat.tiles.forEach(tile => {
          if (tile.data?.value && tilesList.some(key => new RegExp(`${key}.*`, 'g').test(tile.id))) {
            isAnyTileSave = true
          }
          return isAnyTileSave
        })
      })
    }
    return isAnyTileSave
  }

  const handleDelete = () => {

  }

  const getMaxNumber = () => {
    if (typeof currentSubscriptionData?.value?.credits === 'number'
      && typeof currentSubscriptionData?.value?.usage === 'number') {
      return currentSubscriptionData?.value?.usage + currentSubscriptionData?.value?.credits
    }
    return currentSubscriptionData?.value?.credits
  }

  // hooks
  useEffect(() => {
    // fetches user token id from authContext
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    let prevScrollPos = window.pageYOffset
    window.onscroll = function () {
      const currentScrollPos = window.pageYOffset
      if (
        currentScrollPos > 100
        && navigationRef.current
        && counterRef.current
      ) {
        if (prevScrollPos > currentScrollPos) {
          navigationRef.current.style.top = '0'
          navigationRef.current.style.opacity = '1'
          counterRef.current.style.top = '0'
          counterRef.current.style.opacity = '1'
        } else {
          navigationRef.current.style.top = '-8rem'
          navigationRef.current.style.opacity = '0'
          counterRef.current.style.top = '-8rem'
          counterRef.current.style.opacity = '0'
        }
      }
      prevScrollPos = currentScrollPos
    }
    if (prevScrollPos === 0
      && navigationRef.current
      && counterRef.current) {
      navigationRef.current.style.top = '0'
      navigationRef.current.style.opacity = '1'
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [])

  useEffect(() => {
    if (counterRef.current) {
      counterRef.current.style.top = '0'
      counterRef.current.style.opacity = '1'
    }
  }, [creditsAmount])

  return (
    <>
      <CreditsCounterWrapper ref={counterRef}>
        <CreditsCounter
          currentPlan={currentSubscriptionData?.value?.planExact || ''}
          maxNumber={getMaxNumber()}
          number={currentSubscriptionData?.value?.credits || 0}
          plans={plans}
        />
      </CreditsCounterWrapper>

      <Wrapper ref={navigationRef}>
        <MiniMapTile
          location={value}
          mapId='currentLocation'
        />
        <LocationWrapper>
          <span>{t('placeme.page_header.location_analyse')}</span>
          <div>
            <span>{address}</span>

            {checkIfAnyTileSave()
              ? (
                <ExitFromAnalysePopup
                  close={() => {}}
                  handleDelete={() => {
                    const isProjectSaved = analysisDbInfo?.visible
                    dispatch(resetAnalysisState())
                    dispatch(resetLocationState())
                    setTimeout(() => history.push(isProjectSaved ? `/${PATHS.ANALYSE_REPORTS}` : `/${PATHS.CHOOSE_LOCATION}`), 200)
                  }}
                  trigger={
                    !analysisDbInfo?.visible
                      ? (
                        <IconButton
                          iconAlt='edit'
                          iconSrc='assets/icons/dataplace/edit.svg'
                        />
                      )
                      : <></>
                  }
                />
              )
              : canBeSave && (
                <IconButton
                  iconAlt='edit'
                  iconSrc='assets/icons/dataplace/edit.svg'
                  onClick={() => {
                    const isProjectSaved = analysisDbInfo?.visible
                    dispatch(resetAnalysisState())
                    dispatch(resetLocationState())
                    setTimeout(() => history.push(isProjectSaved ? `/${PATHS.ANALYSE_REPORTS}` : `/${PATHS.CHOOSE_LOCATION}`), 200)
                  }}
                />
              )}
          </div>
          {/* switch off for mvp */}
          {/* <Compare>
          <span>Porównujesz z </span>
          <Tag color='#EDF0F5'>
            <ComparedLocation>
              Reymonta 17, Gorzów Wielkopolski
            </ComparedLocation>
          </Tag>
          <LinkWithIconEdit />
          <LinkWithIconDelete onClick={() => {}} />
        </Compare> */}
        </LocationWrapper>
        <ActionsWrapper>
          {/* <LinkWithIconPreview /> */}
          <DownloadAnalyseModal
            analyseId={analyseId}
            trigger={(
              <DownloadButton
                disabled={!checkIfAnyTileSave()}
                type='button'
              >
                <Download />
                <span>{t('generic.download')}</span>
              </DownloadButton>
            )}
          />
          <SaveAnalyseModal
            analyseId={analyseId}
            handleDelete={handleDelete}
            trigger={(!analysisDbInfo?.visible ? <Button disabled={!canBeSave || !checkIfAnyTileSave()}>{t('generic.save_analyse')}</Button> : <></>)}
          />
          <LanguageSelector
            noArrow
            noBorder
            transparent
          />
        </ActionsWrapper>
      </Wrapper>
    </>
  )
}
