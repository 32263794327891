
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormSection,
  TileFormWrapper,
  TileFormRowWithData,
  TileFormParagraph, TitleFormSectionSubTitle,
  // Button,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { ILocation } from '@dataplace.ai/types'
import Geocoder from 'apps/placeme/src/services/Geocoder/Geocoder'
import { deleteResultAction, deleteTileAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { fetchSubscriptionInfo } from 'apps/placeme/src/features/ChooseLocationReport/chooseLocationSlice'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { LocalizationSelector, LocalizationSelectorWrapper, TileFooter } from '../../../../atoms'
import { ITileData } from '../../../../../slice/@types/ITileData'
import { ENDPOINTS } from '../../../../../../../constants/endpoints'
import { ICannibalizationTileData } from './@types/ICannibalizationTileData'
import { ChosenLocationCannibalizationTile, ChosenLocationCannibalizationTilePDF } from '../../../../molecules'
import { RootState } from '../../../../../../../redux/store'

const StyledTileFormWrapper = styled(TileFormWrapper)(({ theme }) => {
  const { palette } = theme
  return css`
    background-color: ${palette.light.main};
  `
})

const NormalSpan = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};
  `
})

export const CannibalizationTile:
React.FC<{data: ICannibalizationTileData, tileId: string, isExtraPaid?: boolean, isPdf?: boolean, locationPDF?: {
  address: string,
  lat: number,
  lng: number,
}}> = ({
  data, tileId, isExtraPaid, isPdf, locationPDF,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [localizations, setLocalizations] = useState<ILocation[]>([])
  const [accepted, setAccepted] = useState<boolean>(false)
  // const [loadedLocations, setLoadedLocations] = useState<ICannibalizationRow[]>([])
  const [currentlyLocalizations, setCurrentlyLocalizations] = useState<ILocation[]>([])
  const [localizationsLoading, setLocalizationsLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [loading, setLoading] = useState(false)
  // const [addingMoreLocations, setAddingMoreLocations] = useState(true)
  const {
    value, analyseId, currentSubscriptionData,
  } = useSelector((state: RootState) => state.location)
  const {
    values, creditsAmount, canBeSave,
  } = useSelector((state: RootState) => state.analysis)
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  // useEffect(() => {
  //   if (data?.value) {
  //     setLoadedLocations(data.value)
  //     setLoading(false)
  //   }
  // }, [data])

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    if (authContext.userData?.user?.uid) setUserId(authContext.userData?.user?.uid)
  }, [authContext])

  const fetchTileRange = () => values.find(cat => cat.id === 'market')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async () => {
    const catchmentId = values?.find(c => c.id === 'market')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange?.catchmentId
    if (accepted) {
      const body = {
        catchmentId,
        addresses: localizations?.map(l => ({
          id: l.placeId,
          address: l.address,
          lat: l.lat,
          lng: l.lng,
        })),
      }

      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post<ITileData>(ENDPOINTS.CANNIBALIZATION_TILE, body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      finally {
        setLoading(false)
        dispatch(saveTileData('market', tileId, saveData))
        dispatch(fetchSubscriptionInfo(token, currentSubscriptionData?.value?.subscriptionId || ''))
      }
    }
  }, [accepted, token, currentSubscriptionData])

  const fetchCurrentlyUsedAddresses = useCallback(async () => {
    let saveData
    try {
      const response = await getAxios(config.API_URL, token).get(
        `/user/${userId}/catchments?workspace_id=${window?.localStorage?.workspaceId}&filter=coordinates`,
      )
      saveData = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      saveData = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    finally {
      if (saveData?.value?.length) {
        const localizations : ILocation[] = []
        saveData?.value?.forEach((loc: { lat: number; lng: number }) => {
          if (typeof loc?.lat === 'number' && typeof loc?.lng === 'number') {
            const geocoder = new Geocoder()
            let address = ''
            geocoder.reverseGeocode(loc?.lat, loc?.lng).then(async (result) => {
              // eslint-disable-next-line camelcase
              const res = result as { formatted_address: string }[]
              if (result && res.length > 0) {
                address = res[0].formatted_address

                localizations.push({
                  placeId: address + loc?.lat + loc?.lng,
                  address,
                  lat: loc?.lat,
                  lng: loc?.lng,
                })
              }
            })
          }
        })

        setCurrentlyLocalizations(localizations)
        setLocalizationsLoading(false)
      }
    }
  }, [accepted, token, userId, window?.localStorage?.workspaceId])

  useEffect(() => {
    if (!data?.value?.length) fetchData()
  }, [fetchData, data?.value?.length])

  useEffect(() => {
    if (token && userId && window?.localStorage?.workspaceId) setLocalizationsLoading(true)
  }, [token, userId, window?.localStorage?.workspaceId])

  useEffect(() => {
    if (localizationsLoading) {
      fetchCurrentlyUsedAddresses()
    }
  }, [localizationsLoading])

  useEffect(() => {
    if (fetchTileRange()) {
      setAccepted(true)
    }
  }, [fetchTileRange()])

  const handleSave = () => {
    setLoading(true)
    dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'market', tileId, {
      id: `${tileId}-250-line`,
      value: 250,
      type: 'line',
    }))
  }

  const handleSubmit = () => {
    // setAddingMoreLocations(false)
    if (creditsAmount) {
      const chosenRange = values?.find(c => c.id === 'market')?.tiles?.find(t =>
        t.id === tileId)?.chosenRange
      analytics?.track('Tile Data Generated', {
        location: {
          ...value,
        },
        tile: tileId?.split('-')[0],
        range: {
          type: chosenRange?.type,
          length: chosenRange?.value,
        },
      })
      handleSave()
    }
  }

  const handleDelete = async (locationId : string) => {
    const catchmentId = fetchTileRange()
    if (token && analyseId && catchmentId) {
      setLoading(true)
      dispatch(deleteResultAction(token, analyseId, catchmentId, tileId.split('-')[0], 'market', locationId, tileId))
    }
  }

  const handleDeleteTile = () => {
    const chosenRange = values?.find(c => c.id === 'market')?.tiles?.find(t =>
      t.id === tileId)?.chosenRange
    analytics?.track('Tile Cancel Button Clicked', {
      location: {
        ...value,
      },
      tile: tileId?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction('market', tileId))
  }

  return (
    <>
      { loading
        ? <Loader />
        : (
          <StyledTileFormWrapper>
            <TileFormSection>
              <TileFormRowWithData>
                <span>{t('placeme.cannibalization_tile.row_with_data_1_span_1')}</span>
              </TileFormRowWithData>
              <TileFormParagraph>
                {!isPdf
                  ? `${t('placeme.cannibalization_tile.paragraph_1')} ${value?.address}, ${t('placeme.cannibalization_tile.paragraph_2')}`
                  : `${t('placeme.cannibalization_tile.paragraph_1')} ${locationPDF?.address}, ${t('placeme.cannibalization_tile.paragraph_2')}`}
              </TileFormParagraph>

              {(!!data?.value.length) && (
                <>
                  {data?.value.map((localization) => (
                    !isPdf
                      ? (
                        <ChosenLocationCannibalizationTile
                          key={localization.address}
                          canBeSave={canBeSave}
                          chosenLocalization={localization}
                          handleDelete={handleDelete}
                          isPdf={isPdf}
                        />
                      )
                      : (
                        <ChosenLocationCannibalizationTilePDF
                          key={localization.address}
                          canBeSave={canBeSave}
                          chosenLocalization={localization}
                          handleDelete={handleDelete}
                          isPdf={isPdf}
                          locationPDF={locationPDF}
                        />
                      )

                  ))}
                </>
              )}

              {/* {(!data?.value?.length || addingMoreLocations) && ( */}
              {(!data?.value.length) && (
                <>
                  {!data?.value.length
            && <TitleFormSectionSubTitle>{t('placeme.cannibalization_tile.localization_selector_title')}</TitleFormSectionSubTitle>}
                  <LocalizationSelectorWrapper>

                    {!!data?.value.length
              && <NormalSpan>{t('placeme.cannibalization_tile.localization_selector_has_tems')}</NormalSpan>}
                    {!data?.value.length
              && <NormalSpan>{t('placeme.cannibalization_tile.localization_selector_no_tems')}</NormalSpan>}
                    <LocalizationSelector
                      instantAdd
                      localizations={currentlyLocalizations}
                      localizationsLoading={localizationsLoading}
                      multiple
                      onChange={setLocalizations}
                      tile={tileId}
                    />

                  </LocalizationSelectorWrapper>
                </>
              )}

              {/* {(!addingMoreLocations && data?.value?.length) && (
                <AddAnotherRange>
                  <div>
                    <span>{t('placeme.localization_selector.add_another_point_title')}</span>
                    <span>{t('placeme.localization_selector.add_another_point_description')}</span>
                  </div>

                  <Button onClick={() => setAddingMoreLocations(true)}>
                    {t('generic.add')}
                  </Button>
                </AddAnotherRange>
              )} */}

            </TileFormSection>

            {/* {(!data?.value?.length || addingMoreLocations) && ( */}
            {(!data?.value.length) && (
              <TileFooter
                disabled={!localizations?.length}
                isExtraPaid={isExtraPaid}
                isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
                label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
                onAccept={handleSubmit}
                onCancel={handleDeleteTile}
                tile='cannibalization'
                value={localizations.length.toString()}
              />
            )}

          </StyledTileFormWrapper>
        ) }
    </>
  )
}
