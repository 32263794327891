
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import {
  LinkWithIconCheckOnMap,
  LinkWithIconAddNote,
  LinkWithIconDelete,
  LinkWithIconEdit,
  Range,
  Divider,
} from '@dataplace.ai/ui-components/atoms'
import { IRange } from '@dataplace.ai/types'
import { Popup } from 'reactjs-popup'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'
import { RootState } from 'apps/placeme/src/redux/store'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { mediaMax as media, useCheckForMobileDevice } from '@dataplace.ai/ui-components/themes'
import { AnalyticsContext } from '@dataplace.ai/features'
import { NotePopup } from '../NotePopup'
import { DeleteTilePopup } from '../DeleteTilePopup'
import { deleteTileAction } from '../../../slice/analysisSlice'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { ITile } from '../../../slice/@types/ITile'
import { ReactComponent as Menu } from '../../../../../../../../libs/shared/assets/src/lib/icons/dataplace/menu.svg'

interface IWrapper {
  canBeSave: boolean
  isPdf?: boolean
}

const Wrapper = styled.span<IWrapper>(({
  theme, canBeSave, isPdf,
}) => {
  const { palette } = theme
  return css`
    padding: 1rem;
    display: flex;
    background-color: ${palette.light.white};
    border-bottom: 1px solid ${palette.light.darker};
    align-items: center;

    > span:nth-child(2) {
      margin: 0 1rem;
    }

    > :last-child {
      margin-left: ${(canBeSave && !isPdf) ? 'auto' : 'none'};
    }
  `
})

const Title = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    color: ${palette.black};
    padding-right: 1rem;
    border-right: 1px solid ${palette.light.darker};
    font-size: ${typography.big.pt_18_semibold.fontSize};
    font-weight: ${typography.big.pt_18_semibold.fontWeight};
    line-height: ${typography.big.pt_18_semibold.lineHeight};

    ${media.mobile`
      font-size: 16px;
    `}
  `
})

const Actions = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;

    > * {
      margin: 0 0.25rem;
    }
  `,
)

const MenuButton = styled.div(
  () => css`
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
)

const MenuWrapper = styled.div(({ theme }) => {
  const {
    palette, corners, shadows,
  } = theme
  return css`
    padding: 0.5rem;
    background-color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};

    > div > button {
      width: 100%;
    }
  `
})

export interface ITileHeaderProps {
  tile: ITile
  sectionTile: ISectionTile
  range?: IRange
  toggleChangeRangeModal?: () => void
  handleMap?: () => void
  dataLoaded: boolean
  isPdf?: boolean
}

export const TileHeader = ({
  range,
  toggleChangeRangeModal,
  sectionTile,
  tile,
  handleMap,
  dataLoaded,
  isPdf,
}: ITileHeaderProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { analytics } = useContext(AnalyticsContext)
  const { canChooseRange } = tile
  const { canBeSave } = useSelector((state: RootState) => state.analysis)
  const { value } = useSelector((state: RootState) => state.location)
  const handleDelete = () => {
    dispatch(deleteTileAction(sectionTile.id, tile.id))
    analytics?.track('Tile Deleted', {
      tile: tile?.id?.split('-')[0],
      range: {
        type: range?.type,
        value: range?.value,
      },
    })
  }
  const isMobileDevice = useCheckForMobileDevice()

  const tilesWithNoRange = ['transport', 'scoring_model_big_ben', 'look_alike_big_ben', 'satellite', 'cities_population', 'municipality_population', 'customer_origin', 'land_use_plan', 'access_range', 'potential_model']

  const checkIfPeopleTileForeign = () => {
    if (tile.id.includes('people') && value?.country !== 'PL') return t(`${tile.labelForeign}`)
    return t(`${tile.label}`)
  }

  return (
    <Wrapper
      canBeSave={canBeSave}
      id={tile.id}
      isPdf={isPdf}
    >
      <Title>{checkIfPeopleTileForeign()}</Title>
      {!tilesWithNoRange.includes(tile.id.split('-')[0]) && range && <Range range={range} />}
      {tile.id.split('-')[0] === 'cities_population' && (
        <Range
          range={{
            id: '50000_line',
            value: 50000,
            type: 'line',
          }}
        />
      )}
      {range && !isPdf && tile.id.split('-')[0] !== 'look_alike_big_ben' && (
        <>
          {canChooseRange && canBeSave && !isPdf && (
            <LinkWithIconEdit
              onClick={toggleChangeRangeModal}
              showLabel={!isMobileDevice}
            />
          )}
          {/* {tile.id.split('-')[0] === 'scoring_model_big_ben' && (
            <LinkWithIconEdit
              onClick={toggleChangeRangeModal}
              showLabel={!isMobileDevice}
            />
          )} */}
          { !tilesWithNoRange.includes(tile.id.split('-')[0]) && (
            <LinkWithIconCheckOnMap
              onClick={handleMap}
              showLabel={!isMobileDevice}
            />
          )}
        </>
      )}
      {canBeSave && !isPdf && (
        <Actions>
          <Popup
            closeOnDocumentClick
            nested
            on='click'
            position='bottom center'
            trigger={(
              <MenuButton>
                <Menu />
              </MenuButton>
            )}
          >
            {(close: () => void) => (
              <MenuWrapper>
                <NotePopup
                  close={close}
                  dataLoaded={dataLoaded}
                  maxLength={500}
                  sectionTile={sectionTile}
                  tile={tile}
                  trigger={<LinkWithIconAddNote focusStyle={false} />}
                />
                <Divider color={defaultTheme.palette.light.darker} />
                {dataLoaded
                  ? (
                    <DeleteTilePopup
                      close={close}
                      handleDelete={handleDelete}
                      trigger={(
                        <LinkWithIconDelete
                          focusStyle={false}
                          onClick={() => {}}
                        />
                      )}
                    />
                  )
                  : (
                    <LinkWithIconDelete
                      focusStyle={false}
                      onClick={handleDelete}
                    />
                  )}
              </MenuWrapper>
            )}
          </Popup>
        </Actions>
      )}
    </Wrapper>
  )
}
