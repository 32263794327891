import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChosenRangeExistPopup } from '..'
import { TileFooter } from '../../atoms'
import { ITypeRange, TypeRangeSelector } from '../TypeRangeSelector'

interface ITypeAndRangeTile {
  accepted: boolean,
  typeRanges: ITypeRange,
  setTypeRanges: React.Dispatch<React.SetStateAction<ITypeRange>>
  isWarningModalOpen: boolean
  toggleWarningModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  handleDeleteTile: () => void
  handleSubmit: () => void
  isExtraPaid: boolean
}

export const TypeAndRangeTile = ({
  accepted,
  typeRanges,
  setTypeRanges,
  isWarningModalOpen,
  toggleWarningModalOpen,
  handleDeleteTile,
  handleSubmit,
  isExtraPaid,
}: ITypeAndRangeTile): JSX.Element => {
  const { t } = useTranslation()

  const handleDisabled = () => {
    if (
      typeRanges?.cost
      && typeRanges?.area
      && (typeRanges?.roundTheClock === 1 || typeRanges?.roundTheClock === 0)
    ) {
      return false
    } return true
  }

  return (
    <>

      <>
        <h4>
          {t('placeme.sales_potential_tile.header')}
        </h4>
        <ChosenRangeExistPopup
          close={() => toggleWarningModalOpen}
          isOpen={isWarningModalOpen}
        />
        <TypeRangeSelector
          accepted={accepted}
          description={t('placeme.sales_potential_tile.type_range_description')}
          onChange={(value: ITypeRange) => setTypeRanges(value)}
        />
        <TileFooter
          disabled={handleDisabled()}
          isExtraPaid={isExtraPaid}
          label={t('generic.apply')}
          onAccept={handleSubmit}
          onCancel={handleDeleteTile}
          tile='potential_model'
          value='1'
        />
      </>

    </>
  )
}
