
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import {
  LinkWithIconCheckOnMap,
  TitleFormSectionSubTitle,
  TileFormWrapper,
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { RootState } from '../../../../../../../redux/store'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'
import { IPopulationHeatmap } from './@types/IPopulationHeatmap'

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

export const PopulationHeatmapTile: React.FC<{
  data: IPopulationHeatmap,
  isPdf?:boolean, tileId: string}> = ({ data }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  const [isMapDisplayed, setIsMapDisplayed] = useState(false)

  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  return !data || data?.loading
    ? (<Loader />)
    : (
      data?.value?.populationHeatmap
        ? (
          <>
            <TileFormWrapper>
              <TitleFormSectionSubTitle>
                <span>{t('placeme.people_tile.section_sub_title_2_span_1')}</span>
                <LinkWithIconCheckOnMap onClick={handleMapOpen} />
              </TitleFormSectionSubTitle>
              <MapWrapper>
                <MapTile
                  height='100%'
                  layers={data?.value?.populationHeatmap
                    ? [{
                      id: data?.value?.populationHeatmap?.id,
                      layer: {
                        ...data?.value?.populationHeatmap,
                        options: {
                          type: data?.value?.populationHeatmap?.type,
                          id: data?.value?.populationHeatmap?.id,
                          style: {
                            ...data?.value?.populationHeatmap?.style,
                            blur: 5,
                            radius: 90,
                            minOpacity: 0.07,
                            gradient: {
                              1:'#FF0000',
                              '0.0':'#0099FF',
                              0.3:'#FFFF99',
                              0.65:'#FF9933',
                            },
                          },

                        },
                      },
                    }]
                    : undefined}
                  location={value}
                  mapId='example-map-data-tile'
                  pinDisplayed
                  popupHeading={t('generic.chosen_location')}
                  popupParagraph={value?.address}
                  width='100%'
                  zoom={15}
                  zoomControl
                />
              </MapWrapper>
            </TileFormWrapper>

            {isMapDisplayed && (
              <MapVisualizationModal
                isDisplayed={isMapDisplayed}
                layers={data?.value?.populationHeatmap
                  ? [{
                    id: data?.value?.populationHeatmap?.id,
                    layer: {
                      ...data?.value?.populationHeatmap,
                      options: {
                        type: data?.value?.populationHeatmap?.type,
                        id: data?.value?.populationHeatmap?.id,
                        style: {
                          ...data?.value?.populationHeatmap?.style,
                          blur: 5,
                          radius: 90,
                          minOpacity: 0.07,
                          gradient: {
                            1:'#FF0000',
                            '0.0':'#0099FF',
                            0.3:'#FFFF99',
                            0.65:'#FF9933',
                          },
                        },

                      },
                    },
                  }]
                  : undefined}
                location={value}
                mapId={`people-tile-${values?.find(c => c.id === 'population')?.tiles?.find(t => t.id === 'people')?.chosenRange?.catchmentId}`}
                setIsDisplay={setIsMapDisplayed}
                zoom={15}
              />
            )}
          </>
        )
        : null

    ) }
