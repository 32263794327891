
import React, { useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IMapLocationProps, LinkWithIconCheckOnMap, Loader, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAxios } from '@dataplace.ai/functions/utils'
import { config } from 'apps/placeme/src/config'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { ITileData } from 'apps/placeme/src/features/Analyse/slice/@types/ITileData'
import { saveNewRangeAction, saveTileData } from 'apps/placeme/src/features/Analyse/slice/analysisSlice'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { AuthContext } from '@dataplace.ai/features'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { RootState } from '../../../../../../../redux/store'

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

export const SatelliteTile:
React.FC<{data: {loading: boolean, value: unknown, error: string}, tileId: string, isPdf?: boolean}> = ({
  data, tileId, isPdf,
}) => {
  // constants
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const { values } = useSelector((state: RootState) => state.analysis)
  const dispatch = useAppDispatch()

  const authContext = useContext(AuthContext)

  // states
  const [accepted, setAccepted] = useState(false)
  const [token, setToken] = useState('')
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [mapLocation, setMapLocation] = useState<IMapLocationProps>({
    zoom: 14,
    center: {
      lat: value?.lat || 0,
      lng: value?.lng || 0,
    },
  })
  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  useEffect(() => {
    dispatch(saveTileData('visualization', tileId, {
      ...data,
      mapLocation,
    }))
  }, [mapLocation])
  // functions
  const fetchTileRange = () => values.find(cat => cat.id === 'visualization')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId

  const fetchData = useCallback(async () => {
    const catchmentId = values.find(cat => cat.id === 'visualization')?.tiles.find(t => t.id === tileId)?.chosenRange?.catchmentId
    if (accepted)
    {
      const body = {
        catchmentId,
      }

      let saveData
      try {
        const response = await getAxios(config.API_URL, token).post<ITileData>(ENDPOINTS.SATELLITE_TILE, body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
          mapLocation,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      finally {
        dispatch(saveTileData('visualization', tileId, saveData))
      } }
  }, [token, accepted])

  // hooks

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  useEffect(() => {
    if (token && !fetchTileRange()) {
      dispatch(saveNewRangeAction(token, authContext.userData.user?.uid || '', 'visualization', tileId, {
        id: `${tileId}-250-line`,
        value: 250,
        type: 'line',
      })) }
  }, [token])

  useEffect(() => {
    if (fetchTileRange()) setAccepted(true)
  }, [fetchTileRange()])

  useEffect(() => {
    if (!data?.value) {
      fetchData()
    }
  }, [fetchData, data?.value])

  return (
    <>
      {!data || data?.loading
        ? (<Loader />)
        : (
          <TileFormWrapper>
            {!isPdf
              ? (
                <>
                  <StyledTitleFormSectionSubTitle>
                    <LinkWithIconCheckOnMap onClick={handleMapOpen} />
                  </StyledTitleFormSectionSubTitle>
                  <MapTile
                    dragging
                    height='500px'
                    layers={[]}
                    location={value}
                    mapId='example-map-data-tile'
                    pinDisplayed
                    popupHeading={`${t('generic.chosen_location')}:`}
                    popupParagraph={value?.address}
                    setMapLocation={setMapLocation}
                    style={{
                      id: 'satellite',
                      name: 'placeme.map_satellite_type',
                      style: 'ck72206dr091e1ipfj5zu6js1',
                    }}
                    width='100%'
                    zoom={14}
                    zoomControl
                  />
                  {isMapDisplayed && (
                    <MapVisualizationModal
                      isDisplayed={isMapDisplayed}
                      layers={[]}
                      location={value}
                      mapId='visualization-tile-cities_population'
                      setIsDisplay={setIsMapDisplayed}
                      style={{
                        id: 'satellite',
                        name: 'placeme.map_satellite_type',
                        style: 'ck72206dr091e1ipfj5zu6js1',
                      }}
                      zoom={14}
                    />
                  )}
                </>
              )
              : (
                <img
                  alt='map'
                  src={`data:image/png;base64,{{ satellite_${tileId.replace(/-/g, '_')
                  } }}`}
                  style={{
                    width: '80%',
                    margin: '50px auto 0 auto',
                    borderRadius: '8px',
                  }}
                />
              )}
          </TileFormWrapper>
        )}
    </>
  ) }

export default SatelliteTile
