/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { AddMapView, MapVisualizationModal, NestedSelect, Pagination } from '@dataplace.ai/ui-components/molecules'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  LinkWithIconCheckOnMap,
  // SimpleSelect,
  TitleFormSectionSubTitle,
  MapTile,
} from '@dataplace.ai/ui-components/atoms'
import { Table } from '@dataplace.ai/ui-components/organisms'
import { useTranslation } from 'react-i18next'
import { ResourceWithId } from '@dataplace.ai/ui-components/organisms/ResourcesSelector/@types/ResourceWithId'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { investmentsTableData } from './data'
import { RootState } from '../../../../../../../redux/store'
import { IInvestment, IInvestmentsTileData } from './@types/IInvestmentsTileData'

const { v4: uuidv4 } = require('uuid')

const Wrapper = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: column;
    padding: 1.25rem 1.5rem;
    background-color: ${palette.light.white};
  `
})
const TitleWithLegend = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin: 1rem 0 0;
    display: flex;
    flex-direction: row;
    color: ${palette.black};
    background-color: ${palette.light.white};
    font-size: ${typography.main.pt_15_semibold.fontSize};
    font-weight: ${typography.main.pt_15_semibold.fontWeight};
    line-height: ${typography.main.pt_15_semibold.lineHeight};

    > img{
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  `
})

const NestedSelectWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    > span {
      margin-right: 0.5rem;
    }
  `
})

// const SimpleSelectWrapper = styled.div(({ theme }) => {
//   const {
//     palette, typography,
//   } = theme
//   return css`
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-end;
//     align-items: center;
//     white-space: nowrap;
//     color: ${palette.blue};
//     font-size: ${typography.small.pt_13_regular.fontSize};
//     font-weight: ${typography.small.pt_13_regular.fontWeight};
//     line-height: ${typography.small.pt_13_regular.lineHeight};

//     > span {
//       color: ${palette.black};
//       margin-right: 0.5rem;
//     }
//   `
// })

const MapWrapper = styled.div(
  () => css`
    margin: 1rem 0;
    width: 100%;
    height: 300px;
  `,
)

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)(
  ({ theme }) => {
    const { palette } = theme
    return css`
      border-top: 1px solid ${palette.light.darker};
      padding-top: 2rem;
      margin-bottom: 0;
    `
  },
)

const AdditionalInfo = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    padding: 0.5rem;
    border-top: 1px solid ${palette.light.darker};
    flex-direction: row;
    flex-wrap: wrap;

    div {
      display: flex;
      flex-direction: column;
      margin: 0.5rem 0;
    }

    > div {
      width: 50%;
    }

    > div:last-child {
      width: 100%;
    }

    > div > span:first-child,
    > div > div > span:first-child {
      margin-bottom: 0.25rem;
      color: ${palette.dark.normal};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    > div > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      letter-spacing: ${typography.small.pt_13_semibold.letterSpacing};
      text-transform: ${typography.small.pt_13_semibold.textTransform};
    }

    > div > span {
      color: ${palette.black};
      font-size: ${typography.small.pt_13_regular.fontSize};
      font-weight: ${typography.small.pt_13_regular.fontWeight};
      line-height: ${typography.small.pt_13_regular.lineHeight};
      letter-spacing: ${typography.small.pt_13_regular.letterSpacing};
      text-transform: ${typography.small.pt_13_regular.textTransform};
    }
  `
})
const ObjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ScrollWrapper = styled.div`
  margin: 1rem 0;
  max-height: 650px;
  overflow-y: auto;
  overscroll-behavior: contain;

/* ::-webkit-scrollbar { */
  /* display: none;  /* Chrome, Safari and Opera */
/*} */

-ms-overflow-style: none;  /* IE and Edge */
  /* scrollbar-width: none;  Firefox */
  .ps{
    overflow: visible !important;
  }
`

interface ExpandableProps {
  investorName: string,
  legalStatus?: string,
  dateOfApplication: string,
  capacity: number | string,
  info: string
}

const Expandable: React.FC<ExpandableProps> = ({
  investorName, legalStatus, dateOfApplication, capacity, info,
}) => {
  const { t } = useTranslation()

  return (
    <AdditionalInfo>
      <div>
        <div>
          <span>{`${t('placeme.investments_tile.investor_name')}:`}</span>
          <span>
            {(typeof investorName === 'string' && (investorName.toLowerCase() === 'none'
          || investorName.toLowerCase() === 'nan'))
              ? '-'
              : investorName}

          </span>
        </div>
        { legalStatus && (
          <div>
            <span>{`${t('placeme.investments_tile.legal_status')}:`}</span>
            <span>{legalStatus}</span>
          </div>
        )}
        { !legalStatus && (
          <div>
            <span>{`${t('placeme.investments_tile.info')}:`}</span>
            <span>{info}</span>
          </div>
        )}
      </div>
      <div>
        <div>
          <span>{`${t('placeme.investments_tile.date_of_application')}:`}</span>
          <span>{dateOfApplication}</span>
        </div>
        <div>
          <span>{`${t('placeme.investments_tile.capacity')}:`}</span>
          <span>
            {(typeof capacity === 'string' && (capacity.toLowerCase() === 'none'
          || capacity.toLowerCase() === 'nan'))
              ? '-'
              : `${capacity} m2`}

          </span>
        </div>
      </div>
      <div>
        {legalStatus
        && (
          <>
            <span>{`${t('placeme.investments_tile.info')}:`}</span>
            <span>{info}</span>
          </>
        )}
      </div>
    </AdditionalInfo>
  )
}

export const getInvestmentIcon: (category: string, isPdf?: boolean) => string = (category, isPdf) => {
  switch (category) {
    case 'administration':
      if (!isPdf) { return 'assets/icons/placeme/administrationInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FadministrationInvestments.svg?alt=media&token=3d591cde-301a-45f8-95b5-ca22a6c7638e'
    case 'residential':
      if (!isPdf) { return 'assets/icons/placeme/residentialInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FresidentialInvestments.svg?alt=media&token=18502ea9-c627-4035-8ff1-075f48276b69'
    case 'retail_and_services':
      if (!isPdf) { return 'assets/icons/placeme/servicesInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FservicesInvestments.svg?alt=media&token=92793c07-1f92-49a8-a1d5-e882454648a8'
    case 'other':
      if (!isPdf) { return 'assets/icons/placeme/otherInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FotherInvestments.svg?alt=media&token=7ef9722a-31f9-44c5-8cee-04026da2f22f'
    case 'economic':
      if (!isPdf) { return 'assets/icons/placeme/economicInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FeconomicInvestments.svg?alt=media&token=244869eb-a3be-4a79-8172-b39ebf2165d2'
    case 'infrastructural':
      if (!isPdf) { return 'assets/icons/placeme/infrastructuralInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FinfrastructuralInvestments.svg?alt=media&token=05d4fa0e-c66d-44b4-a13d-c69fd6399aba'
    default:
      if (!isPdf) { return 'assets/icons/placeme/otherInvestments.svg' }
      return 'https://firebasestorage.googleapis.com/v0/b/dataplace-app.appspot.com/o/investment_icons%2FotherInvestments.svg?alt=media&token=7ef9722a-31f9-44c5-8cee-04026da2f22f'
  }
}

export const InvestmentsTile: React.FC<{
  data: IInvestmentsTileData,
  tileId: string
  isPdf?: boolean
}> = ({
  data,
  tileId,
  isPdf,
}) => {
  const { t } = useTranslation()
  const [val, setValue] = useState<ResourceWithId[]>([])
  // const [sort, setSort] = useState<string>(t('placeme.sort.distance_descending'))
  const [mapVisible, setMapVisible] = useState<boolean>(false)
  const [categories, setCategories] = useState<ResourceWithId[]>([])
  const { labels } = investmentsTableData

  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [valuesToDisplay, setValuesToDisplay] = useState<{[key: string]: IInvestment[]}>({})

  const { value } = useSelector((state: RootState) => state.location)
  const {
    values, canBeSave,
  } = useSelector((state: RootState) => state.analysis)

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const getLayers = (categories: string[]) => {
    if (data?.value) {
      const features : IFeatureCollection['data']['features'] = []

      data?.value?.investments?.forEach(invest => {
        if (categories.includes(invest?.category)) {
          features.push({
            geometry: {
              coordinates: [invest?.lng, invest?.lat],
              type: 'Point',
            },
            properties: {
              title: `<div style="display: flex; flex-direction: column;"><span>${handleTranslateForInvestName(invest?.name)}</span><span>${invest?.address}</span></div>`,
              pinnedItem: {
                class: 'poi-img',
                html: `<img alt='' src='${getInvestmentIcon(invest?.category, isPdf)}'/>`,
              },
            },
            type: 'Feature',
          }) } })

      return [{
        id: 'investment-layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'investments',
          },
        },
      }]
    }
    return undefined
  }

  const groupCategoriesInvestmentsPdf = () => {
    const rowsToDisplayInvPdf = JSON.parse(localStorage?.getItem('investmentsRowsToDisplay') || '{}') as {
      [key: string]: IInvestment[];
    }

    const investmentArray: JSX.Element[] = []

    Object.entries(rowsToDisplayInvPdf).map((entry) => (
      investmentArray.push(
        <TitleWithLegend>
          <img
            alt='investmentCategory'
            src={getInvestmentIcon(entry[0], isPdf)}
          />
          {t(`placeme.investments_tile.category.${entry[0]}`)}
        </TitleWithLegend>,
      )
      && entry[1].map((item) => investmentArray.push(
        <>

          <AdditionalInfo
            key={item.lat + item.lng}
          >
            <div>
              <div>
                <span>{`${t('placeme.investmentstile.tablelabel_1')}:`}</span>
                <span>
                  {(typeof item?.name === 'string' && (item?.name.toLowerCase() === 'none'
                || item?.name.toLowerCase() === 'nan'))
                    ? '-'
                    : handleTranslateForInvestName(item?.name)}

                </span>
              </div>
            </div>
            <div>
              <div>
                <span>{`${t('placeme.investmentstile.tablelabel_2')}:`}</span>
                <span>
                  {(typeof item?.address === 'string' && (item?.address.toLowerCase() === 'none'
                  || item?.address.toLowerCase() === 'nan'))
                    ? '-'
                    : item?.address}
                </span>
              </div>
              <div>
                <span>{`${t('placeme.investmentstile.tablelabel_3')}:`}</span>
                <span>
                  {item?.distanceFromPoint * 1000}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>{`${t('placeme.investments_tile.investor_name')}:`}</span>
                <span>
                  {(typeof item?.investorName === 'string' && (item?.investorName.toLowerCase() === 'none'
                  || item?.investorName.toLowerCase() === 'nan'))
                    ? '-'
                    : item?.investorName}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>{`${t('placeme.investments_tile.date_of_application')}:`}</span>
                <span>{item?.dateOfApplication}</span>
              </div>
              <div>
                <span>{`${t('placeme.investments_tile.capacity')}:`}</span>
                <span>
                  {(typeof item?.capacity === 'string' && (item?.capacity.toLowerCase() === 'none'
          || item?.capacity.toLowerCase() === 'nan'))
                    ? '-'
                    : `${item?.capacity} m2`}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>{`${t('placeme.investments_tile.info')}:`}</span>
                <span>{item?.info}</span>
              </div>
            </div>
          </AdditionalInfo>
        </>,
      ))
    ))

    return investmentArray
  }

  const handleAddMap = () => {
    setMapVisible(true)
  }

  const handleTranslateForInvestName = (name: string) => t(`placeme.investments_tile.name.${name.toLocaleLowerCase().split(/[_/ -]+/)
    .join('_')}`)

  const getCatchmentId = values?.find(c => c.id === 'surroundings')?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId

  useEffect(() => {
    if (data?.value) {
      const categoriesObjects : { id: string, content: string}[] = []
      data?.value?.investments?.forEach(investment => {
        const categoryId = investment?.category === 'nan' ? 'other' : investment?.category
        if (!categoriesObjects.find(cat => cat?.id === categoryId)) {
          categoriesObjects.push({
            id: categoryId,
            content: t(`placeme.investments_tile.category.${categoryId.split(' ').join('_')}`),
          })
        }
      })

      setCategories(categoriesObjects)
      setValue(categoriesObjects)
    }
  }, [data?.value])

  useEffect(() => {
    if (data?.value?.investments?.length) {
      const sortedValues: {[key: string]: IInvestment[]} = [...new Set(data?.value?.investments
        ?.filter(v => val.map(v => v.id)?.includes(v.category))
        ?.map(i => i.category))].reduce((acc, cur) => ({
        ...acc,
        [cur]: [],
      }), {})
      data?.value?.investments?.filter(v => val.map(v => v.id)?.includes(v.category))
        ?.sort((a, b) => (a.distanceFromPoint > b.distanceFromPoint ? -1 : 1))
        ?.forEach(v => sortedValues[v.category].push(v))

      setValuesToDisplay(sortedValues || [])
    }
  }, [val])

  useEffect(() => {
    window.localStorage.setItem('investmentsRowsToDisplay', JSON.stringify(valuesToDisplay))
  }, [valuesToDisplay])

  return !data?.value || data?.loading
    ? (<Loader />)
    : (
      <Wrapper>
        {!isPdf
        && (
          <NestedSelectWrapper>
            <span>{t('placeme.investments_tile.investment_categories')}</span>
            <NestedSelect
              name=''
              onChange={setValue}
              options={categories}
              selected={val}
              width='50%'
            />
          </NestedSelectWrapper>
        ) }
        {/* <SimpleSelectWrapper>
          <span>{t('generic.sort')}</span>
          <SimpleSelect
            name=''
            onChange={(_, value: string) => setSort(value)}
            options={[
              t('placeme.sort.distance_descending'),
              t('placeme.sort.distance_ascending'),
            ]}
            selected={sort}
            width='50%'
          />
        </SimpleSelectWrapper> */}

        {!isPdf
          ? Object.entries(valuesToDisplay).map((v) => (
            <ObjectWrapper key={uuidv4()}>
              <TitleWithLegend>
                <img
                  alt=''
                  src={getInvestmentIcon(v[0])}
                />
                {t(`placeme.investments_tile.category.${v[0].split(' ').join('_')}`)}
              </TitleWithLegend>
              <ScrollWrapper>
                <PerfectScrollbar
                  className='scroll'
                >

                  <Pagination
                    data={(v[1].map(val => [(val?.name.toLowerCase() === 'nan') ? '-' : handleTranslateForInvestName(val?.name),
                      val?.address, val?.distanceFromPoint * 1000,
                      val?.capacity, val?.dateOfApplication, val?.info, val?.investorName,
                      val?.legalStatus])) as string[][]}
                    dataLimit={8}
                    justifyNavigation='center'
                    renderComponent={(data) => {
                      const newData = data as string[]
                      return (
                        <Table
                          content={newData.map(d => [<span key={uuidv4()}>{d[0]}</span>,
                            <span key={uuidv4()}>{d[1]}</span>,
                            <span key={uuidv4()}>{`${d[2]} m`}</span>])}
                          expandableComponent={newData.map(item => (
                            <Expandable
                              key={uuidv4()}
                              capacity={item[3]}
                              dateOfApplication={item[4]}
                              info={item[5]}
                              investorName={item[6]}
                            // legalStatus={item[7]}
                            />
                          ))}
                          gap='1rem'
                          headerTemplate='5fr 3fr 4fr 1fr'
                          headerTextAlign='left'
                          labels={labels.map((label) => (
                            <span key={label}>{t(label)}</span>
                          ))}
                          rowTemplate='5fr 3fr 4fr 1fr'
                          whiteSpaces='wrap'
                        />
                      ) }}
                  />

                </PerfectScrollbar>
              </ScrollWrapper>
            </ObjectWrapper>
          ))
          : (
            <div>
              {groupCategoriesInvestmentsPdf()}
            </div>
          ) }

        {(mapVisible || (!canBeSave && data?.value && !isPdf)) && (
          <>
            <StyledTitleFormSectionSubTitle>
              <span>{t('placeme.investments_tile.points_on_map')}</span>
              <LinkWithIconCheckOnMap onClick={handleMapOpen} />
            </StyledTitleFormSectionSubTitle>
            <MapWrapper>
              <MapTile
                height='100%'
                layers={getLayers(Object.keys(valuesToDisplay))}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                width='100%'
                zoomControl
              />
            </MapWrapper>
          </>
        )}
        {(!mapVisible && canBeSave && !isPdf) && (
          <AddMapView
            buttonValue={1}
            description='placeme.add_investments_map.description'
            onChange={handleAddMap}
            title='placeme.add_investments_map.title'
          />
        )}
        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={getLayers(Object.keys(valuesToDisplay))}
            location={value}
            mapId={`investment-map-${getCatchmentId}`}
            setIsDisplay={setIsMapDisplayed}
          />
        )}
      </Wrapper>
    )
}
