/* eslint-disable max-lines */
import { createSlice } from '@reduxjs/toolkit'
import { IRange } from '@dataplace.ai/types'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { config } from 'apps/placeme/src/config'
import { createFlashMessage, createNavbarNotification } from '@dataplace.ai/functions/utils'
import { getI18n } from 'react-i18next'
import { IAnalysisState } from './@types/IAnalysisState'
import type { AppThunk } from '../../../redux/store'
import { ISectionTile } from './@types/ISectionTile'
import { ITile } from './@types/ITile'
import { ITileData } from './@types/ITileData'
import { INote } from './@types/INote'
import { ENDPOINTS } from '../../../constants/endpoints'
import { ITemporaryCatchmentData } from './@types/ITemporaryCatchmentData'
import { tilesData } from '../utils/tilesData'
import { IFreqRanges } from './@types/IFreqRanges'
import { ICatchmentData } from './@types/ICatchmentData'
import { IAnalysisResponse, IReportCatchment, IReportTileData } from './@types/savedReportTypes'
import { IRemoteConfigTileErrors, IRemoteConfigTilesWithPlans } from './@types/IRemoteConfigTiles'
import { IPdfData } from './@types/IPdfData'
import { fetchSubscriptionInfo } from '../../ChooseLocationReport/chooseLocationSlice'

const { v4: uuidv4 } = require('uuid')

const initialState: IAnalysisState = {
  analysisDbInfo: null,
  values: [],
  canBeSave: true,
  ranges: {
    value: [],
    loading: true,
    error: '',
  },
  showHints: true,
  layers: {},
  currentPlan: 'bronze',
  tiles: [],
  plans: [],
  isPdf: false,
  pdfData: [],
}

const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    toggleHints: (state) => {
      state.showHints = !state.showHints
    },
    saveTiles: (state, action) => {
      state.values = action.payload
    },
    saveRanges: (state, action) => {
      state.ranges = action.payload
    },
    saveCreditsAmount: (state, action) => {
      state.creditsAmount = action.payload
    },
    saveTemporaryCatchment: (state, action) => {
      state.layers.temporaryCatchment = action.payload
    },
    toggleCanBeSave: (state, action) => {
      state.canBeSave = action.payload
    },
    saveAnalysisDbInfo: (state, action) => {
      state.analysisDbInfo = action.payload
    },
    resetAnalysisState: (state) => {
      state = {
        ...initialState,
        tiles: [...state.tiles],
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        plans: [...state.plans],
      }
    },
    saveTilesFromRemoteConfig: (state, action) => {
      state.tiles = action.payload
    },
    savePlansFromRemoteConfig: (state, action) => {
      state.plans = action.payload
    },
    toggleSubscriptionType: (state, action) => {
      state.currentPlan = action.payload
    },
    savePdfData: (state, action) => {
      state.pdfData = action.payload
    },
    toggleIsPdf: (state, action) => {
      state.isPdf = action.payload
    },
  },
})

export const { reducer } = analysisSlice
export const {
  saveTiles, saveRanges, saveCreditsAmount, saveTemporaryCatchment, toggleHints, toggleCanBeSave, saveAnalysisDbInfo,
  resetAnalysisState, saveTilesFromRemoteConfig, savePlansFromRemoteConfig, toggleSubscriptionType, toggleIsPdf,
  savePdfData,
} = analysisSlice.actions

// Tile actions

export const addTileAction = (
  token: string,
  section: ISectionTile,
  tile: ITile,
  isExtraPaid?: boolean,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const categoryAlreadyExist = analysis.values.find((dataTile) => dataTile.id === section.id)
  const idNumber = (id: string) => {
    if (id.split('-').length > 1) {
      return tile.id
    }
    return `${tile.id}-${uuidv4()}`
  }

  if (categoryAlreadyExist) {
    dispatch(
      saveTiles(
        analysis.values.map((dataTile) =>
          (dataTile.id === section.id
            ? {
              ...dataTile,
              tiles: [...dataTile.tiles, {
                ...tile,
                id: idNumber(tile.id),
                isExtraPaid,
              }],
            }
            : dataTile)),
      ),
    )
  } else {
    dispatch(saveTiles([...analysis.values, {
      ...section,
      tiles: [{
        ...tile,
        id: idNumber(tile.id),
        isExtraPaid,
      }],
    }]))
  }
}

export const deleteTileAction = (
  categoryId: string,
  tileId: string,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.filter((t) => t.id !== tileId),
      }
      : dataTile))

  dispatch(
    saveTiles(updatedList.filter((dataTile) => !!dataTile.tiles.length)),
  )
}

export const fetchTileDataAction = (
  token: string,
  categoryId: string,
  tileId: string,
  subscriptionId: string | null,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const catchmentId = getState().analysis.values?.find(
    c => c.id === categoryId,
  )?.tiles?.find(t => t.id === tileId)?.chosenRange?.catchmentId
  const getTileType = (id: string) => id.split('-')[0]
  if (catchmentId) {
    const body = {
      catchment_id: catchmentId,
    }
    let data: ITileData

    const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tileId).toUpperCase()}_TILE`)?.[1]

    try {
      const response = await getAxios(config.API_URL, token).post(endpoint || '', body)
      data = {
        loading: false,
        error: '',
        value: response.data,
      }
    } catch (e) {
      data = {
        loading: false,
        error: e.error,
        value: null,
      }
    }
    if (data?.value) {
      const updatedList = getState().analysis.values.map((analysisCategory) =>
        (analysisCategory.id === categoryId
          ? {
            ...analysisCategory,
            tiles: analysisCategory.tiles.map((tile) =>
              (tile.id === tileId
                ? {
                  ...tile,
                  data,
                }
                : tile)),
          }
          : analysisCategory))

      dispatch(saveTiles(updatedList))

      // update tokens number, set timeout needed because of adding usage record after returning response in marmur
      setTimeout(() => dispatch(fetchSubscriptionInfo(token, subscriptionId)), 2000) }
  }
}

interface IParams {
  length: number,
  lat: number,
  lng: number,
  mode: string,
}

export const fetchTemporaryCatchment = (
  token: string,
  params: IParams,
): AppThunk => async (dispatch, _getState): Promise<void> => {
  const body = {
    length: params.length,
    lat: params.lat,
    lng: params.lng,
    mode: params.mode,
  }
  let data: ITemporaryCatchmentData

  const endpoint = ENDPOINTS.FETCH_CATCHMENT_PREVIEW

  try {
    const response = await getAxios(config.API_URL, token).post(endpoint, body)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }

  if (data?.value)
  { const newLayer = {
    id: uuidv4(),
    layer: {
      data: {
        coordinates: data?.value?.geojson?.coordinates,
        type: data?.value?.geojson?.type,
        properties: {},
      },
      options: {
        type: 'geojson',
        id: uuidv4(),
        style: {
          color: '#0000a2',
          fillColor: '#0000a2',
          weight: 0,
          fillOpacity: 0.3,
        },
      },
    },
  }
  dispatch(saveTemporaryCatchment(newLayer)) }
}

export const saveTileData = (
  categoryId: string,
  tileId: string,
  data: (ITileData | undefined),
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { values } = getState().analysis
  const updatedList = values.map((analysisCategory) =>

    (analysisCategory.id === categoryId
      ? {
        ...analysisCategory,
        tiles: analysisCategory.tiles.map((tile) =>
          (tile.id === tileId
            ? {
              ...tile,
              data,
            }
            : tile)),
      }
      : analysisCategory))
  dispatch(saveTiles(updatedList))
}

export const openAnalyse = (
  token: string,
  id: string,
): AppThunk => async (dispatch, _getState): Promise<IAnalysisResponse> => {
  let data: IAnalysisResponse

  const endpoint = ENDPOINTS.CREATE_DELETE_SHARE_ANALYSE
  try {
    const response = await getAxios(config.API_URL, token).get(endpoint ? `${endpoint}/${id}` : '')
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  }
  const saveEachAnalyse = () => {
    const catchments = data.value?.catchments || []
    if (catchments) {
      const tiles = [] as (ICatchmentData & IReportTileData)[]

      Object.entries(catchments).forEach(([_catchmentId, catchment]:[string, IReportCatchment]) => {
        const catchmentData = catchment?.catchmentData || {}
        const catchmentTiles: IReportTileData[] = catchment?.tiles || []

        catchmentTiles.forEach((tile) => {
          tiles.push({
            ...tile,
            ...catchmentData,
          })
        })
      })

      const sortedTiles = tiles.sort((a, b) => a.creationTime.localeCompare(b.creationTime))

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      sortedTiles.forEach((tile) => {
        const tileTypeId = tile?.categoryId || ''
        const categoryMetadata = tilesData.find(cat => cat.tiles.some(tileMetadata => tileMetadata.id === tileTypeId))

        const tileId = `${tileTypeId}-${tile?.catchmentId || ''}`
        const tileMetadata = categoryMetadata?.tiles.find(t => t.id === tileTypeId) as ITile

        const catchmentType = tile?.type || ''
        const tileRange = {
          id: `${catchmentType}-${tile.length}`,
          value: tile?.length || 0,
          type: catchmentType,
          catchmentId: tile.catchmentId || '',
          geoJSON: tile.geojson,
        }
        const tileData: ITileData = {
          loading: false,
          error: '',
          value: tile.value,
        }
        if (categoryMetadata && tileMetadata) {
          dispatch(addTileAction(token, categoryMetadata, {
            ...tileMetadata,
            id: tileId,
          }))
          dispatch(saveChosenRangeAction(categoryMetadata.id, tileId, tileRange as IRange))
          dispatch(saveTileData(categoryMetadata.id, tileId, tileData))
        }
      })
    }
  }
  if (data?.value) {
    dispatch(toggleCanBeSave(false))
    const {
      // eslint-disable-next-line no-unused-vars
      catchments, ...dbData
    } = data.value
    dispatch(saveAnalysisDbInfo(dbData))
  } else {
    dispatch(saveAnalysisDbInfo(undefined))
  }
  saveEachAnalyse()

  return data
}

export const toggleHintsAction = (): AppThunk => async (dispatch): Promise<void> => {
  dispatch(toggleHints())
}

export const saveTilesFromRemoteConfigAction = (remoteTilesWithPlans: IRemoteConfigTilesWithPlans | null): AppThunk =>
  (dispatch): void => {
    if (remoteTilesWithPlans) {
      // this combines remoteConfig tile data with hardcoded tilesData object
      const combinedTilesData = [] as unknown[]
      remoteTilesWithPlans.categories?.forEach(remoteCategory => {
        const localCategory = tilesData.find(localCategory => localCategory?.id === remoteCategory?.id)
        if (localCategory) {
          const { tiles } = localCategory
          const combinedTiles = [] as unknown[]

          // adding tile to sidebar manually - find category and push tile

          // if (remoteCategory.id === 'surroundings') {
          //   remoteCategory.tiles.push({
          //     id: 'employees_in_range',
          //     countries: ['PL'],
          //     plan: 'white',
          //   })
          // }

          remoteCategory.tiles.forEach(tile => {
            const localTile = tiles.find(localTile => localTile?.id === tile?.id)
            if (localTile) {
              const plan = tile.plan === null ? remoteTilesWithPlans.plans.find(plan => plan?.id === 'white') : remoteTilesWithPlans.plans.find(plan => plan?.id === tile.plan)
              if (plan) {
                combinedTiles.push({
                  ...localTile,
                  ...tile,
                  plan,
                })
              }
            }
          })
          combinedTilesData.push({
            ...localCategory,
            ...remoteCategory,
            tiles: combinedTiles,
          })
        }
      })

      dispatch(saveTilesFromRemoteConfig(combinedTilesData))
      // for country check issue
      window?.localStorage?.setItem('onlyTiles', JSON.stringify(combinedTilesData))
      dispatch(savePlansFromRemoteConfig(remoteTilesWithPlans?.plans))
    }
  }

export const saveTileErrors = (remoteTilesErrors: IRemoteConfigTileErrors[] | null): AppThunk =>
  (): void => {
    if (remoteTilesErrors?.length) {
      const i18n = getI18n()
      const messageForAll = remoteTilesErrors?.find(error => error.id === 'message_for_all')
      const filteredTileErrors = remoteTilesErrors?.filter(item => item?.id !== 'message_for_all' && item?.visible)
      if (filteredTileErrors?.length) {
        createNavbarNotification({
          id: 'tile_errors',
          text: `${`${i18n.t('placeme.tile_errors_1')} ${filteredTileErrors?.map((error, index) =>
          { const dotOrColon = index < (filteredTileErrors?.length - 1) ? '' : '.'
            return ` ${i18n.t(`placeme.tile.${error?.id}`)}${dotOrColon}`
          })} ${i18n.t('placeme.tile_errors_2')}`}`,
          showCloseButton: true,
          showContactButton: false,
          translate: false,
          error: true,
        })
      }

      if (messageForAll && messageForAll?.visible) {
        createNavbarNotification({
          id: 'error_message_for_all',
          text: `${i18n.t(`placeme.error.${messageForAll?.id}`)}`,
          showCloseButton: true,
          showContactButton: false,
          translate: false,
          error: true,
        })
      }
    }
  }

export const fetchTilesFromRemoteConfigAction = (token: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await getAxios(config.API_URL, token).get(ENDPOINTS.TILES)
    if (response) {
      const newTiles = {
        ...response?.data,
      }
      const tileErrors = [...response?.data?.tileErrors] as IRemoteConfigTileErrors[]

      if (newTiles) {
        const newTilesStringified = JSON.stringify(newTiles)
        const localStorageTilesData = window?.localStorage?.getItem('placemeTiles')
        if (localStorageTilesData !== newTilesStringified) {
          dispatch(saveTilesFromRemoteConfigAction(newTiles))
          window?.localStorage?.setItem('placemeTiles', newTilesStringified)
        }
      }
      if (tileErrors) {
        const newTileErrors = JSON.stringify(tileErrors)
        dispatch(saveTileErrors(tileErrors))
        window?.localStorage?.setItem('tileErrors', newTileErrors)
      }
    }
  }

// Note actions

export const saveNoteAction = (
  categoryId: string,
  tileId: string,
  note: INote,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) => (t.id === tileId
          ? {
            ...t,
            notes: t.notes?.length ? [...t?.notes?.filter(n => n.id !== note.id), note] : [note],
          }
          : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const deleteNoteAction = (
  categoryId: string,
  tileId: string,
  note: INote,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) =>
          (t.id === tileId
            ? {
              ...t,
              notes: t.notes?.filter((n) => n.id !== note.id),
            }
            : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const saveNotesToDBAction = (token: string, notes: unknown[]): AppThunk => async (): Promise<void> => {
  if (notes) {
    try {
      const notesData = {
        notes,
      }
      await getAxios(config.API_URL, token).post<INote[]>(ENDPOINTS.CRUD_NOTE, notesData)
    }
    catch (e) {
      createFlashMessage({
        message: 'status.error.note.creation_failed',
      })
    }
  }
}

export const deleteNoteFromDBAction = (token: string, note: INote): AppThunk => async (): Promise<void> => {
  try {
    const response = await getAxios(config.API_URL, token).delete<INote>(`${ENDPOINTS.CRUD_NOTE}/${note.id}`)
    createFlashMessage({
      message: response.statusText,
    })
  }
  catch (e) {
    createFlashMessage({
      message: 'status.error.note.deletion_failed',
    })
  }
}

export const updateNoteInDBAction = (token: string, note: INote): AppThunk => async (): Promise<void> => {
  try {
    const noteBody = {
      id: note.id,
      text: note.text,
    }
    await getAxios(config.API_URL, token).patch<INote>(ENDPOINTS.CRUD_NOTE, noteBody)
  } catch (e) {
    createFlashMessage({
      message: 'status.error.note.update_failed',
    })
  }
}

export const fetchNotesAction = (token: string, reportId: string, categoryId: string, catchmentId: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.CRUD_NOTE}?catchment_id=${catchmentId}&report_id=${reportId}`)
      const { data: notes } = response
      dispatch(saveFetchedNotesAction(categoryId, catchmentId, notes))
    } catch (e) {
      createFlashMessage({
        message: 'status.error.note.get_failed',
      })
    }
  }

const saveFetchedNotesAction = (categoryId: string, catchmentId: string, notes: INote[]): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    const { analysis } = getState()
    const updatedList = analysis.values.map((dataTile) =>
      (dataTile.id === categoryId
        ? {
          ...dataTile,
          tiles: dataTile.tiles.map((t) => (t.chosenRange?.catchmentId === catchmentId
            ? {
              ...t,
              notes,
            }
            : t)),
        }
        : dataTile))
    dispatch(saveTiles(updatedList))
  }

// Range actions

export const saveChosenRangeAction = (
  categoryId: string,
  tileId: string,
  range: IRange | undefined,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const { analysis } = getState()
  const updatedList = analysis.values.map((dataTile) =>
    (dataTile.id === categoryId
      ? {
        ...dataTile,
        tiles: dataTile.tiles.map((t) =>
          (t.id === tileId
            ? {
              ...t,
              chosenRange: range,
            }
            : t)),
      }
      : dataTile))
  dispatch(saveTiles(updatedList))
}

export const addNewRangeAction = (range: IRange): AppThunk => async (dispatch, getState): Promise<void> => {
  const { ranges } = getState().analysis
  const updatedList = (): IRange[] => {
    const isAlreadyAdded = !!ranges.value.find((r) => ((r.type === range.type) && (r.value === range.value)))
    if (!isAlreadyAdded) {
      const newArray = [range, ...ranges.value]
      return newArray.length > 8 ? newArray.slice(0, 8) : newArray
    }
    return ranges.value
  }
  dispatch(saveRanges({
    ...ranges,
    value: updatedList(),
  }))
}

export const saveNewRangeAction = (
  token: string,
  userId: string,
  categoryId: string,
  tileId: string,
  range: IRange,
): AppThunk => async (dispatch, getState): Promise<void> => {
  const {
    location, analysis,
  } = getState()

  const body = range.type === 'custom'
    ? {
      reportId: location?.analyseId,
      lat: location?.value?.lat,
      lng: location?.value?.lng,
      geojson: {
        type: range.geoJSON?.type,
        coordinates: range.geoJSON?.coordinates,
      },
      mode: range.type,
    }
    : {
      reportId: location?.analyseId,
      lat: location?.value?.lat,
      lng: location?.value?.lng,
      length: range.value,
      mode: range.type,
    }

  try {
    const newRange = await getAxios(config.API_URL, token).post<IRange>(ENDPOINTS.FETCH_CATCHMENT, body)
    dispatch(saveChosenRangeAction(categoryId, tileId, {
      id: `${range.type}-${range.value}`,
      value: range.value,
      type: range.type,
      catchmentId: newRange.data.id,
      geoJSON: newRange.data.geojson,
    }))

    const notes = analysis?.values?.find(category =>
      category.id === categoryId)?.tiles?.find(tile => tile.id === tileId)?.notes

    if (notes) {
      const notesData = notes.map(note => ({
        id: note?.id,
        text: note?.text,
        tileType: tileId?.split('-')[0],
        catchmentId: newRange?.data?.id,
        reportId: location?.analyseId,
      }))
      dispatch(saveNotesToDBAction(token, notesData))
    }

    // useful when we'll do the logic with saving tiles data to the same catchment
    // dispatch(fetchRangesAction(token, userId))
  } catch (e) {
    createFlashMessage({
      message: 'status.error.catchment.creation_failed',
    })
  }
}

export const fetchRangesAction = (
  token: string,
  userId: string,
): AppThunk => async (dispatch, getState): Promise<void> => {
  // TODO co gdy będzie dobry zasięg do ponownego wykorzystania ale stary?
  // useful when we'll do the logic with saving tiles data to the same catchment
  // dispatch(saveRanges(
  //   {
  //     value: [],
  //     loading: true,
  //     error: ''
  //   }
  // ))

  let data

  try {
    const response = await getAxios(config.API_URL, token).get<IFreqRanges[]>(
      `/user/${userId}/catchments?workspace_id=${window?.localStorage?.workspaceId}&filter=ranges&prefer_report=${getState().location.analyseId}`,
    )
    data = {
      loading: false,
      error: '',
      value: response.data.map(data => ({
        id: `${data?.type}-${data?.length}-${uuidv4()}`,
        value: data?.length,
        type: data?.type,
        catchmentId: data?.id,
        geoJSON: data?.geoJson,
      })),
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: [{
        id: 'walk-10',
        value: 10,
        type: 'walk',
      }],
    }
  } finally {
    dispatch(saveRanges(data))
  }
}
export const fetchWorkspaceUsageValue = (token: string): AppThunk => async (dispatch, _getState): Promise<void> => {
  let saveData
  try {
    const response = await getAxios(config.API_URL, token).get(`${ENDPOINTS.WORKSPACE}/${window?.localStorage?.workspaceId}`)
    saveData = {
      error: '',
      value: response.data,
    }
  } catch (e) {
    saveData = {
      error: e.error,
      value: null,
    }
  }
  dispatch(saveCreditsAmount(saveData?.value?.creditsTotal))
}

// Result actions

export const deleteResultAction = (token: string, reportId: string,
  catchmentId: string, categoryType: string, tileCategory: string, locationId?: string, tileId?: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      const response = await getAxios(config.API_URL, token)
        .delete(`${ENDPOINTS.DELETE_RESULT.replace('<report_id>', reportId)}?catchment_id=${catchmentId}&category_type=${categoryType}${locationId && `&location_id=${locationId}`}`)
      if (locationId && tileId) {
        const saveData = {
          loading: false,
          error: '',
          value: response.data,
        }

        dispatch(saveTileData(tileCategory, tileId, saveData))
      }
    } catch (e) {
      createFlashMessage({
        message: 'status.error.result.deletion_failed',
      })
    }
  }

export default analysisSlice.reducer

export const addPdfDataAction = (pdf: IPdfData): AppThunk => async (dispatch): Promise<void> => {
  // uncomment if we need to update not overwrite pdfData var
  // const { pdfData } = getState().analysis

  dispatch(savePdfData([pdf]))
}
