import { redirectToSignOut, setCookieWithoutJSON } from '../utils'

const fetchIdFromUrl = () => {
  const location = window.location.pathname.split('/')
  let id = ''
  for (const [index, elem] of location.entries()) {
    if (elem === 'analyse') {
      id = location?.[index + 1]
      break
    }
    id = ''
  }
  return id
}

export const handleTokenExpire = () :void => {
  if (!window.location.href.includes('auth') && !window.location.pathname.includes('sign')) {
    if (window.location.pathname.includes('analyse')) {
      setCookieWithoutJSON('analyseId', fetchIdFromUrl())
      setCookieWithoutJSON('workspaceId', window.localStorage.getItem('workspaceId'))
    }
    redirectToSignOut()
  }
}
