/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react'
import styled, { css } from 'styled-components'
import Popup from 'reactjs-popup'
import { ButtonProduct } from '@dataplace.ai/ui-components/atoms'
import { handleMenuModalClose } from '@dataplace.ai/functions/utils'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import { useTranslation } from 'react-i18next'

const Trigger = styled.div(
  () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `,
)

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    min-width: 300px;
  `
})

const Header = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    margin-bottom: 0.5rem;
    color: ${palette.black};
    font-size: ${typography.main.pt_15_medium.fontSize};
    font-weight: ${typography.main.pt_15_medium.fontWeight};
    line-height: ${typography.main.pt_15_medium.lineHeight};
  `
})

const Content = styled.span(({ theme }) => {
  const {
    palette, typography,
  } = theme

  return css`
    margin-bottom: 0.5rem;
    color: ${palette.results.red.dark};
    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

const Footer = styled.div(
  () => css`
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
  `,
)

export interface DeleteTilePopupProps {
  trigger: JSX.Element
  handleDelete?: () => void
  close: () => void
}

export const DeleteTilePopup = ({
  trigger,
  handleDelete,
  close,
}: DeleteTilePopupProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Popup
      modal
      onClose={close}
      onOpen={handleMenuModalClose}
      overlayStyle={defaultTheme.overlay}
      trigger={<Trigger>{trigger}</Trigger>}
    >
      {(close: () => void) => (
        <Wrapper>
          <Header>{t('placeme.do_you_want_delete_tile')}</Header>
          <Content>{t('placeme.data_not_saved')}</Content>
          <Footer>
            <ButtonProduct
              deleteButton
              margin='0 5px 0 0'
              onClick={handleDelete}
              small
            >
              {t('generic.delete')}
            </ButtonProduct>
            <ButtonProduct
              onClick={close}
              small
            >
              {t('generic.cancel')}
            </ButtonProduct>
          </Footer>
        </Wrapper>
      )}
    </Popup>
  )
}
