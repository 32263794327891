/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  TileFormRow,
  TileFormSpan,
  TileFormWrapper,
  TileFormSection,
  TileFormSectionTitle,
  TileFormColumn,
  Price,
  TileFormRowWithData,
  GaugeChart,
} from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '@dataplace.ai/features'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { useSelector } from 'react-redux'
import { RootState } from 'apps/placeme/src/redux/store'
import { formatNumber } from '@dataplace.ai/functions/utils'
import { ILocation } from '@dataplace.ai/types'
import { IPurchasingPowerTileData } from './@types/IPurchasingPowerTileData'
import { useAppDispatch } from '../../../../../../../redux/hooks'
import { fetchWorkspaceUsageValue } from '../../../../../slice/analysisSlice'

const StyledTileFormRowWithData = styled(TileFormRowWithData)(({ theme }) => {
  const { palette } = theme
  return css`
    > svg > path {
      fill: ${palette.blue};
    }
  `
})

const Header = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    flex-direction: row;
    background-color: ${palette.light.white};
    margin: 2rem 0 1rem;
  `
})

const Label = styled.span(({ theme }) => {
  const { typography } = theme
  return css`
    width: 50%;
    text-align: right;
    color: #646464;
    margin: 0 1.25rem;
    font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
    font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
    line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
    letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};

    :first-child {
      padding-left: 0.75rem;
      margin-left: 0;
      text-align: left;
    }

    :last-child {
      padding-right: 0.75rem;
      margin-right: 0;
    }
  `
})

const Row = styled.span(({ theme }) => {
  const {
    typography, palette, corners,
  } = theme
  return css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem;
    border-right: ${corners.default.borderRadius};
    color: ${palette.black};
    font-size: ${typography.small.pt_13_regular.fontSize};
    font-weight: ${typography.small.pt_13_regular.fontWeight};
    line-height: ${typography.small.pt_13_regular.lineHeight};

    :nth-child(even) {
      background-color: ${palette.light.main};
    }

    > span {
      text-align: right;
      margin: 0 1.25rem;
      width: 50%;
    }

    > span:first-child {
      font-size: ${typography.small.pt_13_semibold.fontSize};
      font-weight: ${typography.small.pt_13_semibold.fontWeight};
      line-height: ${typography.small.pt_13_semibold.lineHeight};
      margin-left: 0;
      text-align: left;
    }

    > span:last-child {
      margin-right: 0;
      display: flex;
      justify-content: flex-end;

      > span {
        width: max-content;
      }
    }
  `
})

const labels = ['placeme.purchasing_power.income', 'placeme.purchasing_power.number_of_households']

export const PurchasingPowerTile: React.FC<{data: IPurchasingPowerTileData, isPdf?:boolean}> = ({
  isPdf, data,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { value } = useSelector((state: RootState) => state.location)

  const [token, setToken] = useState('')
  const authContext = useContext(AuthContext)
  const locationPDF = JSON.parse(localStorage?.getItem('location') || '{}') as ILocation

  const getBelowAboveTranslation = (label: string) => {
    const labelArray = label.split(' ')
    if (labelArray[0] === 'below') {
      return `${t('placeme.purchasing_power.below')} ${labelArray.splice(1).join(' ')}`
    } if (labelArray[0] === 'above') {
      return `${t('placeme.purchasing_power.above')} ${labelArray.splice(1).join(' ')}`
    }

    return labelArray.join(' ')
  }

  useEffect(() => {
    if (token.length) {
      dispatch(fetchWorkspaceUsageValue(token))
    }
  }, [token, data])

  useEffect(() => {
    authContext?.userData?.user?.getIdToken(true)?.then((response) => {
      setToken(response)
    })
  }, [authContext])

  return !data || data?.loading
    ? (<Loader />)
    : (
      <TileFormWrapper>
        <TileFormSection>
          <StyledTileFormRowWithData>
            <span>{t('placeme.purchasing_power_tile.row_with_data_1_span_1')}</span>
            <Price
              currency={(data?.value?.withinRange?.currency && value?.country !== 'PL') ? data?.value?.withinRange?.currency : `PLN ${t('generic.net')}`}
              value={data?.value?.withinRange?.averageSalary}
            />
          </StyledTileFormRowWithData>
          <TileFormSpan>
            {t('placeme.purchasing_power_tile.form_span_1')}
          </TileFormSpan>
          <StyledTileFormRowWithData>
            <span>{t('placeme.peopleTile.row_with_data_2_span_1')}</span>
            <Price
              currency={(data?.value?.withinRange?.currency && value?.country !== 'PL') ? data?.value?.withinRange?.currency : `PLN ${t('generic.net')}`}
              value={data?.value?.withinRange?.totalEarnings}
            />
          </StyledTileFormRowWithData>
          <TileFormSpan>
            {t('placeme.purchasing_power_tile.form_span_2')}
          </TileFormSpan>
        </TileFormSection>
        <TileFormSection>
          <TileFormSectionTitle>
            <span>{t('placeme.purchasing_power_tile.section_title_1_span_1')}</span>
          </TileFormSectionTitle>
          <TileFormSpan>
            <strong>{`${parseFloat((data?.value?.withinCity)?.toFixed(1))}%`}</strong>
            {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.purchasing_power_tile.form_span_3_normal_1') : t('placeme.purchasing_power_tile.form_span_3_normal_1.foreign')}
          </TileFormSpan>
          {!isPdf
          && (
            <>
              <GaugeChart
                // comparison='+10%'
                height={150}
                unit='%'
                value={data?.value?.withinCity}
              />

              <TileFormRow width='100%'>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='center'
                  width='50%'
                >
                  <TileFormSpan>
                    {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.purchasing_power_tile.form_span_4') : t('placeme.purchasing_power_tile.form_span_4.foreign')}
                  </TileFormSpan>
                </TileFormColumn>
                <TileFormColumn
                  justify='center'
                  padding='2rem'
                  textAlign='center'
                  width='50%'
                >
                  <TileFormSpan>
                    {(value?.country === 'PL' || locationPDF.country === 'PL') ? t('placeme.purchasing_power_tile.form_span_5') : t('placeme.purchasing_power_tile.form_span_5.foreign')}
                  </TileFormSpan>
                </TileFormColumn>
              </TileFormRow>
            </>
          ) }
        </TileFormSection>
        {(data?.value?.annualIncome?.length) && (
          <TileFormSection>
            <TileFormSectionTitle>
              <span>{t('placeme.purchasing_power_tile.section_title_3_span_1')}</span>
            </TileFormSectionTitle>
            <Header>
              {labels.map((label, index) => (
                index === 0
                  ? (
                    <Label key={label}>
                      {`${t(label)} [${data?.value?.withinRange?.currency}]`}
                    </Label>
                  )
                  : <Label key={label}>{t(label)}</Label>
              ))}
            </Header>
            {data?.value?.annualIncome?.map((value) => (
              <Row key={value?.label}>
                <span>{getBelowAboveTranslation(value?.label)}</span>
                <span>{formatNumber(value?.value)}</span>
              </Row>
            ))}
          </TileFormSection>
        )}
      </TileFormWrapper>
    )
}
