import React from 'react'
import styled, { css } from 'styled-components'

interface ICheckboxWrapper {
  error: boolean
}

const CheckboxWrapper = styled.div<ICheckboxWrapper>(({
  theme, error,
}) => {
  const { palette } = theme

  return css`
    display: flex;
    position: relative;
    width: 1.25rem;
    height: 1.25rem;

    > input[type='checkbox'] {
      cursor: pointer;
    }

    > input[type='checkbox']:disabled {
      cursor: default;
    }

    > input[type='checkbox'],
    > input[type='checkbox'] + div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > input[type='checkbox'] {
      z-index: 1;
      opacity: 0;
    }

    > input[type='checkbox']:checked + div {
      background-image: url('assets/icons/checkMark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }

    > input[type='checkbox'] + div {
      z-index: 0;
      background-color: ${palette.light.white};
      border: 1px solid ${palette.blue};
      box-sizing: border-box;
      border-radius: 0.25rem;
    }

    > input[type='checkbox']:hover + div {
      background-color: ${palette.light.white};
      border: 2px solid ${palette.dark.main};
    }

    > input[type='checkbox']:focus:checked + div {
      // background-color: ${palette.results.green.opaque};
      border: 2px solid ${palette.results.green.dark};
    }

    > input[type='checkbox']:focus + div {
      border: 2px solid ${palette.blue};
    }

    > input[type='checkbox']:checked + div {
    //> input[type='checkbox']:active + div {
      background-color: ${palette.results.green.dark};
      border: 2px solid ${palette.results.green.dark};
    }

    ${error && css`
      > input[type='checkbox'] + div {
        border: 1px solid ${palette.results.red.dark};
      }
    `}
  `
})

const ErrorSpan = styled.span(({ theme }) => {
  const { palette } = theme
  return css`
    font-size: 0.75rem;
    color: ${palette.results.red.dark};
    position: relative;
    top: 1.2rem;
    margin-left: 2rem;
    white-space: nowrap;
  `
})

const LabelWrapper = styled.div`
  padding-left: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string
  label?: JSX.Element | HTMLElement
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  error, label, ...restProps
}) => (
  <CheckboxWrapper error={!!error}>
    <input
      {...restProps}
      type='checkbox'
    />
    <div />
    {label && (
      <LabelWrapper>
        {label}
      </LabelWrapper>
    )}
    {error && (
      <ErrorSpan>
        {error}
      </ErrorSpan>
    )}
  </CheckboxWrapper>
)
