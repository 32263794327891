/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { createFlashMessage } from '@dataplace.ai/functions/utils'
import type { AppThunk } from '../../../redux/store'
import { ENDPOINTS } from '../../../constants/endpoints'
import { IAnalysesAndReportsState } from './@types/IAnalysesAndReportsState'
import { config } from '../../../config'
import { IExistingReports } from '../components/organisms/AnalyseAndReportBody/@types/IExistingReports'

const initialState : IAnalysesAndReportsState = {
  data: {
    value: null,
    loading: true,
    error: '',
  },
}

const analysesAndReportsSlice = createSlice({
  name: 'analysesAndReports',
  initialState,
  reducers: {
    saveAnalysesAndReports: (state, action) => {
      state.data = action.payload
    },
  },
})

export const fetchAnalysesAndReports = (token:string): AppThunk => async (dispatch): Promise<void> => {
  let data
  try {
    const response = await getAxios(config.API_URL, token).get(ENDPOINTS.FETCH_ANALYSES_AND_REPORTS.replace('<workspace_id>', window?.localStorage?.workspaceId) || '')
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
  } finally {
    dispatch(saveAnalysesAndReports(data))
  }
}

export const deleteAnalyse = (token: string, analyseId: string, folderId: string,
  reports: (IExistingReports | null)[]): AppThunk => async (dispatch): Promise<void> => {
  let data
  const body = {
    reports: reports?.find((elem) => elem?.folderId === folderId)?.reports.filter((item) => item !== analyseId),
  }
  try {
    const response = await getAxios(config.API_URL, token).patch(`${ENDPOINTS.CREATE_ADD_DELETE_FOLDER}/${folderId}`, body)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
    createFlashMessage({
      message: 'status.success.report.deleted',
    })
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
    createFlashMessage({
      message: 'status.error.report.deletion_failed',
    })
  } finally {
    if (data?.value) {
      dispatch(fetchAnalysesAndReports(token))
    }
  }
}
export const deleteFolder = (token: string, folderId: string): AppThunk => async (dispatch): Promise<void> => {
  let data
  try {
    const response = await getAxios(config.API_URL, token).delete(`${ENDPOINTS.CREATE_ADD_DELETE_FOLDER}/${folderId}`)
    data = {
      loading: false,
      error: '',
      value: response.data,
    }
    createFlashMessage({
      message: 'status.success.folder.deleted',
    })
  } catch (e) {
    data = {
      loading: false,
      error: e.error,
      value: null,
    }
    createFlashMessage({
      message: 'status.error.folder.deletion_failed',
    })
  } finally {
    if (data?.value) {
      dispatch(fetchAnalysesAndReports(token))
    }
  }
}

export const { saveAnalysesAndReports } = analysesAndReportsSlice.actions

// eslint-disable-next-line max-len

export default analysesAndReportsSlice.reducer
