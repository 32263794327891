import { AnalyticsContext, AuthContext } from '@dataplace.ai/features'
import { IRange } from '@dataplace.ai/types'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { RootState } from 'apps/placeme/src/redux/store'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { getAxios } from '@dataplace.ai/functions/utils/axios'
import { ENDPOINTS } from 'apps/placeme/src/constants/endpoints'
import { config } from 'apps/placeme/src/config'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { handleMaxRanges } from 'apps/placeme/src/functions/handleMaxRanges'
import { DateRangeSelector } from '..'
import { deleteTileAction, fetchRangesAction, fetchWorkspaceUsageValue, saveNewRangeAction, saveTemporaryCatchment, saveTileData } from '../../../slice/analysisSlice'
import { RangeTile } from '../../organisms'
import { TileFooter } from '../../atoms'
import { ITileData } from '../../../slice/@types/ITileData'
import { IRangeAndDateTile } from './@types/IRangeAndDataTile.props'

const RangeSelectionWrapper = styled.div(() => css`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  padding: 1.25rem;
`)

export const RangeAndDateTile = ({
  userId, category, tile, maxRanges, accepted, setAccepted, isExtraPaid, minDate, maxDate,
}: IRangeAndDateTile) : JSX.Element => {
  const [dateRanges, setDateRanges] = useState<{startDate?: Date, endDate?: Date}>({})

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [chosenRange, setChosenRange] = useState<IRange>()
  const [canBeRequest, setCanBeRequest] = useState(false)
  const [shouldDataBeRequested, setShouldDataBeRequested] = useState(false)
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(true)
  const authContext = useContext(AuthContext)
  const [isCatchmentRequested, setIsCatchmentRequested] = useState(false)
  const {
    ranges, values, creditsAmount,
  } = useSelector((state: RootState) => state.analysis)
  const { analytics } = useContext(AnalyticsContext)

  useEffect(() => {
    authContext.userData?.user?.getIdToken(true)?.then(response => {
      setToken(response)
    })
  }, [authContext])

  const { currentSubscriptionData } = useSelector((state: RootState) => state.location)

  useEffect(() => {
    if (token?.length && !ranges.value.length) dispatch(fetchRangesAction(token, userId))
  }, [token])

  useEffect(() => {
    if (values.length) {
      setChosenRange(values?.find(c => c?.id === category)?.tiles
        ?.find(t => t.id === tile)?.chosenRange || handleMaxRanges(ranges?.value, maxRanges)?.[0] || {})
    }
  }, [JSON.stringify(values), ranges?.value])

  const handleChosenRangeChange = (range?: IRange) => {
    setChosenRange(handleMaxRanges(ranges?.value, maxRanges).find(x =>
      x?.type === range?.type && x?.value === range?.value && range?.type !== 'custom') || range)
  }

  const handleSave = () => {
    if (chosenRange && token?.length) {
      setIsCatchmentRequested(true)
      dispatch(saveNewRangeAction(token, userId, category, tile, chosenRange))
      dispatch(saveTemporaryCatchment(undefined))
      setShouldDataBeRequested(true)
    }
  }

  const handleDeleteTile = () => {
    analytics?.track('Tile Cancel Button Clicked', {
      tile: tile?.split('-')[0],
      range: {
        type: chosenRange?.type,
        value: chosenRange?.value,
      },
    })
    dispatch(deleteTileAction(category, tile))
  }
  const fetchTileRange = () => values.find(cat => cat.id === category)?.tiles.find(t => t.id === tile)?.chosenRange

  const fetchData = useCallback(async () => {
    const catchmentId = values?.find(c => c.id === category)?.tiles?.find(t =>
      t.id === tile)?.chosenRange?.catchmentId

    if (canBeRequest && shouldDataBeRequested) {
      const body = {
        catchmentId,
        dataRanges: {
          startDate: dateRanges.startDate?.getTime(),
          endDate: dateRanges.endDate?.getTime(),
        },
      }

      let saveData
      const getTileType = (id: string) => id.split('-')[0]

      const endpoint = Object.entries(ENDPOINTS).find((key) => key[0] === `${getTileType(tile).toUpperCase()}_TILE`)?.[1]
      try {
        const response = await getAxios(config.API_URL, token)
          .post<ITileData>(endpoint || '', body)
        saveData = {
          loading: false,
          error: '',
          value: response.data,
        }
      } catch (e) {
        saveData = {
          loading: false,
          error: e.error,
          value: null,
        }
      }
      finally {
        dispatch(saveTileData(category, tile, saveData))
        setLoading(false)
        if (token?.length) {
          setTimeout(() => dispatch(fetchWorkspaceUsageValue(token)), 2000)
        }
        setAccepted(true)
      }
    }
  }, [canBeRequest, token])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (fetchTileRange()) {
      setCanBeRequest(true)
    }
  }, [fetchTileRange()])

  const handleSubmit = () => {
    if (creditsAmount) {
      analytics?.track('Tile Data Generated', {
        tile: tile?.split('-')[0],
        range: {
          type: chosenRange?.type,
          value: chosenRange?.value,
        },
      })
      handleSave()
    }
  }

  return (
    <>
      {(ranges?.value?.length && !isCatchmentRequested)
        ? (
          <>
            <RangeSelectionWrapper>
              {!!ranges.value.length && (
                <RangeTile
                  chosenRange={chosenRange || handleMaxRanges(ranges.value, maxRanges)[0]}
                  handleChosenRangeChange={handleChosenRangeChange}
                  maxRanges={maxRanges}
                  ranges={handleMaxRanges(ranges?.value, maxRanges)}
                  tile={tile}
                />
              )}
              <DateRangeSelector
                accepted={accepted}
                description={t('placeme.traffic_visualization_tile.date_range_description')}
                maxDate={maxDate}
                minDate={minDate}
                onChange={(startDate, endDate) => {
                  setDateRanges({
                    startDate,
                    endDate,
                  }) }}
                setAccepted={setAccepted}
                title={t('placeme.traffic_visualization_tile.date_range_title')}
                value={dateRanges}
              />
            </RangeSelectionWrapper>
            <TileFooter
              disabled={!dateRanges?.startDate || !dateRanges?.endDate}
              isExtraPaid={isExtraPaid}
              isUnlimited={currentSubscriptionData?.value?.planExact?.includes('unlimited') || currentSubscriptionData?.value?.plan === 'white'}
              label={isExtraPaid ? t('generic.apply_and_buy') : t('generic.apply')}
              onAccept={handleSubmit}
              onCancel={() => handleDeleteTile()}
              tile={tile}
              value='1'
            />
          </>
        )
        : (loading && <Loader />) }

    </>
  )
}
