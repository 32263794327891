import styled, { css } from 'styled-components'
import { ITileFormColumnProps } from './@types/ITileFormColumn.props'

export const TileFormColumn = styled.div<ITileFormColumnProps>(
  ({
    width, align, justify, padding, textAlign,
  }) => css`
    width: ${width || '100%'};
    display: flex;
    align-items: ${align};
    justify-content: ${justify};
    text-align: ${textAlign};
    padding: ${padding};
    flex-direction: column;
    flex-wrap: nowrap;

    > div {
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    > div:first-child {
      margin-left: 0;
    }

    > div:last-child {
      margin-right: 0;
    }
  `,
)
