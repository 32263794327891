/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'
import { defaultTheme } from '@dataplace.ai/ui-components/themes/defaultTheme'
import Popup from 'reactjs-popup'
import { CloseIcon, Button, TextButton } from '@dataplace.ai/ui-components/atoms'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Formik, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { Form, FormSection, TextInput } from '@dataplace.ai/ui-components/molecules'
import { RootState } from 'apps/placeme/src/redux/store'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'apps/placeme/src/redux/hooks'
import { redirectToPdf } from '@dataplace.ai/functions/utils/navigate'
import { getCookieWithoutJSON } from '@dataplace.ai/functions/utils'
import { IDownloadAnalyseInitialValues, initialValues } from './@constants/initialValues'
import { addPdfDataAction, toggleIsPdf } from '../../../slice/analysisSlice'
import { ITile } from '../../../slice/@types/ITile'
import { ISectionTile } from '../../../slice/@types/ISectionTile'
import { tilesList } from '../../../utils/tilesPdfList'

const { v4: uuidv4 } = require('uuid')

export interface IDownloadAnalyseModalProps {
  trigger: JSX.Element
  analyseId?: string
}

const Wrapper = styled.div(({ theme }) => {
  const {
    palette, corners,
  } = theme
  return css`
    border-radius: ${corners.default.borderRadius};
    background-color: ${palette.light.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5rem 2.5rem 1.5rem;
    width: 460px;

    form {
      margin: 0;
    }
  `
})

const TopWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1.25rem;

    h3 {
      color: ${palette.black};
      font-size: ${typography.big.pt_28_semibold.fontSize};
      font-weight: ${typography.big.pt_28_semibold.fontWeight};
      line-height: ${typography.big.pt_28_semibold.lineHeight};
    }
  `
})

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
`

const AnalysisWrapper = styled.div(({ theme }) => {
  const {
    palette, typography,
  } = theme
  return css`
    width: 100%;
    margin-bottom: 2rem;
    overflow-y: scroll;
    scrollbar-color: rgb(210, 210, 210) transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      display: block;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #f1f1f1;
      border-radius: 8px;
    }

    p {
      color: ${palette.black};
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
    }
  `
})

const InputWrapper = styled.div(({ theme }) => {
  const {
    typography, palette,
  } = theme
  return css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    max-height: 200px;

    > .formik-radio {
      display: flex;
      position: relative;
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 11px;

      > input[type='radio'] {
        z-index: 1;
        opacity: 0;
        cursor: pointer;
      }

      > input[type='radio']:disabled {
        cursor: default;
      }

      > input[type='radio'], > input[type='radio'] + div {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      > input[type='radio']:checked + div {
        background-image: url('assets/icons/radio-button-on.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 140%;
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio'] + div {
        z-index: 0;
        background-color: ${palette.light.white};
        border: 1px solid ${palette.blue};
        box-sizing: border-box;
        border-radius: 1rem;
      }

      > input[type='radio']:hover + div {
        background-color: ${palette.light.white};
        border: 2px solid ${palette.dark.main};
      }

      > input[type='radio']:checked:hover + div {
        background-color: ${palette.light.white};
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio']:focus:checked + div {
        background-color: ${palette.results.green.opaque};
        border: 1px solid ${palette.results.green.dark};
      }

      > input[type='radio']:focus + div {
        border: 2px solid ${palette.blue};
      }

      > input[type='radio']:active + div {
        background-color: ${palette.results.green.dark};
        border: 1px solid ${palette.results.green.dark};
      }
    }

    span {
      color: ${palette.black};
      font-size: ${typography.main.pt_15_regular.fontSize};
      font-weight: ${typography.main.pt_15_regular.fontWeight};
      line-height: ${typography.main.pt_15_regular.lineHeight};
    }
  `
})

const FormikCheckbox = styled.div(({ theme }) => {
  const { palette } = theme
  return css`
    display: flex;
    position: relative;
    width: 1.25rem;
    height: 1.25rem;

    > input[type='checkbox'] {
      cursor: pointer;
    }

    > input[type='checkbox']:disabled {
      cursor: default;
    }

    > input[type='checkbox'], > input[type='checkbox'] + div {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > input[type='checkbox'] {
      z-index: 1;
      opacity: 0;
    }

    > input[type='checkbox']:checked + div {
      background-image: url('assets/icons/checkMark.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }

    > input[type='checkbox'] + div {
      z-index: 0;
      background-color: ${palette.light.white};
      border: 1px solid ${palette.blue};
      box-sizing: border-box;
      border-radius: 0.25rem;
    }

    > input[type='checkbox']:hover + div {
      background-color: ${palette.light.white};
      border: 2px solid ${palette.dark.main};
    }

    > input[type='checkbox']:focus:checked + div {
      border: 2px solid ${palette.results.green.dark};
    }

    > input[type='checkbox']:focus + div {
      border: 2px solid ${palette.blue};
    }

    > input[type='checkbox']:checked + div {
      background-color: ${palette.results.green.dark};
      border: 2px solid ${palette.results.green.dark};
    }
  `
})

const CheckboxWrapper = styled.div`
  margin-left: 1rem;
  width: calc(100% - 2rem);

  svg {
    cursor: pointer;
  }

  span {
    margin-left: 11px;
  }
`

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const DownloadAnalyseModal = ({
  trigger,
  analyseId,
}: IDownloadAnalyseModalProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [open, setOpen] = useState(false)
  const [chooseTiles, setChooseTiles] = useState(false)
  const [loader, setLoader] = useState(false)

  const { values } = useSelector((state: RootState) => state.analysis)
  const { pdfData } = useSelector((state: RootState) => state.analysis)

  const { value } = useSelector((state: RootState) => state.location)
  const reportAddress = value?.address || ''

  const getAvailableTilesArray = (values: ISectionTile[]): ITile[] => {
    const array: ITile[] = []
    const tilesListFiltered: string[] = []

    values.map(value => value.tiles.map(item => item.id))
      .map(item => item.forEach(key => tilesList.some(tile => new RegExp(`${tile}.*`, 'g').test(key)) && tilesListFiltered?.push(key)))

    values?.forEach(sectionTile => sectionTile?.tiles
      ?.forEach(tile => (!array.includes(tile) && tilesListFiltered.includes(tile.id)) && array?.push(tile)))

    return array
  }

  const allSectionTiles: ISectionTile[] = values?.map(sectionTile => ({
    ...sectionTile,
    tiles: sectionTile?.tiles?.filter(tile => typeof tile?.data !== 'undefined'),
  }))
  const availableTiles: ITile[] = getAvailableTilesArray(allSectionTiles)
  const chosenTiles: ITile[] = []
  const reportNames = pdfData.map((dataTile) => dataTile.reportName)

  const openModal = () => {
    setOpen(true)
    setChooseTiles(false)
    setLoader(false)
  }

  const handleSubmittedData = (e: IDownloadAnalyseInitialValues) => {
    let formattedData: ISectionTile[] = []

    if (e.data === 'all') {
      formattedData = allSectionTiles
    } else {
      formattedData = allSectionTiles.map(sectionTile => ({
        ...sectionTile,
        tiles: chosenTiles.filter(tile => tile.section === sectionTile.id),
      })).filter(sectionTile => sectionTile.tiles.length > 0)
    }

    downloadReport(e.reportName, formattedData, e.reportLanguage)
  }

  const handleOnClose = () => {
    setOpen(false)
    dispatch(toggleIsPdf(true))
    redirectToPdf()
  }

  useEffect(() => {
    if (pdfData[0]?.data) {
      window.localStorage.setItem('pdfData', JSON.stringify(pdfData))
      window.localStorage.setItem('analyseId', JSON.stringify(analyseId))
      window.localStorage.setItem('location', JSON.stringify(value))
      window.localStorage.setItem('appLanguage', JSON.stringify(getCookieWithoutJSON('language') || 'pl'))
    }
  }, [pdfData])

  const downloadReport = (
    reportName: string,
    data: ISectionTile[],
    reportLanguage: string,
  ) => {
    const reportDate = new Date().toLocaleDateString()
    setLoader(true)
    setTimeout(() => handleOnClose(), 5000)

    if (analyseId) {
      dispatch(addPdfDataAction({
        analyseId,
        reportName,
        reportDate,
        data,
        reportLanguage,
        reportAddress,
      }))
    }
  }

  return (
    <Popup
      modal
      onClose={() => setOpen(false)}
      onOpen={openModal}
      open={open}
      overlayStyle={defaultTheme.overlay}
      trigger={trigger}
    >
      {(close: () => void) => (
        <Wrapper>
          <TopWrapper>
            <h3>{t('placeme.download_analyse_modal.title')}</h3>
            <StyledCloseIcon onClick={close} />
          </TopWrapper>
          <Formik
            initialValues={initialValues}
            isInitialValid={false}
            onSubmit={handleSubmittedData}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              reportLanguage: Yup.string().required(t('generic.field_is_required')),
              reportName: Yup.string()
                .notOneOf(reportNames, t('placeme.download_analyse_modal.report_name_exists'))
                .required(t('generic.field_is_required'))
                .max(100),
              tiles: Yup.array().when('data', {
                is: 'choosen',
                then: Yup.array().min(1),
              }),
            })}
          >
            {({
              errors,
              handleSubmit,
              handleBlur,
              values,
              setFieldValue,
              touched,
              isValid,
            }) => (
              <Form
                centered
                onSubmit={handleSubmit}
              >
                <FormSection>
                  <TextInput
                    error={touched.reportName && errors.reportName ? errors.reportName : undefined}
                    label={t('placeme.download_analyse_modal.label')}
                    margin='0 0 2rem'
                    name='reportName'
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    placeholder={t('placeme.download_analyse_modal.type_report_name')}
                    reset
                    value={values.reportName}
                  />
                  <AnalysisWrapper>
                    <p>
                      {t('placeme.download_analyse_modal.include_analysis')}
                    </p>
                    <InputWrapper>
                      <div className='formik-radio'>
                        <Field
                          checked={values.data === 'all'}
                          name='data'
                          onChange={() => {
                            setFieldValue('data', 'all')
                            setChooseTiles(false)
                          }}
                          type='radio'
                          value='all'
                        />
                        <div />
                      </div>
                      <span>{t('placeme.download_analyse_modal.all_data')}</span>
                    </InputWrapper>
                    <InputWrapper>
                      <div className='formik-radio'>
                        <Field
                          checked={values.data !== 'all'}
                          name='data'
                          onChange={() => {
                            setFieldValue('data', 'choosen')
                            setChooseTiles(true)
                          }}
                          type='radio'
                          value='choosen'
                        />
                        <div />
                      </div>
                      <span>{t('placeme.download_analyse_modal.chosen_data')}</span>
                      { chooseTiles && (
                        <FieldArray
                          name='tiles'
                          render={() => (
                            <CheckboxWrapper>
                              {availableTiles.map(tile => (
                                <InputWrapper key={tile?.id + uuidv4()}>
                                  <FormikCheckbox>
                                    <Field
                                      checked={chosenTiles.includes(tile)}
                                      name='tiles'
                                      onChange={() => {
                                        if (chosenTiles.includes(tile)) {
                                          const idx = chosenTiles.indexOf(tile)
                                          chosenTiles.splice(idx, 1)
                                        } else {
                                          chosenTiles.push(tile)
                                        }
                                        setFieldValue('tiles', chosenTiles)
                                      }}
                                      type='checkbox'
                                      value={tile}
                                    />
                                    <div />
                                  </FormikCheckbox>
                                  <span>{t(`${tile?.label}`)}</span>
                                </InputWrapper>
                              ))}
                            </CheckboxWrapper>
                          )}
                        />
                      )}
                    </InputWrapper>
                  </AnalysisWrapper>
                  <AnalysisWrapper>
                    <p>{t('placeme.download_analyse_modal.language')}</p>
                    <InputWrapper>
                      <div className='formik-radio'>
                        <Field
                          checked={values.reportLanguage === 'pl'}
                          name='reportLanguage'
                          onChange={() =>
                            setFieldValue('reportLanguage', 'pl')}
                          type='radio'
                          value='pl'
                        />
                        <div />
                      </div>
                      <span>{t('placeme.download_analyse_modal.polish')}</span>
                    </InputWrapper>
                    <InputWrapper>
                      <div className='formik-radio'>
                        <Field
                          checked={values.reportLanguage === 'en'}
                          name='reportLanguage'
                          onChange={() =>
                            setFieldValue('reportLanguage', 'en')}
                          type='radio'
                          value='en'
                        />
                        <div />
                      </div>
                      <span>{t('placeme.download_analyse_modal.english')}</span>
                    </InputWrapper>
                  </AnalysisWrapper>
                  <BottomWrapper>
                    <TextButton onClick={() => setOpen(false)}>
                      <p>{t('generic.cancel')}</p>
                    </TextButton>
                    <Button
                      disabled={!isValid}
                      loading={loader}
                      loadingText={`${t('placeme.download_analyse_modal.button.loading')}...`}
                      margin='0 0 0 20px'
                      type='submit'
                    >
                      {t('placeme.download_analyse_modal.button')}
                    </Button>
                  </BottomWrapper>
                </FormSection>
              </Form>
            )}
          </Formik>
        </Wrapper>
      )}
    </Popup>
  ) }

