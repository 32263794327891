import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { LinkWithIconCheckOnMap, MapTile, TileFormWrapper, TitleFormSectionSubTitle } from '@dataplace.ai/ui-components/atoms'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Loader } from 'libs/shared/ui-components/src/atoms'
import { IFeatureCollection } from '@dataplace.ai/ui-components/atoms/MapTile/components/MapOverlays/@types/IFeatureCollection'
import { MapVisualizationModal } from '@dataplace.ai/ui-components/molecules'
import { IMapTile } from '@dataplace.ai/ui-components/atoms/MapTile/@types/IMapTile'
import { RootState } from '../../../../../../../redux/store'
import { ICitiesPopulation } from './@types/ICitiesPopulation'

const MapWrapper = styled.div(({ theme }) => {
  const {
    palette, shadows, corners,
  } = theme
  return css`
  .city-pop-container {
   width: 22px;
   height: 22px;
   background-color: ${palette.results.green.dark};
   border-radius: 22px;
   overflow: hidden;
   display: flex;
   flex-direction: column;

 }
 .city-tooltip{
    background-color: rgba(21, 24, 31, 0.8);
    padding: 5px 10px 5px 7px;
    display: flex;
    flex-direction: column;
    color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
 }
`
})

const StyledTitleFormSectionSubTitle = styled(TitleFormSectionSubTitle)`
  justify-content: flex-end;
  margin: 0.5rem 0;
`

export const CitiesPopulationTile: React.FC<{ data : ICitiesPopulation, isPdf?:boolean, tileId: string}> = ({
  data, isPdf, tileId,
}) => {
  const { t } = useTranslation()
  const { value } = useSelector((state: RootState) => state.location)
  const [isMapDisplayed, setIsMapDisplayed] = useState(false)
  const [layers, setLayer] = useState<IMapTile['layers']>()

  const handleMapOpen = () => {
    setIsMapDisplayed(!isMapDisplayed)
  }

  const getLayers = () => {
    if (data?.value) {
      const features : IFeatureCollection['data']['features'] = []

      if (data?.value?.citiesPopulation?.length) {
        data?.value?.citiesPopulation?.forEach(item => {
          features.push({
            geometry: {
              coordinates: [item?.lng, item?.lat],
              type: 'Point',
            },
            properties: {
              title: `<div style="display: flex; flex-direction: column;" ><span>${item?.label}</span><span>${item?.number}</span></div>`,
              pinnedItem: {
                class: 'cities-span',
                html: '<span class=\'city-pop-container\'></span> ',
              },
            },
            type: 'Feature',
          })
        }) }

      return [{
        id: 'cities-population-layer',
        layer: {
          data: {
            features,
            type: 'FeatureCollection',
          },
          options: {
            type: 'geojson',
            id: 'cities-population',
          },
        },
      }]
    }
    return undefined
  }

  useEffect(() => {
    if (data?.value) setLayer(getLayers())
  }, [data?.value, isMapDisplayed])

  return !data || data?.loading
    ? ((<Loader />))
    : (
      <TileFormWrapper>
        <StyledTitleFormSectionSubTitle>
          <LinkWithIconCheckOnMap onClick={handleMapOpen} />
        </StyledTitleFormSectionSubTitle>
        <MapWrapper>
          {isPdf
            ? (
              <img
                alt='map'
                src={`data:;base64,{{ ${tileId} }}`}
              />
            )
            : (
              <MapTile
                dragging
                height='500px'
                isPdf={isPdf}
                layers={layers}
                location={value}
                mapId='example-map-data-tile'
                pinDisplayed
                popupHeading={`${t('generic.chosen_location')}:`}
                popupParagraph={value?.address}
                width='100%'
                zoom={11}
                zoomControl
              />
            )}
        </MapWrapper>
        {isMapDisplayed && (
          <MapVisualizationModal
            isDisplayed={isMapDisplayed}
            layers={layers}
            location={value}
            mapId='visualization-tile-cities_population'
            setIsDisplay={setIsMapDisplayed}
            zoom={11}
          />
        )}
      </TileFormWrapper>
    )
}

export default CitiesPopulationTile
