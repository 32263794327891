import React from 'react'
import Popup from 'reactjs-popup'
import { PopupPosition } from 'reactjs-popup/dist/types'
import styled, { css } from 'styled-components'

interface ITooltipProps {
  position: PopupPosition | PopupPosition[]
  header?: string
  content: string | JSX.Element | JSX.Element[]
  maxWidth?: string
  display?: string
}

interface ITooltipWrapper {
  maxWidth?: string,
  display?: string
}

const TooltipWrapper = styled.div<ITooltipWrapper>(({
  theme, maxWidth, display,
}) => {
  const {
    palette, typography, corners, shadows,
  } = theme
  return css`
    background-color: rgba(21, 24, 31, 0.8);
    padding: 5px 10px 5px 7px;
    display: ${display || 'flex'};
    flex-direction: column;
    color: ${palette.light.white};
    border-radius: ${corners.default.borderRadius};
    box-shadow: ${shadows.hover.boxShadow};
    max-width: ${maxWidth};

    span {
      font-size: ${typography.tiny.pt_12_medium_upper.fontSize};
      font-weight: ${typography.tiny.pt_12_medium_upper.fontWeight};
      line-height: ${typography.tiny.pt_12_medium_upper.lineHeight};
      letter-spacing: ${typography.tiny.pt_12_medium_upper.letterSpacing};
      text-transform: ${typography.tiny.pt_12_medium_upper.textTransform};
    }

    font-size: ${typography.tiny.pt_12_regular.fontSize};
    font-weight: ${typography.tiny.pt_12_regular.fontWeight};
    line-height: ${typography.tiny.pt_12_regular.lineHeight};
  `
})

export const Tooltip: React.FC<ITooltipProps> = ({
  position, children, header, content, maxWidth, display,
}): JSX.Element => (
  <Popup
    closeOnDocumentClick
    contentStyle={{
      zIndex: 9998,
    }}
    on={['hover', 'focus']}
    position={position}
    trigger={<div>{children}</div>}
  >
    <TooltipWrapper
      display={display}
      maxWidth={maxWidth}
    >
      {header && <span>{header}</span>}
      {content}
    </TooltipWrapper>
  </Popup>
)
